import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import { BreadCrumbProps } from "../ts";

const useStyles = makeStyles(() => ({
  linkTestStyle: {
    textDecoration: "none",
    color: "#838282",
  },
  typoTestStyle: {
    color: "#00a651",
    fontWeight: "bold",
    // textTransform: 'capitalize',
  },
  separatorSize: {
    width: "15px",
    height: "15px",
  },
}));

const BreadCrumbComponent: React.FC<BreadCrumbProps> = ({ BreadCrumbs }) => {
  const classes = useStyles();
  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon className={classes.separatorSize} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      {BreadCrumbs.map((arrData, index) => {
        if (arrData.linkUrl === "") {
          return (
            <Typography
              key={index}
              className={`${classes.typoTestStyle} typo-font-weight`}
            >
              <span>{arrData.labelText}</span>
            </Typography>
          );
        } else {
          return (
            <Link
              key={index}
              className={classes.linkTestStyle}
              to={arrData.linkUrl}
            >
              {arrData.labelText}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbComponent;
