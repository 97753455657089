import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from '../../common-components/CommonHeadPart';
import { PAGE, UPDATE_STAFF_MANAGEMENT_API } from "../../reducer-store/constants";
import { Visibility } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { Link } from "react-router-dom";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import * as Actions from "../../reducer-store/actions/StaffManagementAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ToastMessage from "../../common-components/ToastMessage";
import StaffManagementIcon from "../../svg_icons/StaffManagement.svg";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import MUIDataTable from "mui-datatables";
import { Box, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import { Typography } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { RiErrorWarningFill } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import axios from "axios";
import { STAFF_SUCCESSFULLY_UPDATED } from "../../reducer-store/ToastMessage";
import { CircularProgress } from "@mui/material";

type StaffProps = {
	name: string;
	email: string;
	id: string;
	house_address: string;
	street_address: string;
	phone: string;
	type: string;
	status: number;
};

const useStyles = makeStyles(() => ({
	divInsideMain: {
		boxShadow: "0 0 5px #8e8e8e",
		borderRadius: "3px",
	},
	iconDiv: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	searchBar: {
		borderColor: "black",
		"& hover": {
			borderColor: "black"
		},
		"& focused": {
			borderColor: "black"
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "black"
		}
	},
	dialogContent: {
		textAlign: "center",
		display: 'flex',
		flexDirection: "row",
		justifyContent: 'center',
		alignItems: "center"
	},
	dialogAction: {
		justifyContent: "center !important",
	},
	icon: {
		width: '30%',
		height: 'auto',
		color: "#000",
	},
	yesButton: {
		borderRadius: "40px !important",
		backgroundColor: "#00A651 !important",
		border: "1px solid #00A651 !important",
		color: "#ffffff !important",
		padding: "0 35px !important",
		height: "40px !important",
		fontFamily: 'Arial !important',
		fontSize: '15px',
		textTransform: 'capitalize'
	},
	noButton: {
		borderRadius: "40px !important",
		backgroundColor: "#ffffff !important",
		border: "1px solid #000000 !important",
		color: "#000000 !important",
		padding: "0 35px !important",
		height: "40px !important",
		textTransform: 'capitalize',
		fontSize: '15px',
	}
}));

const BreadCrumbStaffmanagement = [
	{
		labelText: "Home",
		linkUrl: ROUTES.home
	},
	{
		labelText: "Staff Management",
		linkUrl: "",
	},
	/* {
		labelText: "All Staff / Instructor",
		linkUrl: ""
	} */
];

const theme = createTheme({
	components: {
		MuiTableHead: {
			styleOverrides: {
				root: {
					overflow: "hidden",
					"& .css-1ygcj2i-MuiTableCell-root": {
						color: "black",
						fontFamily: "Arial",
					},
					"& .tss-1akey0g-MUIDataTableHeadCell-data": {
						color: "black",
						fontFamily: "Arial",
					},
					"& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
						color: "black",
					},
					"& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
						display: "-webkit-box"
					},
					"& .tss-ylo811-MUIDataTableSelectCell-headerCell": {
						borderBottomColor: '#707070',
						borderBottomWidth: '1.2px',
					},
					"& .tss-10syd3x-MUIDataTableHeadCell-root ": {
						borderBottomColor: '#707070',
						borderBottomWidth: '1.2px',
					},
					"& .css-crz6gg-MuiTableCell-root": {
						font: "normal normal normal 15px/17px Arial",
						color: '#000000'
					}
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontFamily: "Arial",
					// fontSize: "15px",
				},
				head: {
					backgroundColor: "#FFFFFF"
				},
				footer: {
					backgroundColor: "#FFFFFF"
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&:nth-child(odd)": {
						backgroundColor: "#DFDFDF"
					},
					"&:nth-child(even)": {
						backgroundColor: "#F9F9F9"
					},
				}
			}
		},
		MuiIconButton: {
			styleOverrides: {
				colorInherit: {
					backgroundColor: '#E53D12',
					borderRadius: '4px',
					color: 'white',
					marginRight: '25px',
				}
			}
		},
		MuiButtonBase: {
			styleOverrides: {
				root: {
					lineHeight: "1.30 !important"
				}
			}
		},
		MuiTablePagination: {
			styleOverrides: {
				root: {
					"& .MuiTablePagination-displayedRows": {
						marginTop: "9%"
					},
				}
			}
		},
	}
});

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;
	const [name, setname] = useState("");

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={() => onClose()}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const Staffmanagement: React.FC = () => {
	// const [filter, setFilters] = useState("all");
	const [search, setSearch] = useState('');
	const classes = useStyles();
	const AuthToken = useSelector((state: any) => state.auth);
	const [order, setOrder] = useState("desc");
	const { token } = AuthToken;
	const [rowsPerPage, setRowsPerPage] = useState(10000);
	const [page, setPage] = useState(PAGE);
	const dispatch = useDispatch();
	const { staffManagementList } = bindActionCreators(Actions, dispatch);
	const { updateStaffManagement } = Actions;
	const listOfstaff = useSelector((state: any) => state.staff);
	const { staffmangement } = listOfstaff;
	const [data, setData] = useState([]);
	const [searchApiData, setSearchApiData] = useState([]);
	const [filterVal, setFilterVal] = useState('');
	const [disableStaffId, setDisableStaffId] = useState('');
	const [staffStatus, setStaffStatus] = useState('');
	const [openDialog, setOpenDialog] = useState(false);
	const [searchData, setSearchData] = useState(false);

	const handlefilter = (e: any) => {
		if (e.target.value == "") {
			setData(searchApiData);
		} else {
			const filterData = searchApiData.filter((items: any) =>
				items.name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
				|| items.id.toString().includes(e.target.value.toString())
				|| items.email.toString().toLowerCase().includes(e.target.value.toString())
				|| items.phone.toString().includes(e.target.value.toString())
				|| items.type.toString().toLowerCase().includes(e.target.value.toString())
				|| items.address.toString().toLowerCase().includes(e.target.value.toString())
			);
			if (filterData.length > 0) {
				setData(filterData);
				setSearchData(false);
			} else {
				setData([]);
				setSearchData(true);
			}
		}
		setFilterVal(e.target.value);
	};

	useEffect(() => {
		(async () => {
			const newStaffArr: any = await staffManagementList(page, rowsPerPage, token, order, search);
			renderDataTable(newStaffArr);
		})();
	}, [page, order, search, openDialog]);

	const renderDataTable = (newStaffArr: any) => {
		const rowsDataTable = newStaffArr && newStaffArr.data.data.map((staff: StaffProps, index: string) => {
			return {
				id: index + 1,
				name: staff.name,
				address: `${staff.house_address} ${staff.street_address}`,
				email: staff.email,
				phone: staff.phone,
				type: staff.type === "staff" ? "General Staff" : "Instructor",
				status: <>
					<div>
						<Switch
							color={staff.status ? "success" : "warning"}
							checked={staff.status ? true : false}
							onChange={() => handleClickOpen(staff.id, staff.status)}
						/>
					</div>
				</>,
				action: <>
					<Link to={`${InnerPageRoutes.viewStaffInstructor}/${staff.id}`}>
						<Visibility sx={{ color: yellow[700], marginRight: '15px' }} />
					</Link>
					<Link to={`${InnerPageRoutes.editStaffInstructor}/${staff.id}`}>
						<FaRegEdit size={22} color="#00A651" />
					</Link>
				</>
			}
		})
		/* setDatatable({ ...datatable, rows: rowsDataTable }); */
		setData(rowsDataTable);
		setSearchApiData(rowsDataTable);
	};

	const handleClickOpen = async (id: any, state: any) => {
		setStaffStatus(state ? '0' : '1');
		setDisableStaffId(id);
		setTimeout(() => {
		}, 1000);
		setOpenDialog(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleDisable = async (staffId: string, state: any) => {
		const formData = new FormData();
		formData.append("status", state)

		let resData: any = await updateStaffManagement(staffId, formData, token);
		if (!resData?.error) {
			toast.success(STAFF_SUCCESSFULLY_UPDATED);
			const newStaffArr: any = await staffManagementList(page, rowsPerPage, token, order, search);
			renderDataTable(newStaffArr);
			setTimeout(() => {
				setOpenDialog(false);
			}, 2000);
		}
	}

	const columns = [
		{
			label: 'Sr No',
			name: "id",
		},
		{
			label: 'Name',
			name: "name",
		},
		// {
		// 	label: 'Address',
		// 	name: "address",
		// },
		{
			label: 'Email ID',
			name: "email",
		},
		{
			label: 'Phone',
			name: "phone",
		},
		{
			label: 'Type',
			name: "type",
		},
		{
			label: 'Status',
			name: "status",
			options: {
				sort: false
			}
		},
		{
			label: 'Action',
			name: "action",
			options: {
				sort: false
			}
		},
	];

	const options = {
		search: false,
		print: false,
		viewColumns: false,
		filter: false,
		download: false,
		responsive: "vertical",
		expandableRows: false,
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		selectableRows: "none",
		rowsPerPageOptions: [],
		textLabels: {
			body: {
				noMatch: searchData || data?.length == 0 && staffmangement?.data?.length == 0 ?
					'Sorry, there is no matching data to display' :
					<Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
						<CircularProgress sx={{ color: "#707070" }} />
					</Box >
				,
			},
		},
		customToolbar: () => {
			return (<>
				<TextField
					variant="standard"
					placeholder="Search"
					onInput={(e: any) => handlefilter(e)}
					value={filterVal}
					className={classes.searchBar}
					InputProps={{
						style: { color: "black" },
						endAdornment: (
							<IconButton>
								<SearchOutlined />
							</IconButton>
						),
					}}
				/>
			</>)
		},
	};

	return (
		<>
			<ToastMessage />
			<CommonHeadPart
				componentText="Staff Management"
				Icon={StaffManagementIcon}
				buttonText="Add New Staff / Instructor"
				urlLink={InnerPageRoutes.newStaffInstructor}
				BreadCrumbs={BreadCrumbStaffmanagement}
			/>
			<div className={classes.divInsideMain}>
				{/* {!staffmangement || (staffmangement?.data?.length > 0 && data?.length == 0) ? (
					<Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
						<CircularProgress sx={{ color: '#707070' }} />
					</Box>
				) : staffmangement?.data.length > 0 ?
					( */}
				<ThemeProvider theme={theme} >
					<MUIDataTable
						title={"All Staff / Instructor List"}
						data={data}
						columns={columns}
						options={options}
					/>
				</ThemeProvider>
				{/* ) : (
						<ThemeProvider theme={theme} >
							<MUIDataTable
								title={"All Staff / Instructor List"}
								data={data}
								columns={columns}
								options={options}
							/>
						</ThemeProvider>
					)} */}
			</div>

			<BootstrapDialog
				onClose={handleClose}
				fullWidth
				maxWidth="sm"
				aria-labelledby="customized-dialog-title"
				open={openDialog}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					Staff Enable/Disable
				</BootstrapDialogTitle>

				<DialogContent dividers className={classes.dialogContent} >
					<RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
					{/* <img className={classes.icon} sizes={"10"} src={Icon} /> */}
					<Typography variant="h6" component="div">
						Are you sure you want to  {staffStatus === "0" ? "disable" : "enable"} this staff?
					</Typography>

				</DialogContent>

				<DialogActions className={classes.dialogAction} >
					{/* <CancelButtonComponent ButtonLabel="No" /> */}
					<Button
						type="button"
						variant="contained"
						className={classes.noButton}
						onClick={handleClose}
					>
						No
					</Button>
					{/* <SaveButtonComponent
                        ButtonType="submit"
                        ButtonLabel="Yes"
                    /> */}
					<Button
						type="button"
						variant="contained"
						className={classes.yesButton}
						onClick={() => handleDisable(disableStaffId, staffStatus)}
					>
						Yes
					</Button>
				</DialogActions>
			</BootstrapDialog>

		</>);
};
export default Staffmanagement;