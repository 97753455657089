import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../constants/routes";
import { Card, CardContent, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { InputDatePicker, InputField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { STUDENT_CONTRACT_SUCCESSFULLY_CREATED, UPDATE_STUDENT_CONTRACT_SUCCESSFULLY_CREATED } from "../../../reducer-store/ToastMessage";
import { CancelButtonComponent, SaveButtonComponent } from "../../../common-components/ButtonComponent";
import { Box, CircularProgress, Grid } from "@mui/material";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import DashboardIcon from "../../../svg_icons/dashboard.svg";
import { isRequiredDate, isRequiredField, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/StudentContractAction";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ToastMessage from "../../../common-components/ToastMessage";
import { useParams } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles(() => ({
  CardTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  }
}));

const NewStaffInstructor: React.FC = () => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [studentContractName, setStudentContractName] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [description, setDescription] = useState("");
  const { addStudentContaract, SingleStudentContaract, updateStudentContaract } = Actions;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const history = useHistory();
  const params: any = useParams();
  const { id } = params;
  const [saveUpdate, setSaveUpdate] = useState(true);

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const response: any = await SingleStudentContaract(id, token);
        const data = response.data;
        setStudentContractName(data && data.contract_name);
        setStartDate(data && moment(data.start_date).format("YYYY-MM-DD"));
        setEndDate(data && moment(data.end_date).format("YYYY-MM-DD"));
        setDescription(data && data.contract_description);
        setSaveUpdate(false);
      }
    })();
  }, [id]);

  if (params && params.id && saveUpdate) {
    return <>
      <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    </>
  }

  const BreadCrumbStudentContractManagement = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Student Contract",
      linkUrl: ROUTES.studentContractManagement,
    },
    {
      labelText: id ? "Edit Student Contract" : "New Student Contract",
      linkUrl: "",
    },
  ];

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(studentContractName) &&
      isValidRegisterID(studentContractName) &&
      isRequiredField(startDate) &&
      isRequiredDate(endDate) &&
      isRequiredField(description)
    ) {
      setIsSubmit(false);
      let userObj = {
        contract_name: studentContractName,
        start_date: startDate,
        end_date: endDate,
        contract_description: description
      }

      let resData: any = saveUpdate ? await addStudentContaract(userObj, token) : await updateStudentContaract(id, userObj, token)
      if (!resData.error) {
        toast.success(saveUpdate ? STUDENT_CONTRACT_SUCCESSFULLY_CREATED : UPDATE_STUDENT_CONTRACT_SUCCESSFULLY_CREATED);
        setTimeout(() => {
          return history.push(ROUTES.studentContractManagement);
        }, 2000);
        return;
      }
      toast.error(resData.data.errorMsg);
    }
    setIsValidValues(false);
  }

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  return (
    <>
      <ToastMessage />
      <Row>
        <Col lg="8" sm="12">
          <CommonHeadPart
            componentText="Student Contract"
            Icon={DashboardIcon}
            buttonText=""
            urlLink="/"
            BreadCrumbs={BreadCrumbStudentContractManagement}
          />
        </Col>
      </Row>

      <Card>
        <div className={classes.CardTitle}>
          <CardContent>
            <Typography variant="h6" align="justify">
              {/*  <AddCircle /> */}
              {saveUpdate ? "Add New Student Contract" : "Update Student Contract"}
            </Typography>
          </CardContent>
        </div>
        <CardContent>
          <form onSubmit={SubmitHandler}>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  type="text"
                  lable="Student Contract Name"
                  name={studentContractName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Student Contract Name"
                  className="form-textfield-styles"
                  value={studentContractName}
                  handleChange={(e) => setStudentContractName(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Student Contract Name is required"
                  isCheckValidRID={true}
                />
              </Col>
              <Col lg="4" sm="12">
                {/* <InputField
                  type="date"
                  lable="Start Date"
                  name={startDate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Start Date"
                  className="form-textfield-styles"
                  value={startDate}
                  handleChange={(e) => setStartDate(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Start Date is required"
                  disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                  disablePrevious={moment(new Date()).format("YYYY-MM-DD")}
                /> */}
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={startDate}
                  lable="Start Date"
                  handleChange={(e) => handleStartDateChange(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Start Date is required"
                  min={saveUpdate ? moment(new Date()).format("YYYY-MM-DD") : startDate}
                />
              </Col>
              <Col lg="4" sm="12">
                {/* <InputField
                  type="date"
                  lable="End Date"
                  name={endDate}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Start Date"
                  className="form-textfield-styles"
                  value={endDate}
                  handleChange={(e) => setEndDate(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="End Date is required"
                  disablePrevious={startDate}
                  disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                /> */}
                <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={endDate}
                  lable="End Date"
                  handleChange={(e) => handleEndDateChange(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="End Date is required"
                  min={saveUpdate ? moment(new Date()).format("YYYY-MM-DD") : endDate}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" xxl="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={description}
                  rows={4}
                  multiline={true}
                  placeHolder="Enter Description"
                  className="form-textfield-styles"
                  value={description}
                  handleChange={(e) => setDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description is required"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div style={{ textAlign: "end" }}>
                  <Link to={ROUTES.studentContractManagement} className={classes.link}>
                    <CancelButtonComponent ButtonLabel="Cancel" /></Link>{" "}
                  <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
                </div>
              </Col>
            </Row>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default NewStaffInstructor;
