import React from "react";
import {
  Box,
  TextField,
  Popover,
  MenuItem,
} from "@material-ui/core";
import { Tabs, Tab, tabsClasses } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { ModuleFilterProps } from "../ts";
import { FilterButtonComponent } from "./ButtonComponent";
import { Row, Col } from "react-bootstrap";

import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles(() => ({
  tabMinWidth: {
    // minWidth: "100px",
    textTransform: "inherit",
    position: "relative",
  },
  divStyling: {
    // display: "flex",
    // justifyContent: "space-between",
    padding: "0px 0px",
  },
  search: {
    textAlign: "right",
  },
  searchIcon: {
    padding: "0px",
  },
}));

const FilterTabs: React.FC<ModuleFilterProps> = ({
  Filters,
  HandleChange,
  SelectedFilter,
  isFilter = false,
  // HandleSearch,
  isSearch = false,
}): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <div className={classes.divStyling}>
        <Row>
          <Col lg="12" sm="12">
            <Tabs
              value={SelectedFilter}
              onChange={(event, value) => HandleChange(value)}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { backgroundColor: "#3A3A3A" } }}

              sx={{
                "& button": {
                  fontWeight: "bold",
                  color: "#878686",
                  textTransform: "capitalize",
                  fontFamily: "Arial",
                  fontSize: "0.875rem",
                  marginRight: "20px",
                  padding: "0px",
                  minHeight: "48px",
                },

                "& button:active": {
                  color: "#3A3A3A",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  marginRight: "20px",
                  padding: "0px",
                  fontFamily: "Arial",
                  fontSize: "0.875rem",
                },

                "& button.Mui-selected": {
                  color: "#3A3A3A",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  marginRight: "20px",
                  padding: "0px",
                  fontFamily: "Arial",
                  fontSize: "0.875rem",
                }
              }} >
              {Filters.map((filter, index) => {
                if (filter.lable === "Status") {
                  return (
                    <Tab
                      key={`${filter.value}_${index}`}
                      className={`dropdown-tab-here ${classes.tabMinWidth}`}
                      iconPosition="end"
                      onClick={(event) => handleClick(event)}
                      icon={<ArrowDropDownIcon />}
                      value={filter.value}
                      label={filter.lable}
                    />
                  );
                } else {
                  return (
                    <Tab
                      key={`${filter.value}_${index}`}
                      className={classes.tabMinWidth}
                      value={filter.value}
                      label={filter.lable}
                    />
                  );
                }
              })}
            </Tabs>
          </Col>
          {/* <Col
            lg="2"
            sm="12"
            style={{
              display: "flex",
              textAlign: "end",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {isSearch &&
              <TextField
                style={{ width: "100%", marginRight: "20px" }}
                placeholder="Search"
                defaultValue=""
                // onChange={(e: any) => HandleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className={classes.SearchIcon}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />}
            {isFilter && <FilterButtonComponent />}
          </Col> */}
        </Row>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem>Resolved</MenuItem>
        <MenuItem>Declined</MenuItem>
        <MenuItem>Under Review</MenuItem>
        <MenuItem>Pending</MenuItem>
      </Popover>
    </Box>
  );
};

export default FilterTabs;
