import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_DASHBOARD, LIST_OF_DASHBOARD_ERROR } from "../ActionTypes";
// import { LIST_OF_DASHBOARD, LIST_OF_DASHBOARD_ERROR } from "../ActionTypes";
import { DASHBOARD_API } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const DashboardData = (
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${DASHBOARD_API}`,
        header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_DASHBOARD,
          payload: res.data.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_DASHBOARD_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

