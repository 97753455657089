import React, { useEffect, useState } from "react";
import * as Actions from "../../../reducer-store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
// import { bindActionCreators } from "redux";
import { PAGE } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import MUIDataTable from "mui-datatables";
// import { SearchOutlined } from "@material-ui/icons";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { bindActionCreators } from "redux";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@mui/material";


const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: "#FFFFFF",
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box",
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: "#FFFFFF",
                    },
                    ".tss-1akey0g-MUIDataTableHeadCell-data": {
                        fontSize: "15px",
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: "#000",
                        borderBottomWidth: "1.2px",
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: "15px",
                    },
                    "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
                        borderBottomColor: "#000",
                        borderBottomWidth: "thin",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF",
                },
                footer: {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: "#E53D12",
                    borderRadius: "4px",
                    color: "white",
                    marginRight: "25px",
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    lineHeight: "1.30 !important",
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#DFDFDF",
                    },
                    "&:nth-child(even)": {
                        backgroundColor: "#F9F9F9",
                    },
                },
            },
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%",
                    },
                },
            },
        },
    },
});

const TexDetails: React.FC = () => {
    const dispatch = useDispatch();
    const { ProvinceTaxList } = bindActionCreators(Actions, dispatch);
    const AuthToken = useSelector((state: any) => state.auth);
    const [page, setPage] = useState(PAGE);
    const { token, user, taxDetails } = AuthToken;
    const [data, setData] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);

    useEffect(() => {
        (async () => {
            const resData: any = await ProvinceTaxList(token, user?.School?.state);
            renderDataTable(resData);
        })();
    }, [page]);

    const renderDataTable = (taxList: any) => {
        const rowsDataTable = taxList && taxList.data?.map((row: any, index: string) => {
            return {
                id: index + 1,
                province: row.Province?.name || "--",
                combined_rate: row?.total_tax || "0",
                gst: row?.tax_slab_2 || "0",
                provincial_tax: row?.tax_slab_1 || "0",
            }
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const columns = [
        {
            label: "Sr No",
            name: "id",
        },
        {
            label: "Province",
            name: "province",
        },
        {
            label: "Combine Rate(%)",
            name: "combined_rate",
        },
        {
            label: "GST/HST(%)",
            name: "gst",
        },
        {
            label: "Provincial/Territorial Sales Tax(%)",
            name: "provincial_tax",
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        selectableRows: "none",
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: !taxDetails || (taxDetails?.data?.length > 0 && data?.length == 0) ?
                    <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: '#707070' }} />
                    </Box>
                    : 'Sorry, there is no matching data to display',
            },
        },
    };

    return (
        <>
            <ToastMessage />
            <ThemeProvider theme={theme}>
                <MUIDataTable
                    title=""
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>

            {/* <Paper className={classes.boxBorder}>
                <div className={classes.divStyling}>
                    <form onSubmit={SubmitHandler}>
                        <Row>
                            <Col lg="12" sm="12">
                                <Typography variant="h6" component="div">
                                    Add Tax (%) :
                                </Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" sm="12">
                                {
                                    bookRoomData.map((data, i) => {
                                        return (
                                            <Row className='mt-3' key={i}>
                                                <Col lg="8" sm="12">
                                                    <InputField
                                                        type="text"
                                                        lable={`Name ${i + 1}`}
                                                        name="TaxtName"
                                                        rows={1}
                                                        multiline={false}
                                                        placeHolder="Enter name"
                                                        className="form-textfield-styles"
                                                        value={data.TaxtName}
                                                        handleChange={(e) => handleChange(i, e)}
                                                        isValidValue={isValidValues}
                                                        isSubmit={isSubmit}
                                                        requiredMessage="Name field is required"
                                                        isCheckValidRID={true}
                                                    />
                                                </Col>
                                                <Col lg="4" sm="12">
                                                    <InputField
                                                        type="text"
                                                        lable={`Tax (%) ${i + 1}`}
                                                        name="Tax"
                                                        rows={1}
                                                        multiline={false}
                                                        placeHolder="Enter Tax"
                                                        className="form-textfield-styles"
                                                        value={data.Tax}
                                                        handleChange={(e) => handleChange(i, e)}
                                                        isValidValue={isValidValues}
                                                        isSubmit={isSubmit}
                                                        requiredMessage="Tax field is required"
                                                        isCheckValidNoValue={true}
                                                        max={MARKSLIMIT}
                                                        disablePrevious="0"
                                                    />
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                                <Row>
                                    <Col className='pt-3'>
                                        <div className="multi-row-add">
                                            <button
                                                className="add-more1"
                                                onClick={handleAddFields}>
                                                Add Tax (%)
                                            </button>{" "}
                                            {
                                                removeBtn ?
                                                    <Button
                                                        variant="danger"
                                                        onClick={handleRemoveFields}>
                                                        Remove
                                                    </Button> : ""
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6" sm="12">

                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col lg="12" sm="12">
                                <div className={classes.button_save}>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCancel}
                                        className={classes.backButton}
                                    >
                                        Cancel
                                    </Button>
                                    <CancelButtonComponent ButtonLabel="Cancel" />
                                    {" "}
                                    <SaveButtonComponent ButtonType="submit" ButtonLabel="Update" />
                                </div>
                            </Col>
                        </Row>
                    </form>
                </div>
            </Paper> */}

            {/* <div className="mt-3">
                <Paper className={classes.boxBorder}>
                    <Table className="communication-table" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell component="th" align="left">
                                    #Id
                                </StyledTableCell>
                                <StyledTableCell component="th" align="left">
                                    Name
                                </StyledTableCell>
                                <StyledTableCell component="th" align="left">
                                    Tax (%)
                                </StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!taxs ? (
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={8}>
                                        Loading...
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : taxs.data.length > 0 ? (
                                taxs.data.map((row: any) => (
                                    <StyledTableRow>
                                        <TableCell align="left">{row.id}</TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.tax}</TableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={8}>
                                        {taxs.msg}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    </Table>
                    <PaginationComponent
                        page={page}
                        setPage={setPage}
                        paginationData={taxs && taxs.meta}
                    />
                </Paper>

            </div> */}
        </>
    );
};

export default TexDetails;
