import React, { useState, useEffect } from "react";
import "../../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box, Table, TableBody, TableHead, TableRow, makeStyles } from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { PAGE, LIMIT } from "../../../reducer-store/constants";
import { bindActionCreators } from "redux";
import * as Actions from '../../../reducer-store/actions/paymentsAction'
import { useDispatch, useSelector } from "react-redux";
import Master from '../../../svg_icons/mastro.png';
import Visa from '../../../svg_icons/visa.png';
import { LIST_OF_PAYMENTS_ERROR } from "../../../reducer-store/ActionTypes";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Typography from "@mui/material/Typography";
import { Col, Container, Row } from "react-bootstrap";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import moment from "moment";
import { toast } from "react-toastify";
import { fileSignedURL } from "../../../reducer-store/actions/authActions";
import { green } from "@mui/material/colors";
import { DownloadForOffline } from "@mui/icons-material";
import pdfIcon from "../../../svg_icons/pdfIcon.png";

const useStyles = makeStyles(() => ({
    boxBorder1: {
        textAlign: "center",
        margin: "20px 0px",
    },
    TransferInitiated: {
        color: "#00a651",
    },
    line: {
        backgroundColor: "#00a651",
        width: "100%",
        height: "5px",
        marginTop: "2.5%",
    },
    Right: {
        textAlign: "end",
    },
    Left: {
        textAlign: "start",
    },
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    '.tss-1akey0g-MUIDataTableHeadCell-data': {
                        fontSize: '15px'
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: '15px',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#F4F4F4"
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "13%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

interface RefundProps {
    [x: string]: any;
    id: number;
    amount: string;
    payment_id: string;
    reason: string;
    status: string;
    refund_id: string;
    created: string;
};

const Refunded: React.FC = () => {
    const classes = useStyles();
    const [page, setPage] = useState(PAGE);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState("asc");
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [rowsPerPage, setRowsPerPage] = useState(LIMIT);
    const dispatch = useDispatch();
    const { paymentsList } = bindActionCreators(Actions, dispatch);
    const listOfPayment = useSelector((state: any) => state.payment);
    const { payments } = listOfPayment;
    const status = 5
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const columns = [
        {
            label: 'Date',
            name: "date",
        },
        {
            label: 'Amount',
            name: "amount",
        },
        {
            label: 'Status',
            name: "status",
        },
        {
            label: 'Transaction ID',
            name: "transaction_id",
        },
        {
            label: 'Plan',
            name: "fee_type",
        },
        {
            label: 'Card Type',
            name: "instrument_logo",
        },
        {
            label: 'Transaction Acc',
            name: "transaction_acc",
        },
        {
            label: 'Invoice',
            name: "invoice",
        },
        {
            label: "transfer_initiate",
            name: "transfer_initiate",
            options: {
                display: false,
            }
        },
        {
            label: "transfer_complete",
            name: "transfer_complete",
            options: {
                display: false,
            }
        },
        {
            label: 'Payment Gateway Id',
            name: "paymentgateway_id",
            options: {
                display: false,
            }
        },
        {
            label: 'Refund Information',
            name: "refundInformation",
            options: {
                display: false,
            }
        },
        {
            label: 'totalAmount',
            name: "totalAmount",
            options: {
                display: false,
            }
        },
        {
            label: 'payId',
            name: "payId",
            options: {
                display: false,
            }
        },
    ];

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: LIST_OF_PAYMENTS_ERROR })
            const newPaymentList = await paymentsList(page, rowsPerPage, token, order, search, status);
            renderDataTable(newPaymentList);
        })();
    }, [page]);

    const paymentStatus = (value: number) => {
        switch (value) {
            case 1:
                return "Received";
            case 2:
                return "Failed";
            case 3:
                return "Cancelled";
            case 4:
                return "Refund Requested";
            case 5:
                return "Refunded";
            default:
                return "Failed";
        }
    };

    const renderDataTable = (paymentsList: any) => {
        const rowsDataTable = paymentsList && paymentsList.data.data.map((row: any, index: number) => {
            return {
                date: moment(row?.paid_on).format("YYYY-MM-DD"),
                amount: <>{row.symbol ? row?.symbol : "--"} {" "} {Number(row?.total_amount).toFixed(2)}</>,
                status: paymentStatus(row?.status),
                transaction_id: <>{row.PaymentInfos ? row.PaymentInfos[0].transaction : "--"}</>,
                fee_type: <>{row?.details ? row?.details : row?.feeType ? row.feeType : "--"}</>,
                instrument_logo: <>{row.PaymentInfos ? cardshow(row.PaymentInfos[0]?.network) : ""}</>,
                transaction_acc: <>{`**** **** **** ${row.PaymentInfos[0]?.last4}`}</>,
                invoice: <><span onClick={() => navigateToExternalUrl(row.invoice_url, true)}>
                    <DownloadForOffline sx={{ fill: green[600], cursor: 'pointer' }} />
                </span></>,
                transfer_initiate: row?.created_at,
                transfer_complete: row?.paid_on,
                paymentgateway_id: row?.payment_id,
                refundInformation: row?.RefundInfos,
                totalAmount: row?.total_amount,
                payId: row?.id,
            };
        });
        setData(rowsDataTable)
    };

    const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
        const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
        const { url, msg, error } = newData;
        if (error) {
            toast.error(msg);
            return;
        }
        shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
        return;
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const cardshow = (value: any) => {
        if (value.length === 0) {
            return "---";
        }
        switch (value[0].brand) {
            case "visa":
                return <img src={Visa} alt="visa" />;
            case "master":
                return <img src={Master} alt="visa" />;
            default:
                return <img src={Visa} alt="visa" />;
        }
    };

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        selectableRows: "none",
        rowsPerPageOptions: [],
        fixedHeader: false,
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && payments?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box>
            },
        },
        renderExpandableRow: (rowData: any) => {
            const totalAmountBank = rowData?.[11]?.filter((data: any) => { return data.type === "bank" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0);
            const totalAmountCard = rowData?.[11]?.filter((data: any) => { return data.type === "card" }).reduce((prev: any, next: any) => { return prev + next.amount }, 0) / 100;
            return (
                <TableRow >
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Box className={classes.boxBorder1}>
                            <div className="row">
                                <Col lg="4" sm="4" xs="12" className={classes.Right}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer Initiated
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp : {rowData[8] ? moment(rowData[8]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                </Col>
                                <Col lg="4" sm="4" xs="12">
                                    <div className={classes.line}></div>
                                </Col>

                                <Col lg="4" sm="4" xs="12" className={classes.Left}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer Completed
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp :  {rowData[9] ? moment(rowData[9]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Payment Gateway ID : {rowData[10]}
                                    </Typography>
                                </Col>
                            </div>
                        </Box>

                        <hr style={{ margin: '0 auto', marginTop: 2, color: "#000000", opacity: 1, width: '70%' }} />

                        <Container className="mb-3">
                            <Col lg="12" sm="12" xs="12">
                                <Table aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Date
                                            </StyledTableCell>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Refunded Amount
                                            </StyledTableCell>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Payment Gateway Reason
                                            </StyledTableCell>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Status
                                            </StyledTableCell>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Remark
                                            </StyledTableCell>
                                            <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
                                                Payment Reference
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            rowData[11].length > 0 ? (
                                                rowData[11].map((refund: RefundProps, index: number) => (
                                                    <StyledTableRow key={refund.id}>
                                                        <StyledTableCell align="center">
                                                            {refund.type === "bank" ? moment(new Date(refund.created)).format("YYYY-MM-DD") : moment(new Date(refund.created_at)).format("YYYY-MM-DD")}
                                                        </StyledTableCell>
                                                        <StyledTableCell scope="row" align="center">
                                                            CAD {refund.type === 'bank' ? Number(refund.amount).toFixed(2) : (Number(refund.amount) / 100).toFixed(2)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {refund.reason || "--"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {refund.status}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {refund?.metadata?.remark || "--"}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {refund.type === "bank" && refund.bank_transaction_proof ?
                                                                <img
                                                                    title="Download Payment Reference"
                                                                    style={{ cursor: "pointer" }}
                                                                    width={32}
                                                                    src={pdfIcon}
                                                                    onClick={() => navigateToExternalUrl(refund.bank_transaction_proof)}
                                                                /> : "--"}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            ) : (
                                                <StyledTableRow>
                                                    <StyledTableCell align="center" colSpan={8}>
                                                        No refunds found
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )}
                                    </TableBody>
                                </Table>

                                <Row className="mt-3">
                                    <Col lg="6" sm="6" xs="12">
                                        {rowData[11] && rowData[11]?.length > 0 ?
                                            <Typography variant="subtitle1" gutterBottom component="div">
                                                <b>Total Amount Refunded:</b> CAD {Number(totalAmountBank + totalAmountCard).toFixed(2) || "--"}
                                            </Typography> : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </TableCell>
                </TableRow >
            );
        },
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </>
    );
};
export default Refunded;

{/* <div>
<Table className="communication-table" aria-label="simple table">
    <TableHead>
        <TableRow>
            <StyledTableCell align="left" component="th">
                ID
            </StyledTableCell>
            <StyledTableCell align="left" component="th">
                Amount
            </StyledTableCell>
            <StyledTableCell align="left" component="th">
                Transaction ID
            </StyledTableCell>
            <StyledTableCell align="left" component="th">
                Fee Type
            </StyledTableCell>
            <StyledTableCell align="left" component="th">
                Transaction Acc
            </StyledTableCell>
            <StyledTableCell align="left" component="th">
                Instrument Logo
            </StyledTableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {!payments ? (
            <TableRow>
                <StyledTableCell align="center" colSpan={6}>
                    <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: '#707070' }} />
                    </Box>
                </StyledTableCell>
            </TableRow>
        ) : payments.data.length > 0 ? (
            payments.data.map((row: any) =>
                <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">{row.id}</StyledTableCell>
                    <StyledTableCell align="left">{row.symbol ? row.symbol : "--"} {" "}{Number(row.total_amount).toFixed(2)}</StyledTableCell>
                    <StyledTableCell align="left">{row.PaymentInfos ? row.PaymentInfos[0].transaction : "--"}</StyledTableCell>
                    <StyledTableCell align="left">{row.feeType ? row.feeType : "--"}</StyledTableCell>
                    <StyledTableCell align="left">**** **** **** {row.PaymentInfos ? row.PaymentInfos[0].last4 : "--"}</StyledTableCell>
                    <StyledTableCell align="left">{row.PaymentInfos ? cardshow(row.PaymentInfos[0].network) : ""}</StyledTableCell>
                </StyledTableRow>
            )
        ) : (
            <StyledTableRow>
                <StyledTableCell align="center" colSpan={8}>
                    No Data Found
                </StyledTableCell>
            </StyledTableRow>
        )}
    </TableBody>
</Table>
<PaginationComponent
    page={page}
    setPage={setPage}
    paginationData={payments && payments.meta}
/>
</div> */}