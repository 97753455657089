import React from "react";
import { useState } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import logIn from "../../svg_icons/school_login.svg";
import MainComponent from "./MainComponent";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormControl from "@mui/material/FormControl";
import { InputField, InputPasswordField } from "../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidEmail } from "../../common-components/FormComponent/FormValidator";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import * as authAction from "../../reducer-store/actions/authActions";
import { useDispatch } from "react-redux";
import { LOGIN_SUCCESSFULLY } from "../../reducer-store/messages/actionMessages";
import ToastMessage from "../../common-components/ToastMessage";
import Logo from "../../common-components/Logo/Logo";
import { styled } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "70px",
    fontWeight: 700,
    color: "#000000",
    '@media (max-width: 1200px)': {
      fontSize: "32px",
    },
    '@media (max-width: 991px)': {
      fontSize: "30px",
    },
    '@media (max-width: 575px)': {
      fontSize: "22px",
    }
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "20px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  cardMain: {
    '@media (max-width: 991px)': {
      marginTop: "30px",
    },
  },
  imgCenter: {
    '@media (max-width: 1200px)': {
      display: "flex",
      justifyContent: "center",
    },
    '@media (max-width: 991px)': {
      display: "flex",
      justifyContent: "center",
    },
    '@media (max-width: 575px)': {
      display: "flex",
      justifyContent: "center",
    },
  },
  imgClass: {
    '@media (max-width: 1200px)': {
      width: "70%",
      height: "100%",
    },
    '@media (max-width: 991px)': {
      width: "60%",
      height: "100%",
    },
    '@media (max-width: 575px)': {
      width: "100%",
      height: "100%",
    },
    width: "70%",
    height: "100%",
  },
  footer: {
    position: "absolute",
    // right: 0,
    bottom: 0,
    // left: 0,
    backgroundColor: ' #000000',
    width: '100%',
    minHeight: 'auto',
    '@media (max-width: 768px)': {
      position: 'absolute',
      minHeight: 'auto',
      bottom: 0,
    },
  },
  FooterText: {
    textAlign: 'center',
    color: '#ffffff',
    margin: '15px 0px',
    '@media (max-width: 768px)': {
      margin: '15px 0px',
    },
  },
  logoP: {
    position: "fixed",
    '@media (max-width: 768px),(min-width: 480px)': {
      padding: "15px 30px",
    },
    '@media (max-width: 480px)': {
      padding: "15px 10px",
    }
  }
}));

type StateProps = {
  auth: AuthProps;
};

type AuthProps = {
  isAuthenticated: string;
  isVerified: string;
  loading: boolean;
  user: {};
};

const Login = (props: any) => {

  const mdUp = useResponsive('up', 'md', '');

  const classes = useStyles();
  const [loaded, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidValues, setIsValidValues] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { adminLogin } = bindActionCreators(authAction, dispatch);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  function removeSomeChar(url: string) {
    let newURL = url.replace(/^https?:\/\//, '');
    newURL = newURL.replace("/#/", '');
    return newURL;
  }

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(email) &&
      isValidEmail(email) &&
      isRequiredField(password)
    ) {
      setIsSubmit(false);
      setLoader(true);
      let userObj = {
        email: email,
        password: password,
        domain: removeSomeChar(window.location.href)
        // "vibrantharsh.testdrvsc.com"
      };
      let resData: any = await adminLogin(userObj);
      if (resData?.error) {
        setLoader(false);
        toast.error(resData?.errorMsg);
        return;
      } else if (resData?.active_session) {
        setLoader(false);
        toast.error("This user is already logged in, please logout to login again");
        return;
      }
      setEmail("");
      setPassword("");
      toast.success(LOGIN_SUCCESSFULLY);
      setTimeout(() => {
        setLoader(false);
        history.push({ pathname: ROUTES.generateOTP, state: userObj });
      }, 2000);
    }
    setIsValidValues(false);
  }

  return (

    <MainComponent>
      <StyledRoot>
        <ToastMessage />
        <Box className={classes.logoP}>
          <Link to={ROUTES.login}><Logo size={30} /></Link>
        </Box>

        <section className={classes.sectionTop}>
          <Container className="mt-4">
            <Row className="stap-form">
              <Typography
                className={classes.loginTitle}
                variant="h5"
                component="div"
              >
                Welcome Back to Driving School Cloud!
              </Typography>
            </Row>
            <Row className="stap-form">
              {mdUp && (
                <Col lg="6" sm="12" className={classes.imgCenter}>
                  <img src={logIn} className={classes.imgClass} />
                </Col>
              )}

              <Col lg="6" sm="12" className="d-flex flex-column justify-content-center">
                <Box>
                  <Card className="Main_Card" variant="outlined">
                    {loaded && <LinearProgress />}
                    <Form onSubmit={SubmitHandler}>
                      <CardContent>
                        <InputField
                          type="text"
                          lable="Email"
                          name={email}
                          rows={10}
                          multiline={false}
                          placeHolder="Enter email"
                          className="form-textfield-styles"
                          value={email}
                          handleChange={(e) => setEmail(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Email field is required"
                          isCheckValidEmailValue={true}
                        />
                        {/* </CardContent> */}
                        {/* <CardContent> */}
                        <FormControl variant="standard" fullWidth>
                          <InputPasswordField
                            type="password"
                            lable="Password"
                            name={password}
                            rows={10}
                            multiline={false}
                            placeHolder="Enter password"
                            className="form-textfield-styles"
                            value={password}
                            handleChange={(e) => setPassword(e.target.value)}
                            clickToShowPassowrd={handleClickShowPassword}
                            hoverToPassword={handleMouseDownPassword}
                            showPassword={isShowPassword}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="Password field is required"
                          />
                        </FormControl>
                      </CardContent>
                      <CardContent>
                        <Typography
                          variant="h6"
                          className={classes.forgotpass}
                          gutterBottom
                          component="div"
                        >
                          <Link
                            className={classes.forgotpass1}
                            to={ROUTES.forgotPassword}
                          >
                            {" "}
                            Forgot Password?{" "}
                          </Link>
                        </Typography>
                        <div className="logbutton">
                          <button type="submit" className="btn btn-style">
                            Login
                          </button>
                        </div>
                      </CardContent>
                    </Form>
                  </Card>
                </Box>
              </Col>
            </Row>
          </Container>
          <footer className={classes.footer}>
            <Container>
              <Row>
                <Col lg="12" sm="12">
                  <p className={classes.FooterText}>© 2023 Powered By Driving School Cloud.  All rights reserved.</p>
                </Col>
              </Row>
            </Container>
          </footer>
        </section>

      </StyledRoot>
    </MainComponent>

  );
};
export default Login;
