import { ActionProps } from "../../ts";
import { LIST_OF_PROGRAMS, LIST_OF_PROGRAMS_ERROR, LIST_OF_PROGRAM_FEE, LIST_OF_PROGRAM_FEE_ERROR } from "../ActionTypes";

const initialState = { programs: null,programFee:null };

const programStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_PROGRAMS: {
      return {
        ...state,
        programs: payload,
      };
    }
    case LIST_OF_PROGRAMS_ERROR: {
      return {
        ...state,
        programs: null,
      };
    }
    case LIST_OF_PROGRAM_FEE: {
      return {
        ...state,
        programFee: payload,
      };
    }
    case LIST_OF_PROGRAM_FEE_ERROR: {
      return {
        ...state,
        programFee: null,
      };
    }
    default:
      return state;
  }
};

export default programStore;