export const CommunicationFilterTabs = [
  {
    lable: "All Messages",
    value: "",
  },
  // {
  //   lable: "Received",
  //   value: "1",
  // },
  // {
  //   lable: "Sent",
  //   value: "2",
  // },
  // {
  //   lable: "Failed",
  //   value: "3",
  // },
  {
    lable: "Important",
    value: "4",
  },
  {
    lable: "Archived",
    value: "5",
  },
  {
    lable: "Draft",
    value: "6",
  },
  // {
  //   lable: "Status",
  //   value: "7",
  // },
];

export const SchoolPaymentFilterTabs = [
  {
    lable: "All payments",
    value: "all",
  },
  {
    lable: "Received",
    value: "received",
  },
  {
    lable: "Failed",
    value: "failed",
  },
  {
    lable: "Refunded",
    value: "refunded",
  },
];

export const SchoolFilterTabs = [
  {
    lable: "All school list",
    value: "all",
  },
];

export const ProgramsFilterTabs = [
  {
    lable: "All Programs",
    value: "all",
  },
  {
    lable: "Active Programs",
    value: "1",
  },
  {
    lable: "Inactive Programs",
    value: "0",
  },
];

export const GenerateInvoiceFilterTabs = [
  {
    lable: "All Invoices",
    value: "all",
  },
  {
    lable: "Saved",
    value: "saved",
  },
];

export const AssetsManagementFilterTabs = [
  {
    lable: "Assets Managements",
    value: "all",
  },
];

export const Settings = [
  {
    lable: "School Settings",
    value: "School Settings",
  },
  {
    lable: "Tax (%) Details",
    value: "Tax (%) Details",
  },
  {
    lable: "Whitelist Ip's",
    value: "Whitelist Ip's",
  },
  {
    lable: "Payment Key",
    value: "Payment Key",
  },
];

export const StudentContractFilterTabs = [
  {
    lable: "All Student Contract List",
    value: "all",
  },
];

export const PaymentTab = [
  {
    lable: "All Payments",
    value: "All Payments",
  },
  {
    lable: "Transaction",
    value: "Transaction",
  },
  {
    lable: "Failed",
    value: "Failed",
  },
  {
    lable: "Refunded",
    value: "Refunded",
  },
];

export const StudentPaymentTab = [
  {
    lable: "All Payments",
    value: "All Payments",
  },
  {
    lable: "Transaction",
    value: "Transaction",
  },
  {
    lable: "Failed",
    value: "Failed",
  },
];

export const StaffManagementFilterTabs = [
  {
    lable: "All Staff / Instructor List",
    value: "all",
  },
];

export const ProgramDetails = [
  {
    lable: "Program Details",
    value: "Program Details",
  },
  {
    lable: "Course Fee",
    value: "Course Fee",
  },
];
