import { ActionProps } from "../../ts";
import {
    LIST_OF_SCHOOL_SETTINGS,
    LIST_OF_SCHOOL_SETTINGS_ERROR,
    LIST_OF_TAX,
    LIST_OF_TAX_ERROR,
    LIST_OF_WHITE_IP,
    LIST_OF_WHITE_IP_ERROR
} from "../ActionTypes";

const initialState = { settings: null };

const settingStore = (state: any = initialState, action: ActionProps) => {
    const { type, payload } = action;
    switch (type) {
        case LIST_OF_SCHOOL_SETTINGS: {
            return {
                ...state,
                settings: payload,
                taxs:null,
                whiteip:null
            };
        }
        case LIST_OF_SCHOOL_SETTINGS_ERROR: {
            return {
                ...state,
                settings: null,
                taxs:null,
                whiteip:null
            };
        }
        case LIST_OF_TAX: {
            return {
                ...state,
                taxs: payload,
                whiteip:null
            };
        }
        case LIST_OF_TAX_ERROR: {
            return {
                ...state,
                taxs: null,
                whiteip:null
            };
        }
        case LIST_OF_WHITE_IP: {
            return {
                ...state,
                whiteip:payload
            };
        }
        case LIST_OF_WHITE_IP_ERROR: {
            return {
                ...state,
                whiteip:null
            };
        }
        default:
            return state;
    }
};

export default settingStore;