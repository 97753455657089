import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_ASSETS_MANAGEMENT, LIST_OF_ASSETS_MANAGEMENT_ERROR } from "../ActionTypes";
import { LIMIT, PAGE, ADD_ASSETS_MANAGEMENT_API, ASSETS_MANAGEMENT_LIST_API } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
        "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
    },
};

export const addAssets = async (userObj: any, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .post(ADD_ASSETS_MANAGEMENT_API, body, header)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const getAssets = async (id: string, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    return await axios
        .get(`${ADD_ASSETS_MANAGEMENT_API}/${id}`, header)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const updateAssets = async (userObj: any, id: string | number, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .put(`${ADD_ASSETS_MANAGEMENT_API}/${id}`, body, header)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const AssetsManagementList = (order: string, page = PAGE, limit = LIMIT, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    return async (dispatch: any) => {
        return await axios
            .get(`${ASSETS_MANAGEMENT_LIST_API}?orderBy=${order}&page=${page}&limit=${limit}`, header)
            .then((res) => {
                dispatch({
                    type: LIST_OF_ASSETS_MANAGEMENT,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: LIST_OF_ASSETS_MANAGEMENT_ERROR,
                });
                toast.error(error.response.data.errorMsg);
            });
    };
};
