import { ActionProps } from "../../ts";
import { LIST_OF_PLAN_HISTORY, LIST_OF_PLAN_HISTORY_ERROR, LIST_OF_SINGLE_PLAN_HISTORY, LIST_OF_SINGLE_PLAN_HISTORY_ERROR } from "../ActionTypes";

const initialState = { plans: null, singlePlans: null };

const licenseStore = (state: any = initialState, action: ActionProps) => {
    const { type, payload } = action;
    switch (type) {
        case LIST_OF_PLAN_HISTORY: {
            return {
                ...state,
                plans: payload,
                plans_data: null,
            };
        }
        case LIST_OF_PLAN_HISTORY_ERROR: {
            return {
                ...state,
                plans: null,
                plans_data: null,
            };
        }
        case LIST_OF_SINGLE_PLAN_HISTORY: {
            return {
                ...state,
                singlePlans: payload,
            };
        }
        case LIST_OF_SINGLE_PLAN_HISTORY_ERROR: {
            return {
                ...state,
                singlePlans: null,
            };
        }
        default:
            return state;
    }
};

export default licenseStore;