import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  floatLeftText: {
    float: "left",
    color: "#5f5f5f",
    fontWeight: "normal"
  },
}));

type HeadingProps = {
  text: String,
  children: React.ReactNode
}

const ComponentHeading: React.FC<HeadingProps> = ({ text, children }): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <h6 className={classes.floatLeftText}>
        {children}
        {text}
      </h6>
    </>
  )
}

export default ComponentHeading
