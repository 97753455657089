import React, { useEffect, useState } from "react";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import { ROUTES } from "../../constants/routes";
import AssetManagementIcon from "../../svg_icons/Assetmanagement.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TableRow } from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Visibility } from "@mui/icons-material";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { yellow, green, red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { Row, Col, Button } from "react-bootstrap";
import "../Common.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
    InputDatePicker,
    InputField,
    InputSelectField,
} from "../../common-components/FormComponent/FormFieldsComponent";
import {
    SaveButtonComponent,
} from "../../common-components/ButtonComponent";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
    DATATABLELIMIT,
    PAGE
} from "../../reducer-store/constants";
import { bindActionCreators } from "redux";
import * as Actions from "../../reducer-store/actions/assetsManagementAction";
import { isRequiredField, isValidRegisterID } from "../../common-components/FormComponent/FormValidator";
import {
    ASSET_ADDED_SUCCESSFULLY, ASSET_UPDATED_SUCCESSFULLY
} from "../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import moment from "moment";
import ToastMessage from "../../common-components/ToastMessage";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import { IoAddCircleSharp } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { RiEyeFill } from "react-icons/ri";
import { IoWarning } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    iconDiv: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
    },
    dialogContent: {
        textAlign: "center",
    },
    icon: {
        width: '30%',
        height: 'auto',
        color: "#000",
    },
    saveEmailButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize'
    },
    saveEmailButton1: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: "0.875rem",
    },
    switchRight: {
        textAlign: "end",
    },
    searchBar: {
        borderColor: "black",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
    dialogToggleContent: {
        textAlign: "center",
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center"
    },
    dialogAction: {
        justifyContent: "center !important",
    },
    yesButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    noButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '15px',
    }
}));

const BreadCrumbDashboard = [
    {
        labelText: "Home",
        linkUrl: ROUTES.home,
    },
    {
        labelText: "Assets",
        linkUrl: ROUTES.assetManagement,
    },
    {
        labelText: "All Assets",
        linkUrl: "",
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        color: "black",
                        fontFamily: "Arial",
                    },
                    "& .tss-1akey0g-MUIDataTableHeadCell-data": {
                        color: "black",
                        fontFamily: "Arial",
                    },
                    "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
                        color: "black",
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-ylo811-MUIDataTableSelectCell-headerCell": {
                        borderBottomColor: '#707070',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root ": {
                        borderBottomColor: '#707070',
                        borderBottomWidth: '1.2px',
                    },
                    "& .css-crz6gg-MuiTableCell-root": {
                        font: "normal normal normal 15px/17px Arial",
                        color: '#000000'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    // fontSize: "15px",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#DFDFDF"
                    },
                    "&:nth-child(even)": {
                        backgroundColor: "#F9F9F9"
                    },
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    lineHeight: "1.30 !important"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

const AssetsManagement: React.FC = () => {
    const classes = useStyles();
    // const [filter, setFilters] = useState("all");
    const [search, setSearch] = useState('');
    const [popUpLabel, setPopUpLabel] = useState("Add");
    const [open, setOpen] = React.useState(false);
    const [openDialog, setDialogOpen] = React.useState(false);
    const [toggleStatus, setToggleStatus] = useState('');
    const [disableAssetId, setDisableAssetId] = useState('');
    const [disableAssetReg, setDisableAssetReg] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const [vehicleReg, setVehicleReg] = useState("");
    const [nextServiceDate, setNextServiceDate] = useState<any>(null);
    const [plateExpiry, setPlateExpiry] = useState<any>(null);
    const [annualSafetyExpiry, setAnnualSafetyExpiry] = useState<any>(null);
    const [insuranceExpiry, setInsuranceExpiry] = useState<any>(null);
    const [createdDate, setCreatedDate] = useState<any>(null);
    const [type, setType] = useState("");
    const [page, setPage] = useState(PAGE);
    const [order, setOrder] = useState("desc");
    const [rowsPerPage, setRowsPerPage] = useState(DATATABLELIMIT);
    const dispatch = useDispatch();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const assestList = useSelector((state: any) => state.asset);
    const { assets } = assestList;
    const { addAssets, getAssets, updateAssets } = Actions
    const { AssetsManagementList } = bindActionCreators(Actions, dispatch)
    const [status, setStatus] = useState(1);
    const [assetsId, setAssetsId] = useState("");
    const [disabled, setDisabled] = useState(false);
    const history = useHistory();
    const [reload, setReload] = useState(false);
    const [data, setData] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);
    const [filterVal, setFilterVal] = useState('');
    const [searchData, setSearchData] = useState(false);

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#616161" }} {...other}>
                {open && popUpLabel === "Add" ? <IoAddCircleSharp style={{ marginRight: 10, marginBottom: 4 }} /> : popUpLabel === "Edit" ? < FaRegEdit style={{ marginRight: 10, marginBottom: 4 }} /> : <RiEyeFill color="#FFFFFF" size={24} style={{ marginRight: 10, marginBottom: 4 }} />}

                {children}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[100],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
        );
    };

    const handlefilter = (e: any) => {
        if (e.target.value == "") {
            setData(searchApiData);
        } else {
            const filterData = searchApiData.filter((items: any) =>
                items.Vehicle.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.id.toString().includes(e.target.value.toString())
                || items.NextServiceDate.toString().includes(e.target.value.toString())
                || items.PlateExpiry.toString().includes(e.target.value.toLowerCase())
                || items.AnnualSafetyExpiry.toString().includes(e.target.value.toString())
                || items.InsuranceExpiry.toString().includes(e.target.value.toString())
                || items.vehicle_type.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
            );
            if (filterData.length > 0) {
                setData(filterData);
                setSearchData(false);
            } else {
                setData([]);
                setSearchData(true);
            }
        }
        setFilterVal(e.target.value);
    };

    useEffect(() => {
        (async () => {
            const newAssetsList: any = await AssetsManagementList(order, page, rowsPerPage, token);
            {
                newAssetsList.data.data.map((date: any) => {
                    if (date.status && (moment().isAfter(moment(date?.plate_expiry)) || moment().isAfter(moment(date?.annual_safety_expiry)) || moment().isAfter(moment(date?.insurance_valid)))) {
                        handleDisableWithout(date.id);
                    }
                })
            }
            renderDataTable(newAssetsList);
        })();
    }, [page, order, search, reload, openDialog]);

    const handleClose = () => {
        setOpen(false);
        setIsSubmit(false);
    };

    const openAssetModal = (lable: string) => {
        setPopUpLabel(lable);
        setDisabled(false);
        resetData();
        setAssetsId("");
        setOpen(true);
    };

    /*     const handleSearch = (value: string) => {
            setSearch(value);
        }; */
    const handleClickOpenEditView = async (lable: string, id: string, disabledField: boolean = false) => {
        setOpen(false);
        setPopUpLabel(lable);
        setAssetsId(id);
        const assetsInfo = await getAssets(id, token);
        if (!assetsInfo.error) {
            const { data } = assetsInfo;
            setVehicleReg(data.vehicle_registration);
            setNextServiceDate(moment(data.due_service_date).format('YYYY-MM-DD'));
            setPlateExpiry(moment(data.plate_expiry).format('YYYY-MM-DD'));
            setInsuranceExpiry(moment(data.insurance_valid).format('YYYY-MM-DD'));
            setAnnualSafetyExpiry(moment(data.annual_safety_expiry).format('YYYY-MM-DD'));
            setCreatedDate(moment(data.created_at).format('YYYY-MM-DD'));
            setType(data.type)
            setStatus(data.status);
            setOpen(true);
            setDisabled(disabledField);
        }
    };

    const resetData = () => {
        setVehicleReg("");
        setNextServiceDate(null);
        setPlateExpiry(null);
        setInsuranceExpiry(null);
        setAnnualSafetyExpiry(null);
        setCreatedDate("");
        setType("");
        setStatus(1);
        setReload(false);
    };

    const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        if (
            isRequiredField(vehicleReg) &&
            isValidRegisterID(vehicleReg) &&
            isRequiredField(nextServiceDate) &&
            isRequiredField(plateExpiry) &&
            isRequiredField(annualSafetyExpiry) &&
            isRequiredField(insuranceExpiry) &&
            isRequiredField(type)
        ) {
            setIsSubmit(false);
            let data: any = {
                vehicle_registration: vehicleReg,
                type: type,
                due_service_date: nextServiceDate,
                plate_expiry: plateExpiry,
                annual_safety_expiry: annualSafetyExpiry,
                insurance_valid: insuranceExpiry,

            }
            if (assetsId == "") {
                data.status = status
            }
            let resData: any = assetsId !== "" ? await updateAssets(data, assetsId, token) : await addAssets(data, token);
            if (!resData.error) {
                toast.success(assetsId !== "" ? ASSET_UPDATED_SUCCESSFULLY : ASSET_ADDED_SUCCESSFULLY);
                AssetsManagementList(order, page, rowsPerPage, token);
                setTimeout(() => {
                    resetData();
                    setReload(true);
                    handleClose();
                }, 2000);
            }
        }
        setIsValidValues(false);
    };

    const renderDataTable = (newAssetsList: any) => {
        const rowsDataTable = newAssetsList && newAssetsList.data.data.map((row: any, index: any) => {
            return {
                id: index + 1,
                Vehicle: row?.vehicle_registration,
                vehicle_type: row?.type || "--",
                NextServiceDate: moment(row.due_service_date).format("YYYY-MM-DD"),
                PlateExpiry: moment(row.plate_expiry).format("YYYY-MM-DD"),
                AnnualSafetyExpiry: moment(row.annual_safety_expiry).format("YYYY-MM-DD"),
                InsuranceExpiry: moment(row.insurance_valid).format("YYYY-MM-DD"),
                EnabledDisable: <>
                    <div>
                        <Switch
                            color={row.status ? "success" : "warning"}
                            checked={row.status ? true : false}
                            onChange={() => handleClickOpen(row?.id, row?.status, row?.vehicle_registration)}
                        />
                    </div>
                </>,
                action: <>
                    <div className={classes.iconDiv}>
                        {moment(row.plate_expiry).diff(moment(), 'days') <= 15 && moment(row.plate_expiry).diff(moment(), 'days') >= 0 ||
                            moment(row.annual_safety_expiry).diff(moment(), 'days') <= 15 && moment(row.annual_safety_expiry).diff(moment(), 'days') >= 0 ||
                            moment(row.insurance_valid).diff(moment(), 'days') <= 15 && moment(row.insurance_valid).diff(moment(), 'days') >= 0 ?
                            <span>
                                <Tooltip
                                    title={"Please check the asset validity"}
                                    // moment(row.plate_expiry).diff(moment(), 'days') <= 15 && moment(row.plate_expiry).diff(moment(), 'days') >= 0 ? "Plate validity is about to expire" :
                                    //         moment(row.annual_safety_expiry).diff(moment(), 'days') <= 15 && moment(row.annual_safety_expiry).diff(moment(), 'days') >= 0 ? "Annual validity is about to expire" :
                                    //             moment(row.insurance_valid).diff(moment(), 'days') <= 15 && moment(row.insurance_valid).diff(moment(), 'days') >= 0 ? "Insurance validity is about to expire" : "Two or more validities are about to expire"
                                    placement="top-start">
                                    <WarningRoundedIcon style={{ color: '#fbc02d', marginRight: '15px' }} />
                                </Tooltip>
                            </span>
                            : null}

                        {/* <span onClick={() => handleClickOpenEditView("View", row.id, true)}>
                            <Visibility sx={{ color: yellow[700], marginRight: '15px', cursor: 'pointer' }} />
                        </span> */}
                        <span onClick={() => handleClickOpenEditView("Edit", row.id, false)}>
                            <FaRegEdit style={{ cursor: 'pointer' }} size={22} color="#00A651" />
                        </span>
                    </div>
                </>
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const handleClickOpen = async (id: any, state: any, vehicleReg: any) => {
        setToggleStatus(state ? '0' : '1');
        setDisableAssetId(id);
        setDisableAssetReg(vehicleReg);
        setTimeout(() => {
        }, 1000);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDisableWithout = async (assetsId: string) => {
        let data = {
            status: 0,
        }
        let resData: any = await updateAssets(data, assetsId, token);
        if (!resData.error) {
            setTimeout(() => {
                setDialogOpen(false);
            }, 2000);
        }
    }

    const handleDisable = async (assetsId: string, state: any, vehicleReg: any) => {
        let data = {
            status: state,
        }
        let resData: any = await updateAssets(data, assetsId, token);
        if (!resData.error) {
            toast.success(ASSET_UPDATED_SUCCESSFULLY);
            setTimeout(() => {
                setDialogOpen(false);
            }, 2000);
        }
    }

    const columns = [
        {
            label: 'Sr No',
            name: "id",
        },
        {
            label: 'Vehicle Reg',
            name: "Vehicle",
        },
        {
            label: 'Vehicle Type',
            name: "vehicle_type",
        },
        {
            label: "Next Service Date",
            name: "NextServiceDate",
        },
        {
            label: "Plate Expiry",
            name: "PlateExpiry",
        },
        {
            label: "Annual Safety Expiry",
            name: "AnnualSafetyExpiry",
        },
        {
            label: "Insurance Expiry",
            name: "InsuranceExpiry",
        },

        {
            label: 'Enabled / Disable',
            name: "EnabledDisable",
            options: {
                sort: false
            }
        },
        {
            label: 'Action',
            name: "action",
            options: {
                sort: false
            }
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        selectableRows: "none",
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && assets?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box>
                ,
            },
        },
        customToolbar: () => {
            return (<>
                <TextField
                    variant="standard"
                    placeholder="Search"
                    onInput={(e: any) => handlefilter(e)}
                    value={filterVal}
                    className={classes.searchBar}
                    InputProps={{
                        style: { color: "black" },
                        endAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                />

            </>)
        },
    };

    const handleNextService = (date: any) => {
        setNextServiceDate(date);
    };

    const handlePlateExpiry = (date: any) => {
        setPlateExpiry(date);
    };

    const handleAnnualSafety = (date: any) => {
        setAnnualSafetyExpiry(date);
    };

    const handleInsurance = (date: any) => {
        setInsuranceExpiry(date);
    };


    return (
        <>
            <ToastMessage />
            <Row>
                <Col lg="6" sm="12">
                    <CommonHeadPart
                        componentText="Assets"
                        Icon={AssetManagementIcon}
                        buttonText=""
                        urlLink="/"
                        BreadCrumbs={BreadCrumbDashboard}
                    />
                </Col>
                <Col lg="6" sm="12" className={classes.switchRight}>
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.saveEmailButton}
                        onClick={() => openAssetModal("Add")}
                    >
                        Add New Asset
                    </Button>
                </Col>
            </Row>
            <div className={classes.divInsideMain}>
                {/* <FilterTabs
                    SelectedFilter={filter}
                    Filters={AssetsManagementFilterTabs}
                    HandleChange={setFilters}
                    isFilter={false}
                    HandleSearch={handleSearch}
                    isSearch={false}
                /> */}

                <ThemeProvider theme={theme} >
                    <MUIDataTable
                        title={"All Asset List"}
                        data={data}
                        columns={columns}
                        options={options}
                    /*  components={components} */
                    />
                </ThemeProvider>

                <BootstrapDialog
                    onClose={handleClose}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        {popUpLabel} Assets
                    </BootstrapDialogTitle>
                    <form onSubmit={SubmitHandler}>
                        <DialogContent dividers>
                            <Row>
                                <Col lg="4" sm="12">
                                    <InputField
                                        type="text"
                                        lable="Vehicle reg"
                                        name="vehicleReg"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Vehicle Reg"
                                        className="form-textfield-styles"
                                        value={vehicleReg}
                                        handleChange={(e) => setVehicleReg(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Vehicle reg field is required"
                                        disabled={disabled}
                                        isCheckValidRID={true}
                                    />
                                </Col>
                                <Col lg="4" sm="12">
                                    {/* <InputField
                                        type="date"
                                        lable="Next Service Date"
                                        name="nextService"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Next Service Date"
                                        className="form-textfield-styles"
                                        value={nextServiceDate}
                                        handleChange={(e) => setNextServiceDate(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Next Service Date field is required"
                                        disabled={disabled}
                                        disablePrevious={moment(new Date()).format("YYYY-MM-DD")}
                                        disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                                    /> */}
                                    <InputDatePicker
                                        className="form-textfield-styles errorBottom"
                                        value={nextServiceDate}
                                        lable="Next Service Date"
                                        handleChange={(e) => handleNextService(e)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        disabled={disabled}
                                        min={popUpLabel === "Edit" ? nextServiceDate : moment(new Date()).format("YYYY-MM-DD")}
                                        requiredMessage="Next Service Date is required"
                                    />
                                </Col>
                                <Col lg="4" sm="12">
                                    {/* <InputField
                                        type="date"
                                        lable="Plate Expiry"
                                        name="plateExpiry"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Plate Expiry"
                                        className="form-textfield-styles"
                                        value={plateExpiry}
                                        handleChange={(e) => setPlateExpiry(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Plate Expiry field is required"
                                        disabled={disabled}
                                        disablePrevious={moment(new Date()).format("YYYY-MM-DD")}
                                        disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                                    /> */}
                                    <InputDatePicker
                                        className="form-textfield-styles errorBottom"
                                        value={plateExpiry}
                                        lable="Plate Expiry"
                                        handleChange={(e) => handlePlateExpiry(e)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        disabled={disabled}
                                        min={popUpLabel === "Edit" ? plateExpiry : moment(new Date()).format("YYYY-MM-DD")}
                                        requiredMessage="Plate Expiry Date is required"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" sm="12">
                                    {/* <InputField
                                        type="date"
                                        lable="Annual Safety Expiry"
                                        name="annualSafetyExpiry"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Annual Safety Expiry"
                                        className="form-textfield-styles"
                                        value={annualSafetyExpiry}
                                        handleChange={(e) => setAnnualSafetyExpiry(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Annual Safety Expiry field is required"
                                        disabled={disabled}
                                        disablePrevious={moment(new Date()).format("YYYY-MM-DD")}
                                        disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                                    /> */}
                                    <InputDatePicker
                                        className="form-textfield-styles errorBottom"
                                        value={annualSafetyExpiry}
                                        lable="Annual Safety Expiry"
                                        handleChange={(e) => handleAnnualSafety(e)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        disabled={disabled}
                                        min={popUpLabel === "Edit" ? annualSafetyExpiry : moment(new Date()).format("YYYY-MM-DD")}
                                        requiredMessage="Annual Safety Expiry Date is required"
                                    />
                                </Col>
                                <Col lg="4" sm="12">
                                    {/* <InputField
                                        type="date"
                                        lable="Insurance Expiry"
                                        name="insuranceExpiry"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Insurance Expiry"
                                        className="form-textfield-styles"
                                        value={insuranceExpiry}
                                        handleChange={(e) => setInsuranceExpiry(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Insurance Expiry field is required"
                                        disabled={disabled}
                                        disablePrevious={moment(new Date()).format("YYYY-MM-DD")}
                                        disableUpcoming={moment(new Date("2100-01-01")).format("YYYY-MM-DD")}
                                    /> */}
                                    <InputDatePicker
                                        className="form-textfield-styles errorBottom"
                                        value={insuranceExpiry}
                                        lable="Insurance Expiry"
                                        handleChange={(e) => handleInsurance(e)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        disabled={disabled}
                                        min={popUpLabel === "Edit" ? insuranceExpiry : moment(new Date()).format("YYYY-MM-DD")}
                                        requiredMessage="Insurance Expiry Date is required"
                                    />
                                </Col>
                                <Col lg="4" sm="12">
                                    {/* <InputField
                                        type="date"
                                        lable="Created Date"
                                        name="createdDate"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Created Date"
                                        className="form-textfield-styles"
                                        value={createdDate ? createdDate : moment(new Date()).format("YYYY-MM-DD")}
                                        handleChange={(e) => { }}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Created Date field is required"
                                        disabled={true}
                                    // popUpLabel == "Edit" ? !disabled : disabled
                                    /> */}
                                    <InputDatePicker
                                        className="form-textfield-styles errorBottom"
                                        value={createdDate ? createdDate : moment(new Date()).format("YYYY-MM-DD")}
                                        lable="Created Date"
                                        handleChange={(e) => { }}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        disabled={true}
                                        requiredMessage="Created Date is required"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="4" sm="12">
                                    <InputSelectField
                                        select={true}
                                        lable="Type"
                                        name="type"
                                        value={type}
                                        className="form-textfield-styles"
                                        options={[
                                            { id: "", name: "Select type" },
                                            { id: "Tractor", name: "Tractor" },
                                            { id: "Trailer", name: "Trailer" },
                                            { id: "Car", name: "Car" },
                                            { id: "Bus", name: "Bus" },
                                            { id: "Minibus", name: "Minibus" },
                                            { id: "Heavy Equipment", name: "Heavy Equipment" },
                                            { id: "Others", name: "Others" },
                                        ]}
                                        handleChange={(e) => setType(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Type field is required"
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col lg="4" sm="12">
                                    <Typography variant="subtitle1" component="div">
                                        Enabled / Disable
                                    </Typography>
                                    <Switch disabled={disabled} color={status ? "success" : "primary"} onChange={(e) => setStatus(e.target.checked ? 1 : 0)} defaultChecked={status ? true : false} />
                                </Col>
                            </Row>
                        </DialogContent>
                        {
                            !disabled &&
                            <DialogActions>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={handleClose}
                                    className={classes.saveEmailButton1}
                                >
                                    Cancel
                                </Button>
                                <SaveButtonComponent ButtonType="submit" ButtonLabel={assetsId === "" ? "Save" : "Update"} />
                            </DialogActions>
                        }
                    </form>
                </BootstrapDialog>

                <BootstrapDialog
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleCloseDialog}
                    >
                        Asset Enable/Disable
                    </BootstrapDialogTitle>

                    <DialogContent dividers className={classes.dialogToggleContent} >
                        <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
                        {/* <img className={classes.icon} sizes={"10"} src={Icon} /> */}
                        <Typography variant="h6" component="div">
                            Are you sure you want to  {toggleStatus === "0" ? "disable" : "enable"} this asset?
                        </Typography>

                    </DialogContent>

                    <DialogActions className={classes.dialogAction} >
                        {/* <CancelButtonComponent ButtonLabel="No" /> */}
                        <Button
                            type="button"
                            variant="contained"
                            className={classes.noButton}
                            onClick={handleCloseDialog}
                        >
                            No
                        </Button>
                        {/* <SaveButtonComponent
                        ButtonType="submit"
                        ButtonLabel="Yes"
                    /> */}
                        <Button
                            type="button"
                            variant="contained"
                            className={classes.yesButton}
                            onClick={() => handleDisable(disableAssetId, toggleStatus, disableAssetReg)}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    );
};

export default AssetsManagement;
