import { BrowserRouter, HashRouter, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/LoginPages/Login";
import ForgotPassword from "./pages/LoginPages/Forgotpass";
import ResetPassword from "./pages/LoginPages/Newpassword";
import GenerateOTP from "./pages/LoginPages/OnetimePass";
import OTP from "./pages/LoginPages/Onetime_otp";
import OTPF2 from "./pages/LoginPages/Onetimepass_f2";
import { InnerPageRoutes, ROUTES } from "./constants/routes";
import Layout from "./components/Layout";
import ComposeMessgeForm from "./pages/Communication/Form/ComposeMessage";
import {
  Dashboard,
  AssetManagement,
  StudentContractManagement,
  StaffManagement,
  NewStaffInstructor,
  Payments,
  Setting,
  Programs,
  NewStudentContract,
  Communication
} from "./pages";
import { PublicRoute, PrivateRoute } from "./CustomRoute";
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import NewProgram from "./pages/Programs/Form/NewProgram";
import ChangePassword from "./common-components/ChangePassword/ChangePassword";
import ChangePasswordOTP from "./common-components/ChangePassword/OTP";
import Newpassword from "./common-components/ChangePassword/NewPassword";
import Profile from "./pages/LoginPages/Profile";
import MessageReply from "./pages/Communication/MessageReply";
import ReplyMessage from "./pages/Communication/ReplyMessage";
import ViewProgram from "./pages/Programs/ProgramDetailsView/ViewProgram";
import ViewStaffInstructor from "./pages/StaffManagement/Form/ViewStaffInstructor";
import ViewStudentContract from "./pages/StudentContractManagement/Form/ViewStudentContract"
import MyPlan from "./pages/MyPlan/MyPlan";
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import ThankUpgrade from "./pages/MyPlan/ThankUpgrade";
// End Dashboard

// import "bootstrap/dist/css/bootstrap.min.css";
import "./pages/Common.css";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import StudentPayments from "./pages/StudentPayments/Payments";
// import { useDispatch } from "react-redux";
// import { useEffect } from "react";
// import { USER_LOGOUT } from "./reducer-store/ActionTypes";

const theme = createTheme({
  typography: {
    fontFamily: "Arial",
  },
});

function App() {

  return (
    <HashRouter hashType="slash">
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Switch>
            <PublicRoute path={ROUTES.login} restricted={true} exact Component={Login} />
            <PublicRoute path={ROUTES.forgotPassword} exact Component={ForgotPassword} />
            <PublicRoute path={ROUTES.newPassword} exact Component={ResetPassword} />
            <PublicRoute path={ROUTES.generateOTP} exact Component={GenerateOTP} />
            <PublicRoute path={ROUTES.addOTP} exact Component={OTP} />
            <PublicRoute path={ROUTES.v2AddOTP} exact Component={OTPF2} />
            <PublicRoute path={InnerPageRoutes.thanksUpgrade} exact Component={ThankUpgrade} />
            <Layout>
              <PrivateRoute exact path={ROUTES.dashboard} Component={Dashboard} />
              <PrivateRoute exact path={ROUTES.changePassword} Component={ChangePassword} />
              <PrivateRoute exact path={ROUTES.changePasswordOTP} Component={ChangePasswordOTP} />
              <PrivateRoute exact path={ROUTES.changeNewPassword} Component={Newpassword} />
              <PrivateRoute exact path={ROUTES.assetManagement} Component={AssetManagement} />
              <PrivateRoute exact path={ROUTES.studentContractManagement} Component={StudentContractManagement} />
              <PrivateRoute exact path={ROUTES.staffManagement} Component={StaffManagement} />
              <PrivateRoute exact path={ROUTES.payments} Component={Payments} />
              <PrivateRoute exact path={ROUTES.studentPayments} Component={StudentPayments} />
              <PrivateRoute exact path={ROUTES.settings} Component={Setting} />
              <PrivateRoute exact path={ROUTES.programs} Component={Programs} />
              <PrivateRoute exact path={ROUTES.myPlan} Component={MyPlan} />
              <PrivateRoute exact path={ROUTES.communication} Component={Communication} />
              <PrivateRoute exact path={InnerPageRoutes.composeMessage} Component={ComposeMessgeForm} />
              <PrivateRoute exact path={InnerPageRoutes.newStudentContract} Component={NewStudentContract} />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.sendDraftMessage}/:id`}
                Component={ComposeMessgeForm}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.editStudentContract}/:id`}
                Component={NewStudentContract}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.viewStudentContract}/:id`}
                Component={ViewStudentContract}
              />
              <PrivateRoute
                exact
                path={InnerPageRoutes.newStaffInstructor}
                Component={NewStaffInstructor}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.editStaffInstructor}/:id`}
                Component={NewStaffInstructor}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.viewStaffInstructor}/:id`}
                Component={ViewStaffInstructor}
              />
              <PrivateRoute
                exact
                path={InnerPageRoutes.addNewProgram}
                Component={NewProgram}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.editProgram}/:id`}
                Component={NewProgram}
              />
              <PrivateRoute
                path={ROUTES.profile}
                restricted={true}
                exact
                Component={Profile}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.messageReply}/:id`}
                Component={MessageReply}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.replyToMessage}/:id`}
                Component={ReplyMessage}
              />
              <PrivateRoute
                exact
                path={`${InnerPageRoutes.programView}/:id`}
                Component={ViewProgram}
              />
            </Layout>
          </Switch>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </HashRouter>
  );
}

export default App;
