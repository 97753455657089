import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./Header";
import Drawer from "./Drawer";
import Footer from "./Footer";
import Toolbar from "@material-ui/core/Toolbar";
import { DrawerContextProvider } from "../contexts/drawer-context";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection:'column'
  },
  main: {
    flex: 1,
    padding: "25px",
    lexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    flexDirection: 'column',
    display: 'flex',
  },
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "5px",
  },
  toolBar: {
    minHeight: "96px",
  },
  mainComponetSytly: {
    background: "#F4F4F4 0% 0% no-repeat padding-box",
  },
}));

type Props = {
  children: NonNullable<ReactNode>;
};

const Layout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <DrawerContextProvider>
      <div className={classes.root}>
        <Header />
        <Toolbar className={classes.toolBar} />
        <div className={classes.container}>
          <Drawer />
          <main
            className={`main-component ${classes.main} ${classes.mainComponetSytly}`}
          >
            {children}
          </main>
          <Footer />
        </div>
        {/* <Footer /> */}
      </div>
    </DrawerContextProvider>
  );
};

export default Layout;
