import React, { useState, useEffect, SetStateAction } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputField,
  InputSelectField,
} from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidRegisterID, isValidNo } from "../../../common-components/FormComponent/FormValidator";
import {
  CancelButtonComponent,
  SaveButtonComponent,
} from "../../../common-components/ButtonComponent";
// import Switch from "@mui/material/Switch";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
/* import TableContainer from "@mui/material/TableContainer"; */
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
/* import Paper from "@mui/material/Paper"; */
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Box from "@mui/material/Box";
/* import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"; */
import { Button, CircularProgress } from "@mui/material";
import TypographyComponent from "./TableComponent/TypographyComponent";
import * as Actions from "../../../reducer-store/actions/programsAction";
import { DropDownProgramList, ModualListByProgramId } from "../../../reducer-store/actions/topicAction";
import {
  PROGRAM_SUCCESSFULLY_CREATED,
  MODUAL_SUCCESSFULLY_CREATED,
  PROGRAM_SUCCESSFULLY_UPDATED,
  MODUAL_SUCCESSFULLY_UPDATED,
} from "../../../reducer-store/ToastMessage";
import { toast } from "react-toastify";
import "../../Common.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { LIMIT, MARKSLIMIT, PAGE } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import { ROUTES } from "../../../constants/routes";
/* import PaginationComponent from "../../../common-components/PaginationComponent"; */
import { ThemeProvider, createTheme } from '@mui/material/styles';
/* import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase'; */
import MUIDataTable from "mui-datatables";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineFolderAdd } from "react-icons/ai";

const useStyles = makeStyles(() => ({
  programCard: {
    backgroundColor: "#ececec",
  },
  button_save: {
    textAlign: "right",
  },
  addModule: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontFamily: 'Arial !important',
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "none",
    minWidth: "64px",
  },
  saveEmailButton1: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    marginRight: 20,
    marginBottom: 15,
    textTransform: 'none',
    fontSize: "0.875rem",
  },
  fieldDisable: {
    color: "rgba(0, 0, 0, 0.38) !important",
    fontSize: "16px !important",
  },
  linkstyle: {
    textDecoration: "none",
    color: "black"
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  }
}));

type AddProgramModulesProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  editProgramInfo?: any;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          color: '#FFFFFF',
          "& .css-1ygcj2i-MuiTableCell-root": {
            color: "#FFFFFF",
            fontweight: "bold",
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#404040",
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "white",
          },
          "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
            color: "white"
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .css-pfof31-MuiTableCell-root ": {
            color: "#FFFFFF"
          },
          "& .tss-10syd3x-MUIDataTableHeadCell-root": {
            fontSize: '15px',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
        },
        head: {
          backgroundColor: "#404040",
          color: '#FFFFFF'
        },
        footer: {
          backgroundColor: '#FFFFFF',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .css-1ex1afd-MuiTableCell-root": {
            width: "18%",
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          "& .css-140puxv-MuiTypography-root-MuiDialogTitle-root": {
            backgroundColor: '#646464'
          }

        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: '#FFFFFF'
        }
      }
    }
  }
});

export default function AddProgramModules({
  handleStep,
  stepValue,
}: AddProgramModulesProps) {
  const classes = useStyles();
  const params: any = useParams();
  const { id } = params;
  const [open, setOpen] = React.useState(false);
  const [programName, setProgramName] = useState("");
  // const [displayProgram, setDisplayProgram] = useState("");
  const [addProgramCheck, setAddProgramCheck] = useState(false);
  const [programCode, setProgramCode] = useState("");
  const [totalHours, setTotalHours] = useState<any>(null);
  const [inClassTrainingHours, setInclassTrainingHours] = useState<any>(null);
  const [inYardTrainingHours, setInyardTrainingHours] = useState<any>(null);
  const [inCabTrainingHours, setIncabTrainingHours] = useState<any>(null);
  const [vehicleType, setVehicleType] = useState("");
  const [description, setDescription] = useState("");
  const [meltCompatible, setMeltCompatible] = useState(false);
  const [saveUpdate, setSaveUpdate] = useState(true);
  const [editProgramInfos, setEditProgramInfos] = useState<any>(null);
  const [status, setStatus] = useState('0');
  const [data, setData] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [filterVal, setFilterVal] = useState('');

  // const handleFilter = (e: any) => {
  //   if (e.target.value == "") {
  //     setData(searchApiData);
  //   } else {
  //     const filterData = searchApiData.filter((items: any) =>
  //       items.module_name.toLowerCase().includes(e.target.value.toLowerCase())
  //       || items.inclass.toString().includes(e.target.value.toString())
  //       || items.inyard.toString().includes(e.target.value.toString())
  //       || items.hours.toString().includes(e.target.value.toString())
  //       || items.incab.toString().includes(e.target.value.toString()));
  //     setData(filterData);
  //   }
  //   setFilterVal(e.target.value);
  // };

  // Module
  const [Program, setProgram] = useState("");
  const [programId, setProgramId] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [moduleVisible, setModuleVisible] = useState(false);
  const [moduleTotalHours, setModuleTotalHours] = useState<any>(null);
  const [moduleInClassTrainingHours, setModuleInclassTrainingHours] = useState<any>(null);
  const [moduleInYardTrainingHours, setModuleInyardTrainingHours] = useState<any>(null);
  const [moduleInCabTrainingHours, setModuleIncabTrainingHours] = useState<any>(null);
  const [modulePracticalTestMax, setModulePracticalTestMax] = useState<any>("");
  const [moduleMinimumPassTheory, setModuleMinimumPassTheory] = useState("");
  const [moduleMinimumPassPractical, setModuleMinimumPassPractical] = useState("");
  const [moduleTheoryTestMax, setModuleTheoryTestMax] = useState<any>("");
  const [moduleDependency, setModuleDependency] = useState<any>("");
  const [moduleDependencyName, setModuleDependencyName] = useState<any>("");
  const [moduleTitleObjective, setModuleTitleObjective] = useState("");
  const [moduleDescription, setModuleDescription] = useState("");
  const [programList, setProgramList] = useState([{ id: "", name: "Select Program" }])
  const [moduleList, setModuleList] = useState([{ id: "", name: "Select Module" }])

  // End Module

  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const modualList = useSelector((state: any) => state.modual);
  const { moduals } = modualList;
  const AuthToken = useSelector((state: any) => state.auth);
  const { user, token } = AuthToken;
  const allProgramList: any = useSelector((state: any) => state.program);
  const { programs } = allProgramList;
  const history = useHistory();
  const dispatch = useDispatch();
  const { addProgram, updateProgram, addModual, updateModual } = Actions;
  const { ModualList, ProgramList } = bindActionCreators(Actions, dispatch);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [moduleId, setModuleId] = useState("");
  const [addCourseVisible, setAddCourseVisible] = useState(false);
  const [dropDownModule, setDropDownModule] = useState(false);
  const [pracMarkError, setPracMarkError] = useState(false);
  const [theoryMarkError, setTheoryMarkError] = useState(false);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const editProgramData: any = await ProgramList("all", page, rowsPerPage, token, "desc", "");
        const allProgramList = editProgramData?.data;
        const filteredProgram = allProgramList?.filter((program: any, index: number) => {
          if (program.id === parseInt(params.id)) {
            return program;
          }
        })
        setEditProgramInfos(filteredProgram[0]);
      }
    })();
  }, [params]);

  useEffect(() => {
    (async () => {
      if (editProgramInfos) {
        const program_code: any = sessionStorage.getItem("program_code");
        setProgramCode(programCode);
      }
      if (!editProgramInfos) {
        const program_Id: any = sessionStorage.getItem("program_id");
        setProgramId(program_Id)
        const program_name: any = sessionStorage.getItem("program_name");
        setProgram(program_name);
        const vehicle: any = sessionStorage.getItem("vehicle_type");
        setVehicleType(vehicle);
        // const provi: any = sessionStorage.getItem("province");
        // setProvince(provi);
        const desc: any = sessionStorage.getItem("description");
        setDescription(desc);
      }
    })();
  }, [page, programId, token, open]);

  useEffect(() => {
    (async () => {
      if (programId) {
        const newModuleList = await ModualList(page, programId, rowsPerPage, token);
        renderDataTable(newModuleList);
      }
    })();
  }, [programId, open]);

  const renderDataTable = (newProgramList: any) => {
    const rowsDataTable = newProgramList && newProgramList.data.data.map((row: any) => {
      return {
        module_name: row.name,
        hours: row.total_duration_minutes !== null ? row.total_duration_minutes / 60 : "---",
        inclass: row.total_inclass_training_minutes !== null ? row.total_inclass_training_minutes / 60 : "---",
        inyard: row.total_inyard_training_minutes !== null ? row.total_inyard_training_minutes / 60 : "---",
        incab: row.total_incab_training_minutes !== null ? row.total_incab_training_minutes / 60 : "---",
        edit: <FaRegEdit style={{ cursor: 'pointer' }} size={18} color="#000000" onClick={() => handleEditModule(row)} />,
        // <><DriveFileRenameOutlineIcon color="#000000" onClick={() => handleEditModule(row)} /></>,
        practical_mark: row.practical_assessment_max_marks,
        min_theory_percent: row?.minimum_pass_percent_theory,
        min_practical_percent: row?.minimum_pass_percent_practical,
        theory_test: row.theory_test_max_marks,
        dependency: row.dependency !== null ? row.dependency : '--',
        objective: row.title_objective !== null ? row.title_objective : "--",
        description: row.description !== null ? row.description : "--",
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  useEffect(() => {
    if (editProgramInfos) {
      setProgramId(editProgramInfos && editProgramInfos.id);
      sessionStorage.setItem("program_id", programId);
      setProgramName(editProgramInfos && editProgramInfos.name);
      // setDisplayProgram(editProgramInfos && editProgramInfos.name);
      sessionStorage.setItem("program_name", programName);
      setTotalHours(editProgramInfos && editProgramInfos.total_duration_minutes);
      setProgramCode(editProgramInfos && editProgramInfos.program_code ? editProgramInfos.program_code : "");
      sessionStorage.setItem("program_code", programCode);
      setInclassTrainingHours(editProgramInfos && editProgramInfos.total_inclass_training_minutes);
      setInyardTrainingHours(editProgramInfos && editProgramInfos.total_inyard_training_minutes);
      setIncabTrainingHours(editProgramInfos && editProgramInfos.total_incab_training_minutes);
      setVehicleType(editProgramInfos && editProgramInfos.vehicle_type);
      setDescription(editProgramInfos && editProgramInfos.description);
      setMeltCompatible(editProgramInfos && editProgramInfos.melt_compatible ? editProgramInfos.melt_compatible : false);
      setStatus(editProgramInfos && editProgramInfos.status === 1 ? "1" : "0");
    }
  }, [editProgramInfos, programName]);

  useEffect(() => {
    (async () => {
      const progrmArr = await DropDownProgramList(page, token);
      setProgramList([...programList, ...progrmArr.data])
    })();
  }, [])

  useEffect(() => {
    (async () => {
      if (programId) {
        const moduleArr = await ModualListByProgramId(programId, token);
        setModuleList(moduleArr.data.length > 0 ? [{ id: "", name: "Select Module" }, ...moduleArr.data] : [{ id: "", name: "No Module Found" }])
      } else {
        setModuleList([{ id: "", name: "Select Module" }])
      }
    })();
  }, [programId, dropDownModule])

  const handleClickOpen = () => {
    setOpen(true);
    resetModule();
    setSaveUpdate(true);
    setIsSubmit(false);
    setModuleVisible(true);
  };

  const handleClose = () => {
    setPracMarkError(false);
    setTheoryMarkError(false);
    setOpen(false);
    setIsSubmit(false);
    setModuleVisible(true);
  };

  const handleEditModule = async (row: any) => {
    setModuleVisible(false);
    setProgram(row.program_id);
    setModuleId(row.id);
    setModuleName(row.name);
    setModuleTotalHours(row.total_duration_minutes / 60);
    setModuleInclassTrainingHours(row.total_inclass_training_minutes / 60);
    setModuleInyardTrainingHours(row.total_inyard_training_minutes / 60);
    setModuleIncabTrainingHours(row.total_incab_training_minutes / 60);
    setModulePracticalTestMax(row.practical_assessment_max_marks);
    setModuleMinimumPassTheory(row?.minimum_pass_percent_theory);
    setModuleMinimumPassPractical(row?.minimum_pass_percent_practical);
    setModuleTheoryTestMax(row.theory_test_max_marks ? row.theory_test_max_marks : "");
    setModuleDependency(row.dependency ? row.dependency : "");
    setModuleTitleObjective(row.title_objective ? row.title_objective : "");
    setModuleDescription(row.description);
    setOpen(true);
    setPracMarkError(false);
    setTheoryMarkError(false);
    setSaveUpdate(false);
  }

  const resetModule = () => {
    // setProgram("");
    setModuleName("")
    setModuleTotalHours(null)
    setModuleInclassTrainingHours(null)
    setModuleInyardTrainingHours(null)
    setModuleIncabTrainingHours(null)
    setModulePracticalTestMax("")
    setModuleMinimumPassPractical("")
    setModuleMinimumPassTheory("")
    setModuleTheoryTestMax("")
    setModuleDependency("")
    setModuleTitleObjective("")
    setModuleDescription("")
  }

  // const resetData = () => {
  // setProgramName("");
  //   setProgramCode("");
  //   setTotalHours(null);
  //   setInclassTrainingHours(null);
  //   setInyardTrainingHours(null);
  //   setIncabTrainingHours(null);
  //   setVehicleType("");
  //   setDescription("");
  //   setMeltCompatible(false);
  // };

  const moduleDep = (e: any) => {
    const moduleName = moduleList.find((module) => module.id == e)
    setModuleDependencyName(moduleName?.name);
  }

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(programName) &&
      isRequiredField(vehicleType) &&
      isValidRegisterID(vehicleType) &&
      isRequiredField(description)
    ) {
      setIsSubmit(false);
      let programObj = {
        name: programName,
        description: description,
        duration_minutes: Math.floor(totalHours * 60),
        inclass_training_minutes: Math.floor(inClassTrainingHours * 60),
        inyard_training_minutes: Math.floor(inYardTrainingHours * 60),
        incab_training_minutes: Math.floor(inCabTrainingHours * 60),
        vehicle_type: vehicleType,
        total_score: totalHours,
        melt_compatible: meltCompatible,
        tenant_id: user.school_id,
        school_id: user.school_id,
        assessment_name: "",
        assessment_type: "",
        credential_types: "",
      };
      if (addProgramCheck === true && programId) {
        let resData: any = await updateProgram(editProgramInfos.id, programObj, token);
        if (!resData.error) {
          toast.success(PROGRAM_SUCCESSFULLY_UPDATED);
          setTimeout(() => {
            // resetData();
            setModuleVisible(true);
            sessionStorage.setItem("program_id", programId);
            sessionStorage.setItem("program_name", programName);
            setSaveUpdate(true);
            // setOpen(true);
            // return history.push(ROUTES.programs);
          }, 2000);
        }
        return;
      } else if (addProgramCheck === false && programId === null) {
        let resData: any = await addProgram(programObj, token);
        if (!resData.error) {
          toast.success(PROGRAM_SUCCESSFULLY_CREATED);
          setTimeout(() => {
            setModuleVisible(!moduleVisible);
            // setOpen(true);
            sessionStorage.setItem("program_id", resData.data.data.id);
            sessionStorage.setItem("program_name", programName);
            sessionStorage.setItem("vehicle_type", vehicleType);
            // sessionStorage.setItem("province", province);
            sessionStorage.setItem("description", description);
            setProgramId(resData.data.data.id);
            // setDisplayProgram(programName);
            // resetData();
            resetModule();
            setSaveUpdate(true);
            setIsSubmit(false);
            // handleStep((stepValue) => stepValue + 1);
          }, 2000);
        }
      }
      // else if (addProgramCheck === false && programId) {
      //   setModuleVisible(true);
      //   setSaveUpdate(true);
      //   setOpen(true);
      //   return;
      // } else {
      //   setModuleVisible(true);
      //   setSaveUpdate(true);
      //   setOpen(true);
      //   return;
      // }
    }
    setIsValidValues(false);
  };

  const SubmitHandlerCourses = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    handleStep((stepValue) => stepValue + 1);
  };

  const SubmitHandlerModual = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);

    if (modulePracticalTestMax?.length == 0) {
      setPracMarkError(true);
    } else if (moduleTheoryTestMax?.length == 0) {
      setTheoryMarkError(true);
      return
    }

    if (!modulePracticalTestMax && !moduleTheoryTestMax || (modulePracticalTestMax == 0 && moduleTheoryTestMax == 0)) {
      setPracMarkError(true);
      setTheoryMarkError(true);
      // toast.warning("Please enter a valid value or 0 if assessment not required.");
    } else {
      setTheoryMarkError(false);
      setPracMarkError(false);
      console.log("Ready to go")

      if (
        isRequiredField(programName) &&
        isRequiredField(moduleName) &&
        isRequiredField(moduleMinimumPassTheory) &&
        isValidNo(moduleMinimumPassTheory) &&
        isRequiredField(moduleMinimumPassPractical) &&
        isValidNo(moduleMinimumPassPractical) &&
        isRequiredField(moduleTheoryTestMax) &&
        isValidNo(moduleTheoryTestMax) &&
        isRequiredField(modulePracticalTestMax) &&
        isValidNo(modulePracticalTestMax) &&
        isRequiredField(moduleTitleObjective) &&
        isRequiredField(moduleDescription)
      ) {
        setIsSubmit(false);
        let programObj = {
          program_id: programId,
          name: moduleName,
          description: moduleDescription,
          duration_minutes: moduleTotalHours * 60,
          inclass_training_minutes: moduleInClassTrainingHours * 60,
          inyard_training_minutes: moduleInYardTrainingHours * 60,
          incab_training_minutes: moduleInCabTrainingHours * 60,
          minimum_pass_percent_theory: moduleMinimumPassTheory,
          minimum_pass_percent_practical: moduleMinimumPassPractical,
          dependency: moduleDependencyName,
          title_objective: moduleTitleObjective,
          theory_test_max_marks: moduleTheoryTestMax,
          practical_assessment_max_marks: modulePracticalTestMax,
          status: 1,
          vehicle_type: vehicleType,
          tenant_id: "",
        };

        let resData: any = saveUpdate ? await addModual(programObj, token) : await updateModual(moduleId, programObj, token);
        if (!resData.error) {
          toast.success(saveUpdate ? MODUAL_SUCCESSFULLY_CREATED : MODUAL_SUCCESSFULLY_UPDATED);
          setTimeout(() => {
            // resetData();
            ModualList(page, programId, LIMIT, token);
            setOpen(false);
            setDropDownModule(!dropDownModule);
            setAddCourseVisible(true);
          }, 2000);
        }
      }
    }
    setIsValidValues(false);
  };

  const columns = [
    {
      label: 'Module name',
      name: "module_name",
    },
    {
      label: 'Total hours',
      name: "hours",
    },
    {
      label: 'Total inclass training hours',
      name: "inclass",
    },
    {
      label: 'Total inyard training hours',
      name: "inyard",
    },
    {
      label: 'Total incab training hours',
      name: "incab",
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
    {
      label: "pra",
      name: "practical_mark",
      options: {
        display: false,
      }
    },
    {
      label: "min_theory_percent",
      name: "min_theory_percent",
      options: {
        display: false,
      }
    },
    {
      label: "min_practical_percent",
      name: "min_practical_percent",
      options: {
        display: false,
      }
    },
    {
      label: "theory",
      name: "theory_test",
      options: {
        display: false,
      }
    },
    {
      label: "dependency",
      name: "dependency",
      options: {
        display: false,
      }
    },
    {
      label: 'Title of objective',
      name: 'objective',
      options: {
        display: false,
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        display: false,
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: (moduals == null && data?.length == 0 && programId) ?
          <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#707070' }} />
          </Box>
          : 'Sorry, there is no matching data to display',
      },
    },
    // customToolbar: () => {
    //   return (<>
    //     <TextField
    //       variant="standard"
    //       placeholder="Search"
    //       onInput={(e: any) => handleFilter(e)}
    //       value={filterVal}
    //       className={classes.searchBar}
    //       InputProps={{
    //         style: { color: "black" },
    //         endAdornment: (
    //           <IconButton>
    //             <SearchOutlined />
    //           </IconButton>
    //         ),
    //       }}
    //     />
    //   </>)
    // },
    renderExpandableRow: (rowData: any) => {
      return (
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={7}
          >
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Max Practical Assessment Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Practical Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Max Theory Test Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Theory Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Dependency On Another Module
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      {rowData[6]}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      {rowData[8]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[9]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[7]}
                    </StyledTableCell >
                    <StyledTableCell align="center">
                      {rowData[10] ? rowData[10] : "--"}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

              <hr style={{ marginTop: 5, color: "#000000", opacity: 1 }} />

              <div style={{ flexDirection: "row", display: 'flex' }}>
                <Typography fontWeight={700} color="#404040" >
                  Title Of Objective:
                </Typography>
                <Typography sx={{ marginLeft: 1 }} color="#606060" >
                  {rowData[11] !== null ? rowData[11] : "---"}
                </Typography>
              </div>

              <Typography fontWeight={700} color="#404040">
                Description:
              </Typography>
              <Typography color="#606060">
                {rowData[12] !== null ? rowData[12] : "---"}
              </Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      );
    },
  };

  const handleChange = (e: any) => {
    if (programId && e) {
      setAddProgramCheck(true);
    } else {
      setAddProgramCheck(false);
    }
  }

  const onChangeMaxMarks = (value: any, setMethod: any) => {
    const re = /^([0-9\b][0-9\b]{0,2}|1000)$/;
    if (value === '' || re.test(value))
      setMethod(value);
  }

  const onChangeProgram = (value: any, setMethod: any) => {
    var regexp = /^[a-zA-Z0-9_]+$/;
    if (value === '' || regexp.test(value))
      setMethod(value);
  }

  return (
    <>
      <ToastMessage />
      <form onSubmit={SubmitHandler}>
        <Card className={classes.programCard}>
          <CardContent>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled={programId ? true : false}
                  type="text"
                  lable="Program Name"
                  name={programName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  value={programName}
                  handleChange={(e) => onChangeProgram(e.target.value, setProgramName)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Program Name field is required"
                />
              </Col>
              {
                id !== undefined ?
                  <>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Program Code (auto generate)
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {programCode !== "" ? programCode : "---"}
                        <hr style={{ marginTop: 5 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {totalHours !== null ? (+totalHours) / 60 : "---"}
                        <hr style={{ marginTop: 5 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inclass Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inClassTrainingHours !== null ? (+inClassTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 5 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inYardTrainingHours !== null ? (+inYardTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 5 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Incab Training Hours
                      </Typography>
                      <Typography className={classes.fieldDisable}>
                        {inCabTrainingHours !== null ? (+inCabTrainingHours) / 60 : "---"}
                        <hr style={{ marginTop: 5 }} />
                      </Typography>
                    </Col>
                  </>
                  : ""
              }
              <Col lg="8" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={description}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter description"
                  className="form-textfield-styles"
                  value={description}
                  handleChange={(e) => { setDescription(e.target.value); handleChange(e.target.value) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Vehicle Type"
                  name={vehicleType}
                  value={vehicleType}
                  className="form-textfield-styles"
                  options={[
                    { id: "", name: "Select type" },
                    { id: "tractor", name: "Tractor" },
                    { id: "trailer", name: "Trailer" },
                    { id: "car", name: "Car" },
                    { id: "bus", name: "Bus" },
                    { id: "minibus", name: "Minibus" },
                    { id: "heavy equipment", name: "Heavy Equipment" },
                    { id: "others", name: "Others" },
                  ]}
                  handleChange={(e) => { setVehicleType(e.target.value); handleChange(e.target.value) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Vehicle Type field is required"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div className={classes.button_save}>
                  <Link to={ROUTES.programs} className={classes.linkstyle}>
                    <CancelButtonComponent ButtonLabel="Cancel" />
                  </Link>
                  {" "}
                  <Button
                    disabled={addProgramCheck || programId === null ? false : true}
                    type="submit"
                    className={classes.addModule}
                    variant="contained"
                  >
                    Save
                  </Button>
                  {" "}
                  <Button
                    disabled={programId === null ? true : false}
                    type="button"
                    onClick={handleClickOpen}
                    className={classes.addModule}
                    variant="contained"
                  >
                    Add Modules
                  </Button>
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
        <TypographyComponent label="Modules List" />

        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider >
      </form>
      <form onSubmit={SubmitHandlerCourses}>
        <Row className="mt-4">
          <Col lg="12" sm="12">
            <div className="stepperButton">
              <Button
                type="submit"
                className={classes.addModule}
                variant="contained"
                disabled={editProgramInfos || programId ? false : true}
              >
                {id ? "Add/Edit Course" : "Add Course"}
              </Button>
            </div>
          </Col>
        </Row>
      </form>

      <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <form onSubmit={SubmitHandlerModual}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {moduleVisible ? <AiOutlineFolderAdd size={28} style={{ marginBottom: 4, marginRight: 4 }} />
              :
              <FaRegEdit size={20} style={{ marginBottom: 6, marginRight: 4 }} color="#FFFFFF" />}
            {moduleVisible ? "Add Modules and Objective" : "Update Modules and Objective"}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {saveUpdate ?
              <Typography variant="h6" gutterBottom component="div" >
                Add Module
              </Typography> :
              <Typography variant="h6" gutterBottom component="div">
                Update Module
              </Typography>
            }
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Program Name"
                  name={programName}
                  value={programName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Program field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled={moduleVisible ? false : true}
                  type="text"
                  lable="Module Name"
                  name={moduleName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Module Name"
                  className="form-textfield-styles"
                  value={moduleName}
                  handleChange={(e) => onChangeProgram(e.target.value, setModuleName)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Module Name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              {
                saveUpdate ? "" :
                  <>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Hours
                      </Typography>
                      <Typography>
                        {moduleTotalHours !== null ? moduleTotalHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inclass Training Hours
                      </Typography>
                      <Typography>
                        {moduleInClassTrainingHours !== null ? moduleInClassTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography>
                        {moduleInYardTrainingHours !== null ? moduleInYardTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Incab Training Hours
                      </Typography>
                      <Typography>
                        {moduleInCabTrainingHours !== null ? moduleInCabTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                  </>
              }
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Max Practical Assessment Marks"
                  name={modulePracticalTestMax}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Max Practical Assessment Marks"
                  className="form-textfield-styles errorBottom"
                  value={modulePracticalTestMax}
                  handleChange={(e) => onChangeMaxMarks(e.target.value, setModulePracticalTestMax)}
                  isValidValue={false}
                  isSubmit={false}
                  isCheckValidNoValue={true}
                  requiredMessage="Max Practical Assessment Marks field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
                {
                  pracMarkError ? <small className="text-danger font-weight-bold">{"Please enter a valid value or 0 if assessment not required"}</small> : null
                }
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Minimum Practical Pass Percentage"
                  name={moduleMinimumPassPractical}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Minimum Practical Pass Percentage"
                  className="form-textfield-styles"
                  value={moduleMinimumPassPractical}
                  handleChange={(e) => setModuleMinimumPassPractical(e.target.value.slice(0, MARKSLIMIT))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Minimum Practical Pass Percentage field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Max Theory Test Marks"
                  name={moduleTheoryTestMax}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Max Theory Test Marks"
                  className="form-textfield-styles errorBottom"
                  value={moduleTheoryTestMax}
                  handleChange={(e) => onChangeMaxMarks(e.target.value, setModuleTheoryTestMax)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Max Theory Test Marks field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
                {
                  theoryMarkError ? <small className="text-danger font-weight-bold">{"Please enter a valid value or 0 if assessment not required"}</small> : null
                }
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Minimum Theory Pass Percentage"
                  name={moduleMinimumPassTheory}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Minimum Theory Pass Percentage"
                  className="form-textfield-styles"
                  value={moduleMinimumPassTheory}
                  handleChange={(e) => setModuleMinimumPassTheory(e.target.value.slice(0, MARKSLIMIT))}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Minimum Theory Pass Percentage field is required"
                  isCheckValidRID={true}
                  disablePrevious="0"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Dependency on Another Module"
                  name={moduleDependency}
                  value={moduleVisible ? moduleDependency : moduleList.find((mod: any) => { if (mod.name == moduleDependency) { return mod.name } })?.id}
                  className="form-textfield-styles"
                  options={moduleList}
                  handleChange={(e) => { setModuleDependency(e.target.value); moduleDep(e.target.value) }}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Module field is required"
                />
              </Col>
            </Row>
            <div className="stline"></div>
            {saveUpdate ?
              <Typography variant="h6" gutterBottom component="div">
                Add Objective
              </Typography> :
              <Typography variant="h6" gutterBottom component="div">
                Update Objective
              </Typography>
            }
            <Row>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Title of Objective "
                  name={moduleTitleObjective}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Title of Objective "
                  className="form-textfield-styles"
                  value={moduleTitleObjective}
                  handleChange={(e) => setModuleTitleObjective(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Title of Objective field is required"
                  isCheckValidRID={true}
                />
              </Col>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={moduleDescription}
                  rows={4}
                  multiline={true}
                  placeHolder="Enter Description"
                  className="form-textfield-styles"
                  value={moduleDescription}
                  handleChange={(e) => setModuleDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                />
              </Col>
            </Row>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className={classes.saveEmailButton1}
            >
              Cancel
            </Button>
            {" "}
            {
              saveUpdate ?
                <SaveButtonComponent ButtonType="submit" ButtonLabel="Save" /> :
                <SaveButtonComponent ButtonType="submit" ButtonLabel="Update" />
            }
          </DialogActions>
        </form>
      </BootstrapDialog>
    </>
  );
};
