import axios from "axios";
import { Dispatch } from "react";
import { toast } from "react-toastify";
import {
  GET_SCHOOL_UPDATED_DATA,
  GET_SCHOOL_UPDATED_DATA_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  OTP_SENT,
  OTP_SENT_ERROR,
  OTP_VERIFY,
  OTP_VERIFY_ERROR,
  PLANLIST,
  PLANLIST_FAIL,
  RESEND_OTP,
  RESEND_OTP_ERROR,
  SELECT_OTP_PLATFORM,
  TAX_DETAILS,
  TAX_DETAILS_ERROR,
  USER_LOADING_ERROR,
  USER_LOGOUT,
} from "../ActionTypes";
import {
  ADMIN_LOGIN_API,
  ADMIN_RESEND_LOGIN_OTP_API,
  ADMIN_RESEND_LOGIN_OTP_API_PHONE,
  ADMIN_SEND_LOGIN_OTP_API,
  ADMIN_SEND_LOGIN_OTP_API_PHONE,
  ADMIN_VERIFY_LOGIN_OTP_API,
  config,
  GET_PROVINCE_LIST,
  LOGOUT_API,
  PLAN_LIST_API,
  PROVINCE_TAX,
  RESET_PASSWORD_API,
  RESET_PASSWORD_OTP_ON_EMAIL,
  RESET_PASSWORD_OTP_VERIFY,
  SCHOOL_DETAILS_API,
  SIGNED_URL,
  USER_DETAILS_BY_ID,
} from "../constants";
import { useDispatch } from "react-redux";

type AdminLoginProps = {
  email: string;
  password: string;
};
export const adminLogin = (userObj: AdminLoginProps) => {
  return async (dispatch: Dispatch<any>) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADMIN_LOGIN_API, body, config)
      .then((res) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });
        return res;
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAIL });
        return error?.response?.data;
      });
  };
};

type SendOTPProps = {
  email?: string;
  mobile?: string;
};
export const sentVerificationOTPCode = (
  userObj: SendOTPProps,
  type: string
) => {
  return async (dispatch: any) => {
    const API_URL =
      type === "email"
        ? ADMIN_SEND_LOGIN_OTP_API
        : ADMIN_SEND_LOGIN_OTP_API_PHONE;
    return await axios
      .post(API_URL, userObj)
      .then((res) => {
        dispatch({
          type: OTP_SENT,
          payload: res?.data,
          otpType: type,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: OTP_SENT_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

type VerifyOTPProps = {
  email?: string;
  mobile?: string;
  otp: string;
  password: string
};

export const verifyOTPCode = (userObj: VerifyOTPProps) => {
  return async (dispatch: any) => {
    const body = JSON.stringify(userObj);
    return await axios
      .post(ADMIN_VERIFY_LOGIN_OTP_API, body, config)
      .then((res) => {
        dispatch({
          type: OTP_VERIFY,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: OTP_VERIFY_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const resendVerificationOTPCode = (
  userObj: SendOTPProps,
  type: string
) => {
  return async (dispatch: any) => {
    const API_URL =
      type === "email"
        ? ADMIN_RESEND_LOGIN_OTP_API
        : ADMIN_RESEND_LOGIN_OTP_API_PHONE;

    return await axios
      .post(API_URL, userObj)
      .then((res) => {
        dispatch({
          type: RESEND_OTP,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: RESEND_OTP_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

export const updateOtpPlatFormValue = (otpType: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: SELECT_OTP_PLATFORM,
      payload: otpType,
    });
  };
};

export const sentForgotPasswordOTPCode = async (email: string) => {
  return await axios
    .get(`${RESET_PASSWORD_OTP_ON_EMAIL}/${email}`)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const verifyForgotPasswordOTPCode = async (userObj: any) => {
  const body = JSON.stringify(userObj);
  return await axios
    .post(RESET_PASSWORD_OTP_VERIFY, body, config)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);

    });
};

export const updateForgotPassword = async (userObj: any) => {
  const body = JSON.stringify(userObj);
  return await axios
    .put(RESET_PASSWORD_API, body, config)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
    });
};

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const fileSignedURL = async (url: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${SIGNED_URL}?key=${url}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const GetProvinceList = async () => {
  const { data }: any = await axios.get(GET_PROVINCE_LIST);
  return data.data[0].Provinces;
};

export const updateProfile = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(`${SCHOOL_DETAILS_API}`, userObj, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response;
    });
};

export const SingleSchoolUser = (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SCHOOL_DETAILS_API}`, header)
      .then((res) => {
        dispatch({
          type: GET_SCHOOL_UPDATED_DATA,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: GET_SCHOOL_UPDATED_DATA_ERROR,
        });
        toast.error(error?.response?.data?.errorMsg);
        return error?.response;
      });
  };
};

export const ProvinceTaxList = (token: string, province_id: string,) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${PROVINCE_TAX}?province_id=${province_id}`, header)
      .then((res) => {
        dispatch({
          type: TAX_DETAILS,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: TAX_DETAILS_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error?.response;
      });
  };
};

export const planList = () => {
  return async (dispatch: any) => {
    return await axios
      .get(PLAN_LIST_API)
      .then((res) => {
        dispatch({
          type: PLANLIST,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: PLANLIST_FAIL,
        });
        toast.error(error?.response?.data?.errorMsg);
      });
  };
};

const headers = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const SingleUserData = (id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async () => {
    return await axios
      .get(`${USER_DETAILS_BY_ID}/${id}`, headers)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const logoutUser = async (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(LOGOUT_API, {}, header)
    .then((res: any) => {
      return res?.data
    })
    .catch((error) => {
      return error?.response?.data;
    });
};