import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_NOTIFICATION, LIST_OF_NOTIFICATION_ERROR } from "../ActionTypes";
import { NOTIFICATION_LIST, NOTIFICATION_UPDATE } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const NotificationList = (role_id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${NOTIFICATION_LIST}/${role_id}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_NOTIFICATION,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_NOTIFICATION_ERROR,
        });
        // toast.error(error.response.data.errorMsg);
      });
  };
};

export const ReadNotification = async (id: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .patch(`${NOTIFICATION_UPDATE}/${id}`, {}, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error?.response?.data;
    });
};