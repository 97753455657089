import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../constants/routes"
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
  InputDatePicker,
  InputField,
  InputSelectField
} from "../../../common-components/FormComponent/FormFieldsComponent";
import TextField from "@material-ui/core/TextField";
import { CancelButtonComponent, SaveButtonComponent } from "../../../common-components/ButtonComponent";
import CommonHeadPart from '../../../common-components/CommonHeadPart';
import CloseIcon from '@mui/icons-material/Close';
import { isRequiredField, isValidAddress, isValidEmail, isValidNo, isValidPhone, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/StaffManagementAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  STAFF_SUCCESSFULLY_ADDED, STAFF_SUCCESSFULLY_UPDATED
} from "../../../reducer-store/ToastMessage";
import { CONTACTNO, LARGELIMIT, PHONELIMIT } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment";
// import Switch from '@mui/material/Switch';
import StaffManagementIcon from "../../../svg_icons/StaffManagement.svg";
import { fileSignedURL } from "../../../reducer-store/actions/authActions";
import AttachmentIcon from '@mui/icons-material/Attachment';
import FieldError from "../../../common-components/FormComponent/ErrorMessage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { IoIosWarning } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GetProvinceList } from "../../../reducer-store/actions/authActions";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
  CardTitle: {
    color: "black",
  },
  button_add: {
    backgroundColor: "#00a651 !important",
    color: "#ffffff !important",
    border: "#00A651 !important",
    lineHeight: "1 !important",
    "&:hover": {
      backgroundColor: "#00a651 !important",
      color: "#ffffff !important",
      border: "#00A651 !important",
    },
    "&:focus": {
      backgroundColor: "#00a651 !important",
      color: "#ffffff !important",
      border: "#00A651 !important",
      boxShadow: "none !important",
    },
    textTransform: 'capitalize'
  },
  button_delete: {
    textTransform: 'capitalize',
    backgroundColor: "#dc3545",
    color: "#ffffff",
    border: "#dc3545",
    "&:hover": {
      backgroundColor: "#dc3545",
      color: "#ffffff",
      border: "#dc3545",
    },
    "&:focus": {
      backgroundColor: "#dc3545 !important",
      color: "#ffffff",
      border: "#dc3545",
      boxShadow: "none !important",
    },
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit",
  },
  inputlabel: {
    fontSize: "16px",
    fontWeight: 600,
    display: "flex"
  },
  uploadfile: {
    fontSize: "14px",
    fontWeight: 450,
    cursor: 'pointer',
  },
  iconBack: {
    cursor: 'pointer'
  },
  yesButton: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontFamily: 'Arial !important',
    fontSize: '15px',
    textTransform: 'capitalize'
  },
  noButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
    fontSize: '15px',
  },
  dialogContent: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center"
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const [name, setname] = useState("");

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const NewStaffInstructor: React.FC = () => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const params: any = useParams();
  const { id } = params;
  const [isValidValues, setIsValidValues] = useState(true);
  const [newdocument, setAddNewdocument] = useState([{ file: '' }]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectType, setSelectType] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState("+1");
  const [dateofbirth, setDateOfBirth] = useState<any>(null);
  const [houseaptnumber, setHouseAptNumber] = useState("");
  const [streetname, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postCode, setPostcode] = useState("");
  const [country, setCountry] = useState("Canada");
  const [emergencyContact, setEmergencyContact] = useState("+1");
  const [jobStartDate, setJobStartDate] = useState<any>(null);
  const [jobEndDate, setJobEndDate] = useState<any>(null);
  const [employementType, setEmployementType] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [jobType, setJobType] = useState("");
  const [photoId, setPhotoID] = useState<any>([]);
  const [isPhotoId, setIsPhotoID] = useState<any>(true);
  const [profilePhoto, setprofilePhoto] = useState<any>([]);
  const [isProfilePhoto, setIsProfilePhoto] = useState<any>(true);
  const [drivingLicense, setDrivingLicense] = useState<any>([]);
  const [drivingRecord, setDrivingRecord] = useState<any>([]);
  const [experienceLetter, setExperienceLetter] = useState<any>([]);
  const [refLetter1, setRefLetter1] = useState<any>([]);
  const [refLetter2, setRefLetter2] = useState<any>([]);
  const [policeCertificate, setPoliceCertificate] = useState<any>([]);
  const [isDrivingLicense, setIsDrivingLicense] = useState<any>(true);
  const [medicalCertificate, setMedicalCertificate] = useState<any>([]);
  const [isMedicalCertificate, setIsMedicalCertificate] = useState<any>(true);
  const [doc1, setDoc1] = useState<any>([]);
  const [doc2, setDoc2] = useState<any>([]);
  const [doc3, setDoc3] = useState<any>([]);
  const [doc4, setDoc4] = useState<any>([]);
  const [doc1_type, setDoc1_type] = useState<any>("");
  const [doc2_type, setDoc2_type] = useState<any>("");
  const [doc3_type, setDoc3_type] = useState<any>("");
  const [doc4_type, setDoc4_type] = useState<any>("");
  /* const [otherDoc, setOtherDoc] = useState<any>({ files: [] }); */
  const [saveUpdate, setSaveUpdate] = useState(true)
  const [status, setStatus] = useState('1')
  const { addStaffManagement, updateStaffManagement } = Actions;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token, user } = AuthToken;
  const history = useHistory();
  const dispatch = useDispatch();
  const { SingleStaffManagementData } = bindActionCreators(Actions, dispatch);
  const [upgradeDialog, setUpgradeDialog] = useState(false);
  const [photoIdShow, setPhotoIdShow] = useState<any>("");
  const [profilePhotoShow, setProfilePhotoShow] = useState<any>("");
  const [medicalCertificateShow, setMedicalCertificateShow] = useState<any>("");
  const [policeCertificateShow, setPoliceCertificateShow] = useState<any>("");
  const [drivingLicenseShow, setDrivingLicenseShow] = useState<any>("");
  const [experienceShow, setExperienceShow] = useState<any>("");
  const [drivingRecordShow, setDrivingRecordShow] = useState<any>("");
  const [refLetter1Show, setRefLetter1Show] = useState<any>("");
  const [refLetter2Show, setRefLetter2Show] = useState<any>("");
  const [doc1Show, setDoc1Show] = useState<any>("");
  const [doc2Show, setDoc2Show] = useState<any>("");
  const [doc3Show, setDoc3Show] = useState<any>("");
  const [doc4Show, setDoc4Show] = useState<any>("");
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  const [photoIdImgError, setPhotoIdImgError] = useState(false);
  const [photoIdImgErrorMsg, setPhotoIdImgErrorMsg] = useState("");
  const [medicalCertiError, setMedicalCertiError] = useState(false);
  const [medicalCertiMsg, setMedicalCertiErrorMsg] = useState("");
  const [policeCertiError, setPoliceCertiError] = useState(false);
  const [policeCertiMsg, setPoliceCertiErrorMsg] = useState("");
  const [drivingCertiError, setDrivingCertiError] = useState(false);
  const [drivingCertiMsg, setDrivingCertiErrorMsg] = useState("");
  const [drivingRecordError, setDrivingRecordError] = useState(false);
  const [drivingRecordErrorMsg, setDrivingRecordErrorMsg] = useState("");
  const [experienceError, setExperienceError] = useState(false);
  const [experienceErrorMsg, setExperienceErrorMsg] = useState("");
  const [refLetter1Error, setRefLetter1Error] = useState(false);
  const [refLetter1ErrorMsg, setRefLetter1ErrorMsg] = useState("");
  const [refLetter2Error, setRefLetter2Error] = useState(false);
  const [refLetter2ErrorMsg, setRefLetter2ErrorMsg] = useState("");

  const [doc1ImgError, setDoc1ImgError] = useState(false);
  const [doc1ImgErrorMsg, setDoc1ImgErrorMsg] = useState("");
  const [doc2ImgError, setDoc2ImgError] = useState(false);
  const [doc2ImgErrorMsg, setDoc2ImgErrorMsg] = useState("");
  const [doc3ImgError, setDoc3ImgError] = useState(false);
  const [doc3ImgErrorMsg, setDoc3ImgErrorMsg] = useState("");
  const [doc4ImgError, setDoc4ImgError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [yearsError, setYearsError] = useState(false);
  const [doc4ImgErrorMsg, setDoc4ImgErrorMsg] = useState("");
  const [instructorLimit, setInstructorLimit] = useState("");
  const [staffLimit, setStaffLimit] = useState("");
  const [experience, setExperience] = useState("");
  const [provinceArray, setProvinceArray] = useState([{ id: "", name: "Select Province" }]);

  const BreadCrumbNewStaffInstructor = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Staff Management",
      linkUrl: ROUTES.staffManagement,
    },
    {
      labelText: id ? "Edit Staff / Instructor" : "Add Staff / Instructor",
      linkUrl: "",
    },
  ];

  useEffect(() => {
    (async () => {
      const provinceWholeData = await GetProvinceList();
      setProvinceArray([...provinceArray, ...provinceWholeData]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const response: any = await SingleStaffManagementData(id, token);
        const { data } = response.data;
        setName(data && data.name);
        setEmail(data && data.email);
        setPhoneNumber(data && data.phone);
        setDateOfBirth(data && moment(data.dob).format("YYYY-MM-DD"));
        setHouseAptNumber(data && data.house_address);
        setStreetName(data && data.street_address);
        setCity(data && data.city);
        setSelectType(data && data.type);
        setProvince(data && data.province);
        setPostcode(data && data.postcode);
        setCountry(data && data.country);
        setEmergencyContact(data && data.emergency_phone);
        setJobStartDate(data && moment(data?.joining_date).format("YYYY-MM-DD"));
        setJobEndDate(data && data?.releiving_date ? moment(data?.releiving_date).format("YYYY-MM-DD") : "");
        setEmployementType(data && data?.employment_type);
        setTaxNumber(data && data?.tax_number);
        setJobType(data && data?.job_type);
        setStatus(data && data.status === 1 ? "1" : "0");
        setPhotoID(data && data.photoId_url);
        setPhotoIdShow(data && data.photoId_url);
        setprofilePhoto(data && data.profile_photo_url)
        setProfilePhotoShow(data && data.profile_photo_url);
        setMedicalCertificate(data && data.medical_proof_url);
        setMedicalCertificateShow(data && data.medical_proof_url);
        setPoliceCertificateShow(data && data.police_proof_url);
        setDrivingLicense(data && data.drivinglicense_proof_url);
        setDrivingLicenseShow(data && data.drivinglicense_proof_url);
        setExperienceShow(data && data?.experience_letter_url);
        setDrivingRecordShow(data && data?.driving_record_url);
        setExperience(data && data?.no_of_years);
        setRefLetter1(data && data?.reference_letter1_url);
        setRefLetter2(data && data?.reference_letter2_url);
        setDoc1(data && data?.doc1_url);
        setDoc2(data && data?.doc2_url);
        setDoc3(data && data?.doc3_url);
        setDoc4(data && data?.doc4_url);
        setDoc1Show(data && data?.doc1_url);
        setDoc2Show(data && data?.doc2_url);
        setDoc3Show(data && data?.doc3_url);
        setDoc4Show(data && data?.doc4_url);
        setDoc1_type(data && data?.doc1_type);
        setDoc2_type(data && data?.doc2_type);
        setDoc3_type(data && data?.doc3_type);
        setDoc4_type(data && data?.doc4_type);
        setSaveUpdate(false);
      }
    })();
  }, [params]);

  if (params && params.id && saveUpdate) {
    return (
      <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    )
  }

  const handleformchange = (e: any, index: any) => {
    const data = [...newdocument]
    const fileObj = e.target.files[0];
    data[index] = { file: fileObj };
    setAddNewdocument(data);
    switch (index) {
      case 0:
        let d2info = "doc2"
        if (imageValidate(fileObj, d2info)) {
          setDoc2(fileObj);
          return;
        }
        setDoc2(fileObj);
        break;
      case 1:
        let d3info = "doc3"
        if (imageValidate(fileObj, d3info)) {
          setDoc3(fileObj);
          return;
        }
        setDoc3(fileObj);
        break;
      case 2:
        let d4info = "doc4"
        if (imageValidate(fileObj, d4info)) {
          setDoc4(fileObj);
          return;
        }
        setDoc4(fileObj);
        break;
      default:
        break;
    }
  };

  const handleformchangeType = (e: any, index: any) => {
    switch (index) {
      case 1:
        setDoc3_type(e);
        break;
      case 2:
        setDoc4_type(e);
        break;
      default:
        break;
    }
  }

  const handleAddFileds = () => {
    setAddNewdocument([...newdocument, { file: '' }]);
  };

  const handleRemoveFields = (indexNum: any) => {
    const newDocArr = newdocument.filter((doc, index) => {
      if (index !== indexNum)
        return doc;
    });
    if (indexNum === 2) {
      setDoc4ImgError(false);
    }
    if (indexNum === 1) {
      setDoc3ImgError(false);
    }
    setAddNewdocument(newDocArr);
  };

  const imageValidate = (fileInfo: any, e: any) => {
    switch (e) {

      case "Photo Id":
        if (fileInfo === null) {
          setPhotoIdImgErrorMsg("Please upload Require Document");
          setPhotoIdImgError(true)
          return false;
        }
        let typeDocument = ["application/pdf"];

        if (typeDocument.includes(fileInfo?.type)) {
          setPhotoIdImgError(false)
          return true;
        } else {
          setPhotoIdImgError(true)
          setPhotoIdImgErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "profilephoto":
        if (fileInfo === null) {
          setImgErrorMsg("Please upload Require Document");
          setImgError(true)
          return false;
        }
        let pImage = ["image/jpeg", "image/jpg", "image/png"];
        if (pImage.includes(fileInfo?.type)) {
          setImgError(false)
          return true;
        } else {
          setImgError(true)
          setImgErrorMsg("Please upload valid image(jpg,jpeg,png).");
          return false;
        };

      case "medicalCer":
        if (fileInfo === null) {
          setMedicalCertiErrorMsg("Please upload Require Document");
          setMedicalCertiError(true)
          return false;
        }
        let mImage = ["application/pdf"];
        if (mImage.includes(fileInfo?.type)) {
          setMedicalCertiError(false)
          return true;
        } else {
          setMedicalCertiError(true)
          setMedicalCertiErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "policeCer":
        if (fileInfo === null) {
          setPoliceCertiErrorMsg("Please upload Require Document");
          setPoliceCertiError(true)
          return false;
        }
        let poImage = ["application/pdf"];
        if (poImage.includes(fileInfo?.type)) {
          setPoliceCertiError(false)
          return true;
        } else {
          setPoliceCertiError(true)
          setPoliceCertiErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "drivingLicense":
        if (fileInfo === null) {
          setDrivingCertiErrorMsg("Please upload Require Document");
          setDrivingCertiError(true)
          return false;
        }
        let dImage = ["application/pdf"];
        if (dImage.includes(fileInfo?.type)) {
          setDrivingCertiError(false)
          return true;
        } else {
          setDrivingCertiError(true)
          setDrivingCertiErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "drivingRecord":
        if (fileInfo === null) {
          setDrivingRecordErrorMsg("Please upload Require Document");
          setDrivingRecordError(true)
          return false;
        }
        let recImage = ["application/pdf"];
        if (recImage.includes(fileInfo?.type)) {
          setDrivingRecordError(false)
          return true;
        } else {
          setDrivingRecordError(true)
          setDrivingRecordErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "experienceLetter":
        if (fileInfo === null) {
          setExperienceErrorMsg("Please upload Require Document");
          setExperienceError(true)
          return false;
        }
        let expImage = ["application/pdf"];
        if (expImage.includes(fileInfo?.type)) {
          setExperienceError(false)
          return true;
        } else {
          setExperienceError(true)
          setExperienceErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "refLetter1":
        if (fileInfo === null) {
          setRefLetter1ErrorMsg("Please upload Require Document");
          setRefLetter1Error(true)
          return false;
        }
        let ref1Doc = ["application/pdf"];
        if (ref1Doc.includes(fileInfo?.type)) {
          setRefLetter1Error(false)
          return true;
        } else {
          setRefLetter1Error(true)
          setRefLetter1ErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "refLetter2":
        if (fileInfo === null) {
          setRefLetter2ErrorMsg("Please upload Require Document");
          setRefLetter2Error(true)
          return false;
        }
        let ref2Doc = ["application/pdf"];
        if (ref2Doc.includes(fileInfo?.type)) {
          setRefLetter2Error(false)
          return true;
        } else {
          setRefLetter2Error(true)
          setRefLetter2ErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "doc1":
        if (fileInfo === null) {
          setDoc1ImgErrorMsg("Please upload Require Document");
          setDoc1ImgError(true)
          return false;
        }
        let d1Image = ["application/pdf"];
        if (d1Image.includes(fileInfo?.type)) {
          setDoc1ImgError(false)
          return true;
        } else {
          setDoc1ImgError(true)
          setDoc1ImgErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "doc2":
        if (fileInfo === null) {
          setDoc1ImgErrorMsg("Please upload Require Document");
          setDoc1ImgError(true)
          return false;
        }
        let d2Image = ["application/pdf"];
        if (d2Image.includes(fileInfo?.type)) {
          setDoc2ImgError(false)
          return true;
        } else {
          setDoc2ImgError(true)
          setDoc2ImgErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "doc3":
        if (fileInfo === null) {
          setDoc3ImgErrorMsg("Please upload Require Document");
          setDoc3ImgError(true)
          return false;
        }
        let d3Image = ["application/pdf"];
        if (d3Image.includes(fileInfo?.type)) {
          setDoc3ImgError(false)
          return true;
        } else {
          setDoc3ImgError(true)
          setDoc3ImgErrorMsg("Please upload valid document(pdf).");
          return false;
        };

      case "doc4":
        if (fileInfo === null) {
          setDoc4ImgErrorMsg("Please upload Require Document");
          setDoc4ImgError(true)
          return false;
        }
        let d4Image = ["application/pdf"];
        if (d4Image.includes(fileInfo?.type)) {
          setDoc4ImgError(false)
          return true;
        } else {
          setDoc4ImgError(true)
          setDoc4ImgErrorMsg("Please upload valid document(pdf).");
          return false;
        };
      default:
        break;
    }
  };

  const allowOnlyNumber = (value: string, setMethod: any) => {
    const re = /^[+10-9\b]+$/;
    if (value === '' || re.test(value))
      setMethod(value);
  };

  const handleClose = () => {
    setUpgradeDialog(false);
    setStaffLimit("");
    setInstructorLimit("");
  };

  const handleImage = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    switch (event.target.name) {

      case "Photo Id":
        const info = "Photo Id"
        let file = event.target.files[0];
        if (imageValidate(file, info)) {
          setIsPhotoID(true);
          setPhotoID(file);
          return;
        }
        setPhotoID(file);
        break;

      case "profilephoto":
        let pinfo = "profilephoto"
        let file1 = event.target.files[0];
        if (imageValidate(file1, pinfo)) {
          setIsProfilePhoto(true);
          setprofilePhoto(file1);
          return;
        }
        setprofilePhoto(file1);
        break;

      case "medicalCer":
        let minfo = "medicalCer"
        let Medical = event.target.files[0];
        if (imageValidate(Medical, minfo)) {
          setIsMedicalCertificate(true);
          setMedicalCertificate(Medical);
          return;
        }
        setMedicalCertificate(Medical);
        break;

      case "policeCer":
        let poinfo = "policeCer"
        let police = event.target.files[0];
        if (imageValidate(police, poinfo)) {
          setPoliceCertificate(police);
          return;
        }
        setPoliceCertificate(police);
        break;

      case "drivingLicense":
        let dinfo = "drivingLicense"
        var driving = event.target.files[0];
        if (imageValidate(driving, dinfo)) {
          setIsDrivingLicense(true);
          setDrivingLicense(driving);
          return;
        }
        setDrivingLicense(driving);
        break;

      case "drivingRecord":
        let dRecInfo = "drivingRecord"
        var record = event.target.files[0];
        if (imageValidate(record, dRecInfo)) {
          // setIsDrivingLicense(true);
          setDrivingRecord(record);
          return;
        }
        setDrivingRecord(record);
        break;

      case "experienceLetter":
        let expInfo = "experienceLetter"
        var experience = event.target.files[0];
        if (imageValidate(experience, expInfo)) {
          // setIsDrivingLicense(true);
          setExperienceLetter(experience);
          return;
        }
        setExperienceLetter(experience);
        break;

      case "refLetter1":
        let refLetter1 = "refLetter1"
        var ref1 = event.target.files[0];
        if (imageValidate(ref1, refLetter1)) {
          // setIsDrivingLicense(true);
          setRefLetter1(ref1);
          return;
        }
        setRefLetter1(ref1);
        break;

      case "refLetter2":
        let refLetter2 = "refLetter2"
        var ref2 = event.target.files[0];
        if (imageValidate(ref2, refLetter2)) {
          // setIsDrivingLicense(true);
          setRefLetter2(ref2);
          return;
        }
        setRefLetter2(ref2);
        break;

      case "doc1":
        let d1info = "doc1"
        var Document1 = event.target.files[0];
        if (imageValidate(Document1, d1info)) {
          setDoc1(Document1);
          return;
        }
        setDoc1(Document1);
        break;

      default:
        break;
    }
  };

  let checkPhotoId;
  let checkProfilePhoto;
  let checkMedicalCertificate;
  let checkDrivingLicense;

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (photoId?.length == 0 || !photoId) {
      setPhotoIdImgError(true);
      setPhotoIdImgErrorMsg("Please upload Require Document");
      setIsPhotoID(false);
      checkPhotoId = false;
    } else {
      setPhotoIdImgError(false)
      setIsPhotoID(true);
      checkPhotoId = true;
    }

    if (profilePhoto?.length == 0 || !profilePhoto) {
      setIsProfilePhoto(false);
      checkProfilePhoto = false;
    } else {
      setIsProfilePhoto(true);
      checkProfilePhoto = true;
    }

    if (selectType == "instructor" && (medicalCertificate?.length == 0 || !medicalCertificate)) {
      setIsMedicalCertificate(false);
      checkMedicalCertificate = false;
    } else {
      setIsMedicalCertificate(true);
      checkMedicalCertificate = true;
    }

    if (selectType == "instructor" && (drivingLicense?.length == 0 || !drivingLicense)) {
      setIsDrivingLicense(false);
      checkDrivingLicense = false;
    } else {
      setIsDrivingLicense(true);
      checkDrivingLicense = true;
    }

    if (
      isRequiredField(name) &&
        isValidRegisterID(name) &&
        isRequiredField(email) &&
        isValidEmail(email) &&
        isRequiredField(selectType) &&
        isRequiredField(phoneNumber) &&
        isValidPhone(phoneNumber) &&
        isRequiredField(dateofbirth) &&
        isRequiredField(houseaptnumber) &&
        isValidAddress(houseaptnumber) &&
        isRequiredField(streetname) &&
        isValidAddress(streetname) &&
        isRequiredField(city) &&
        isRequiredField(province) &&
        isRequiredField(postCode) &&
        isValidRegisterID(postCode) &&
        isRequiredField(country) &&
        isRequiredField(jobStartDate) &&
        isRequiredField(employementType) &&
        isRequiredField(taxNumber) &&
        isRequiredField(photoId) &&
        isValidRegisterID(taxNumber) &&
        isRequiredField(jobType) ||
        isRequiredField(doc1_type) &&
        isValidRegisterID(doc1_type) &&
        isRequiredField(doc2_type) &&
        isValidRegisterID(doc2_type) &&
        isRequiredField(doc3_type) &&
        isValidRegisterID(doc3_type) &&
        isRequiredField(doc4_type) &&
        isValidRegisterID(doc4_type) &&
        checkPhotoId === true &&
        checkProfilePhoto === true &&
        checkMedicalCertificate === true &&
        checkDrivingLicense === true &&
        selectType == "instructor" ? (!yearsError) : "" &&
          selectType == "instructor" ? !dobError : ""
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("type", selectType);
      formData.append("phone", phoneNumber);
      formData.append("dob", dateofbirth);
      formData.append("house_address", houseaptnumber);
      formData.append("street_address", streetname);
      formData.append("city", city);
      // formData.append("status", status);
      formData.append("province", province);
      formData.append("postcode", postCode);
      formData.append("country", country);
      formData.append("emergency_phone", emergencyContact);
      formData.append("joining_date", jobStartDate);
      if (saveUpdate) {
        formData.append("status", "0")
      }
      if (jobEndDate) {
        formData.append("releiving_date", jobEndDate);
      }
      formData.append("employment_type", employementType);
      formData.append("tax_number", taxNumber);
      formData.append("job_type", jobType);
      formData.append("profile", profilePhoto);
      formData.append("photoId", photoId);
      formData.append("doc1", doc1);
      formData.append("doc2", doc2);
      formData.append("doc3", doc3);
      formData.append("doc4", doc4);
      formData.append("doc1_type", doc1_type);
      formData.append("doc2_type", doc2_type);
      formData.append("doc3_type", doc3_type);
      formData.append("doc4_type", doc4_type);
      selectType === "instructor" && formData.append("drivingLicense", drivingLicense);
      selectType === "instructor" && formData.append("police", policeCertificate);
      selectType === "instructor" && formData.append("medical", medicalCertificate);
      selectType === "instructor" && formData.append("drivingRecord", drivingRecord);
      selectType === "instructor" && formData.append("experienceLetter", experienceLetter);
      if (experience?.length > 0) {
        selectType === "instructor" && formData.append("no_of_years", experience);
      }
      selectType === "instructor" && formData.append("referenceLetter1", refLetter1);
      selectType === "instructor" && formData.append("referenceLetter2", refLetter2);

      let resData: any = saveUpdate ? await addStaffManagement(formData, token) : await updateStaffManagement(id, formData, token);
      if (resData?.upgradePlan) {
        {
          resData.errorMsg.includes("Instructor limit exhaused, Please upgrade your plan") ?
            setInstructorLimit(resData?.currentPlan?.instructor)
            :
            setStaffLimit(resData?.currentPlan?.staff)
        }
        setTimeout(() => {
          setUpgradeDialog(true);
        }, 1000)
      }
      else if (resData.status === 200) {
        toast.success(saveUpdate ? STAFF_SUCCESSFULLY_ADDED : STAFF_SUCCESSFULLY_UPDATED);
        setTimeout(() => {
          return history.push(ROUTES.staffManagement);
        }, 1000);
        return;
      }
      toast.error(resData?.errorMsg);
    } else {
      setIsValidValues(false);
    }
  };

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url, msg, error } = newData;
    if (error) {
      toast.error(msg);
      return;
    }
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    return;
  };

  const onChangeDob = (e: any) => {
    const currentYear = new Date().getFullYear();
    const year = new Date(e?._d).getFullYear();
    const age = currentYear - year;
    if (age < 18) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  }

  const onChangeExperience = (value: any, setMethod: any) => {
    const re = /^[+10-9\b]+$/;
    if (value === '' || re.test(value))
      setMethod(value);

    if (value < 5) {
      setYearsError(true);
    } else {
      setYearsError(false);
    }
  }

  const handleDOB = (date: any) => {
    setDateOfBirth(date);
  }

  const handleJobStart = (date: any) => {
    setJobStartDate(date);
  }

  const handleJobEnd = (date: any) => {
    setJobEndDate(date);
  }

  return (<>
    <ToastMessage />
    <Row>
      <Col lg="12" sm="12">
        <CommonHeadPart
          componentText="Staff Management"
          Icon={StaffManagementIcon}
          buttonText=""
          urlLink=""
          BreadCrumbs={BreadCrumbNewStaffInstructor}
        />
      </Col>
    </Row>
    <Card>
      <div className={classes.CardTitle}>
        <CardContent>
          <Typography variant="h5" align="justify">
            {saveUpdate ? "Add New Staff" : "Update Staff"}
          </Typography>
        </CardContent>
        <hr className="hrline" />
      </div>

      <CardContent>
        <form onSubmit={SubmitHandler}>
          <Row>
            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <InputField
                type="text"
                lable="Name"
                name={name}
                rows={1}
                multiline={false}
                placeHolder="Enter name"
                className="form-textfield-styles"
                value={name}
                handleChange={(e) => setName(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Name field is required"
                isCheckValidRID={true}
              />
            </Col>
            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <InputField
                type="email"
                lable="Email-Id"
                name={email}
                rows={1}
                multiline={false}
                placeHolder="Enter Email-Id"
                className="form-textfield-styles"
                value={email}
                handleChange={(e) => setEmail(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Email-Id field is required"
                isCheckValidEmailValue={true}
              />
            </Col>
            {/* <Col lg="4" sm="12">
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    checked={status === "1" ? true : false}
                    onChange={(e) => setStatus(e.target.checked ? "1" : "0")}
                    color={status ? "success" : "primary"}
                  />}
                label="Enable/Disable"
                labelPlacement="top"
                className="form-textfield-styles"
                style={{ fontWeight: "600" }}
              />
            </Col> */}
          </Row>

          <Row>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputSelectField
                select={true}
                lable="Select Type"
                name={selectType}
                value={selectType}
                className="form-textfield-styles"
                options={[
                  { id: "", name: "Select Type" },
                  { id: "staff", name: "General Staff" },
                  { id: "instructor", name: "Instructor" },
                ]}
                handleChange={(e) => setSelectType(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Type field is required"
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="Phone Number"
                name={phoneNumber}
                rows={1}
                multiline={false}
                placeHolder="Enter phone number"
                className="form-textfield-styles"
                value={phoneNumber}
                handleChange={(e) => allowOnlyNumber(e.target.value, setPhoneNumber)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Phone number is required"
                isCheckValidPhoneValue={true}
                max={CONTACTNO}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              {/* <InputField
                type="date"
                lable="Date of Birth"
                name={dateofbirth}
                rows={1}
                multiline={false}
                placeHolder="Enter Date of Birth"
                className="form-textfield-styles errorBottom"
                value={dateofbirth}
                handleChange={(e) => { setDateOfBirth(e.target.value); onChangeDob(e) }}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Date of Birth is required"
                disableUpcoming={moment(new Date()).format("YYYY-MM-DD")}
              /> */}
              <InputDatePicker
                className="form-textfield-styles errorBottom"
                value={dateofbirth}
                lable="Date of Birth"
                handleChange={(e) => { handleDOB(e); onChangeDob(e) }}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Date of Birth is required"
              />
              {
                dobError ? <small className="text-danger font-weight-bold">{"Minimum requirements 18 years or above"}</small> : null
              }
            </Col>
          </Row>

          <Row>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="House/Apt Number"
                name={houseaptnumber}
                rows={1}
                multiline={false}
                placeHolder="Enter House/Apt Number"
                className="form-textfield-styles"
                value={houseaptnumber}
                handleChange={(e) => setHouseAptNumber(e.target.value.slice(0, LARGELIMIT))}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="House/Apt number is required"
                isCheckValidAddress={true}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="Street Name "
                name={streetname}
                rows={1}
                multiline={false}
                placeHolder="Enter Street Name"
                className="form-textfield-styles"
                value={streetname}
                handleChange={(e) => setStreetName(e.target.value.slice(0, LARGELIMIT))}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Street Name is required"
                isCheckValidAddress={true}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="City "
                name={city}
                rows={1}
                multiline={false}
                placeHolder="Enter City"
                className="form-textfield-styles"
                value={city}
                handleChange={(e) =>
                  setCity(e.target.value.slice(0, LARGELIMIT))
                }
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="City is required"
                isCheckString={true}
              />
            </Col>
          </Row>

          <Row>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputSelectField
                select={true}
                lable="Province"
                name={province}
                value={province}
                className="form-textfield-styles"
                options={provinceArray}
                handleChange={(e) => setProvince(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Province field is required"
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="Postcode"
                name={postCode}
                rows={1}
                multiline={false}
                placeHolder="Enter Postcode"
                className="form-textfield-styles"
                value={postCode}
                handleChange={(e) =>
                  setPostcode(e.target.value)
                }
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Postcode is required"
                isCheckValidRID={true}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                disabled
                type="text"
                lable="Country"
                name={country}
                rows={1}
                multiline={false}
                placeHolder="Enter Country"
                className="form-textfield-styles"
                value={country}
                // handleChange={(e) =>
                //   setCountry(e.target.value.slice(0, LARGELIMIT))
                // }
                isValidValue={false}
                isSubmit={false}
                requiredMessage="Country is required"
                isCheckString={true}
              />
            </Col>
          </Row>

          <Row>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="Emergency Contact"
                name={emergencyContact}
                rows={1}
                multiline={false}
                placeHolder="Enter Emergency Contact"
                className="form-textfield-styles"
                value={emergencyContact}
                handleChange={(e) => allowOnlyNumber(e.target.value, setEmergencyContact)}
                isValidValue={false}
                isSubmit={false}
                requiredMessage="Emergency Contact is required"
                isCheckValidPhoneValue={false}
                max={CONTACTNO}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              {/* <InputField
                type="date"
                lable="Job Start Date"
                name={jobStartDate}
                rows={1}
                multiline={false}
                placeHolder="Enter Job Start Date"
                className="form-textfield-styles"
                value={jobStartDate}
                handleChange={(e) => setJobStartDate(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Job Start Date is required"
                disablePrevious={jobStartDate ? "" : moment(new Date()).format("YYYY-MM-DD")}
                disableUpcoming={moment(new Date("9999-01-01")).format("YYYY-MM-DD")}
              /> */}
              <InputDatePicker
                className="form-textfield-styles errorBottom"
                value={jobStartDate}
                lable="Job Start Date"
                handleChange={(e) => handleJobStart(e)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Job Start Date is required"
              />
            </Col >
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              {/* <InputField
                type="date"
                lable="Job End Date"
                name={jobEndDate}
                rows={1}
                multiline={false}
                placeHolder="Enter Job End Date"
                className="form-textfield-styles"
                value={jobEndDate}
                handleChange={(e) => setJobEndDate(e.target.value)}
                isValidValue={false}
                isSubmit={false}
                requiredMessage="Job End Date is required"
                disablePrevious={jobEndDate ? "" : moment(new Date()).format("YYYY-MM-DD")}
                disableUpcoming={moment(new Date("9999-01-01")).format("YYYY-MM-DD")}
              /> */}
              <InputDatePicker
                className="form-textfield-styles errorBottom"
                value={jobEndDate == "" ? null : jobEndDate}
                lable="Job End Date"
                handleChange={(e) => handleJobEnd(e)}
                isValidValue={false}
                isSubmit={false}
                requiredMessage="Job End Date is required"
                min={jobStartDate ? jobStartDate : ""}
              />
            </Col>
          </Row>

          <Row>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              {/* <InputField
                type="text"
                lable="Employment Type Label"
                name={employementType}
                rows={1}
                multiline={false}
                placeHolder="Enter Employment Type Label"
                className="form-textfield-styles"
                value={employementType}
                handleChange={(e) =>
                  setEmployementType(e.target.value)
                }
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Employment Type Label is required"
              /> */}
              <InputSelectField
                select={true}
                lable="Employment Type Label"
                name={employementType}
                value={employementType}
                className="form-textfield-styles"
                options={[
                  { id: "", name: "Select Employment Type" },
                  { id: "Permanent", name: "Permanent" },
                  { id: "Temporary", name: "Temporary" },
                  { id: "Contract", name: "Contract" },
                  { id: "Trainee", name: "Trainee" },
                ]}
                handleChange={(e) => setEmployementType(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Employment Type field is required"
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputField
                type="text"
                lable="Tax Number"
                name={taxNumber}
                rows={1}
                multiline={false}
                placeHolder="Enter Tax Number"
                className="form-textfield-styles"
                value={taxNumber}
                handleChange={(e) => setTaxNumber(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Tax Number is required"
                isCheckValidRID={true}
              />
            </Col>
            <Col xl="4" lg="4" md="4" sm="12" xs="12">
              <InputSelectField
                select={true}
                lable="Job Type"
                name={jobType}
                value={jobType}
                className="form-textfield-styles"
                options={[
                  { id: "", name: "Select Job Type" },
                  { id: "1", name: "Full Time" },
                  { id: "2", name: "Part Time" },
                ]}
                handleChange={(e) => setJobType(e.target.value)}
                isValidValue={isValidValues}
                isSubmit={isSubmit}
                requiredMessage="Job Type is required"
              />
            </Col>
          </Row>

          <Row >
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              {
                params.id ?
                  <>
                    <Typography className={classes.inputlabel}>Photo Id &nbsp;&nbsp;
                      <span onClick={() => navigateToExternalUrl(photoIdShow, true)}>
                        {
                          photoIdShow !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile}>
                              ({photoIdShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                        }
                      </span>
                    </Typography>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="file"
                      name="Photo Id"
                      title="asdasdasdfasf"
                      className="form-textfield-styles errorBottom"
                      onChange={(e) => handleImage(e)}
                    />
                    {
                      photoIdImgError || !isPhotoId || (params.id && photoId?.length > 0) ? <FieldError message={!isPhotoId ? "Photo Id is required" : photoIdImgErrorMsg} /> : null
                    }
                  </>
                  :
                  <>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="file"
                      name="Photo Id"
                      label="Upload Photo Id"
                      className="form-textfield-styles errorBottom"
                      onChange={(e) => handleImage(e)}
                    />
                    {
                      photoIdImgError || !isPhotoId ? <FieldError message={!isPhotoId ? "Photo Id is required" : photoIdImgErrorMsg} /> : null
                    }
                  </>
              }
            </Col>
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              {
                params.id ?
                  <>
                    <Typography variant="body1" className={classes.inputlabel}>Profile Photo &nbsp;&nbsp;
                      <span onClick={() => navigateToExternalUrl(profilePhotoShow, true)}>
                        {
                          profilePhotoShow !== null ?
                            <Typography
                              className={classes.uploadfile}
                              title="Click to check uploaded attachement">
                              ({profilePhotoShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography>
                            : <Typography>(No Attachement Uploaded)</Typography>
                        }
                      </span>
                    </Typography>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="file"
                      hiddenLabel
                      name="profilephoto"
                      className="form-textfield-styles errorBottom"
                      onChange={(e) => handleImage(e)}
                    />
                    {
                      imgError || !isProfilePhoto ? <FieldError message={!isProfilePhoto ? "Profile photo is required" : imgErrorMsg} /> : null
                    }
                  </>
                  :
                  <>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      type="file"
                      name="profilephoto"
                      label="Upload Profile Photo"
                      className="form-textfield-styles errorBottom"
                      onChange={(e) => handleImage(e)}
                    />
                    {
                      imgError || !isProfilePhoto ? <FieldError message={!isProfilePhoto ? "Profile photo is required" : imgErrorMsg} /> : null
                    }
                  </>
              }
            </Col>
          </Row>

          {/* for instructor type  setSelectType */}

          {
            selectType === "instructor" ? (
              <>
                <Row className="mt-2">
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ?
                        <>
                          <Typography variant="body1" className={classes.inputlabel}>Medical Certificate &nbsp;&nbsp;
                            <span onClick={() => navigateToExternalUrl(medicalCertificateShow, true)}>
                              {
                                medicalCertificateShow !== null ?
                                  <Typography
                                    title="Click to check uploaded attachement"
                                    className={classes.uploadfile} >
                                    ({medicalCertificateShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                    <AttachmentIcon />)
                                  </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                              }
                            </span>
                          </Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="medicalCer"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            medicalCertiError || !isMedicalCertificate ? <FieldError message={!isMedicalCertificate ? "Medical certificate is required" : medicalCertiMsg} /> : null
                          }
                        </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="medicalCer"
                            label="Upload Medical Certificate"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            medicalCertiError || !isMedicalCertificate ? <FieldError message={!isMedicalCertificate ? "Medical certificate is required" : medicalCertiMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ?
                        <>
                          <Typography variant="body1" className={classes.inputlabel}>Police Clearance Certificate  &nbsp;&nbsp;
                            <span onClick={() => navigateToExternalUrl(policeCertificateShow, true)}>
                              {
                                policeCertificateShow !== null ?
                                  <Typography
                                    title="Click to check uploaded attachement"
                                    className={classes.uploadfile}>
                                    ({policeCertificateShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                    <AttachmentIcon />)
                                  </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                              }
                            </span>
                          </Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="policeCer"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            policeCertiError ? <FieldError message={policeCertiMsg} /> : null
                          }
                        </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="policeCer"
                            label="Upload Police Clearance Certificate"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            policeCertiError ? <FieldError message={policeCertiMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ?
                        <>
                          <Typography variant="body1" className={classes.inputlabel}>Commercial License &nbsp;&nbsp;
                            <span onClick={() => navigateToExternalUrl(drivingLicenseShow, true)}>
                              {
                                drivingLicenseShow !== null ?
                                  <Typography
                                    title="Click to check uploaded attachement"
                                    className={classes.uploadfile}>
                                    ({drivingLicenseShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                    <AttachmentIcon />)
                                  </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                              }
                            </span>
                          </Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="drivingLicense"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            drivingCertiError || !isDrivingLicense ? <FieldError message={!isDrivingLicense ? "Commercial License is required" : drivingCertiMsg} /> : null
                          }
                        </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="drivingLicense"
                            label="Upload Commercial License"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            drivingCertiError || !isDrivingLicense ? <FieldError message={!isDrivingLicense ? "Commercial license is required" : drivingCertiMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ? <>
                        <Typography variant="body1" className={classes.inputlabel}>Driving Record &nbsp;&nbsp;
                          <span onClick={() => navigateToExternalUrl(drivingRecordShow, true)}>
                            {
                              drivingRecordShow !== null ?
                                <Typography
                                  title="Click to check uploaded attachement"
                                  className={classes.uploadfile} >
                                  ({drivingRecordShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                  <AttachmentIcon />)
                                </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                            }
                          </span>
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="file"
                          name="drivingRecord"
                          className="form-textfield-styles"
                          onChange={(e) => handleImage(e)}
                        />
                        {
                          drivingRecordError ? <FieldError message={drivingRecordErrorMsg} /> : null
                        }
                      </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="drivingRecord"
                            label="Upload Driving Record"
                            className="form-textfield-styles"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            drivingRecordError ? <FieldError message={drivingRecordErrorMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                </Row>

                <Row>
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ?
                        <>
                          <Typography variant="body1" className={classes.inputlabel}>Experience Letter &nbsp;&nbsp;
                            <span onClick={() => navigateToExternalUrl(experienceShow, true)}>
                              {
                                experienceShow !== null ?
                                  <Typography
                                    title="Click to check uploaded attachement"
                                    className={classes.uploadfile}>
                                    ({experienceShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                    <AttachmentIcon />)
                                  </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                              }
                            </span>
                          </Typography>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="experienceLetter"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            experienceError ? <FieldError message={experienceErrorMsg} /> : null
                            // !isDrivingLicense ? "Experience Letter is required" :
                          }
                        </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="experienceLetter"
                            label="Upload Experience Letter"
                            className="form-textfield-styles errorBottom"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            experienceError ? <FieldError message={experienceErrorMsg} /> : null
                            // !isDrivingLicense ? "Experience Letter is required" :
                          }
                        </>
                    }
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ?
                        <>
                          <InputField
                            type="text"
                            lable="No Of Years"
                            name={experience}
                            rows={1}
                            multiline={false}
                            placeHolder="Enter No Of Years"
                            className="form-textfield-styles"
                            value={experience}
                            handleChange={(e) => onChangeExperience(e.target.value, setExperience)}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="No Of Years field is required"
                            isCheckValidNoValue={true}
                          />
                          {
                            yearsError ? <FieldError message={"Minimum 5 Years or Above"} /> : null
                          }
                        </>
                        : <>
                          <InputField
                            type="text"
                            lable="No Of Years"
                            name={experience}
                            rows={1}
                            multiline={false}
                            placeHolder="Enter No Of Years"
                            className="form-textfield-styles"
                            value={experience}
                            handleChange={(e) => onChangeExperience(e.target.value, setExperience)}
                            isValidValue={false}
                            isSubmit={false}
                            requiredMessage="No Of Years field is required"
                            isCheckValidNoValue={true}
                          />
                          {
                            yearsError ? <FieldError message={"Minimum 5 Years or Above"} /> : null
                          }
                        </>
                    }
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ? <>
                        <Typography variant="body1" className={classes.inputlabel}>Reference Letter 1 &nbsp;&nbsp;
                          <span onClick={() => navigateToExternalUrl(refLetter1Show, true)}>
                            {
                              refLetter1Show !== null ?
                                <Typography
                                  title="Click to check uploaded attachement"
                                  className={classes.uploadfile} >
                                  ({refLetter1Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                  <AttachmentIcon />)
                                </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                            }
                          </span>
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="file"
                          name="refLetter1"
                          className="form-textfield-styles"
                          onChange={(e) => handleImage(e)}
                        />
                        {
                          // teachingCertiError ? <FieldError message={teachingCertiMsg} /> : null
                        }
                      </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="refLetter1"
                            label="Upload Reference Letter 1"
                            className="form-textfield-styles"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            // teachingCertiError ? <FieldError message={teachingCertiMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" xs="12">
                    {
                      params.id ? <>
                        <Typography variant="body1" className={classes.inputlabel}>Reference Letter 2 &nbsp;&nbsp;
                          <span onClick={() => navigateToExternalUrl(refLetter2Show, true)}>
                            {
                              refLetter2Show !== null ?
                                <Typography
                                  title="Click to check uploaded attachement"
                                  className={classes.uploadfile} >
                                  ({refLetter2Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                  <AttachmentIcon />)
                                </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                            }
                          </span>
                        </Typography>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="file"
                          name="refLetter2"
                          className="form-textfield-styles"
                          onChange={(e) => handleImage(e)}
                        />
                        {
                          // teachingCertiError ? <FieldError message={teachingCertiMsg} /> : null
                        }
                      </>
                        :
                        <>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            type="file"
                            name="refLetter2"
                            label="Upload Reference Letter 2"
                            className="form-textfield-styles"
                            onChange={(e) => handleImage(e)}
                          />
                          {
                            // teachingCertiError ? <FieldError message={teachingCertiMsg} /> : null
                          }
                        </>
                    }
                  </Col>
                </Row>
              </>) : <></>}

          <Row className="mt-2">
            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <Row>
                <Col lg="6" sm="12">
                  {
                    params.id ? <>
                      <Typography variant="body1" className={classes.inputlabel}>Other Document 1 &nbsp;&nbsp;
                        <span onClick={() => navigateToExternalUrl(doc1Show, true)}>
                          {
                            doc1Show !== null ?
                              <Typography
                                title="Click to check uploaded attachement"
                                className={classes.uploadfile}>
                                ({doc1Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                <AttachmentIcon />)
                              </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                          }
                        </span>
                      </Typography>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="file"
                        name="doc1"
                        className="form-textfield-styles"
                        onChange={(e) => handleImage(e)}
                      />
                      {
                        doc1ImgError ? <FieldError message={doc1ImgErrorMsg} /> : null
                      }
                    </>

                      :
                      <>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="file"
                          name="doc1"
                          label="Upload Other Document 1"
                          className="form-textfield-styles"
                          onChange={(e) => handleImage(e)}
                        />
                        {
                          doc1ImgError ? <FieldError message={doc1ImgErrorMsg} /> : null
                        }
                      </>
                  }
                </Col>
                <Col lg="6" sm="12">
                  <InputField
                    type="text"
                    lable="Document 1 Type"
                    name={doc1_type}
                    rows={1}
                    multiline={false}
                    placeHolder="Enter Document 1 Type"
                    className="form-textfield-styles"
                    value={doc1_type}
                    handleChange={(e) => setDoc1_type(e.target.value)}
                    isValidValue={doc1?.length > 0 ? isValidValues : false}
                    isSubmit={doc1?.length > 0 ? isSubmit : false}
                    requiredMessage="Document 1 Type field is required"
                    isCheckValidRID={true}
                  />
                </Col>
              </Row>
            </Col>

            <Col xl="6" lg="6" md="6" sm="12" xs="12">
              <Row>
                <Col lg="6" sm="12">
                  {
                    params.id ? <>
                      <Typography variant="body1" className={classes.inputlabel}>Other Document 2 &nbsp;&nbsp;
                        <span onClick={() => navigateToExternalUrl(doc2Show, true)}>
                          {
                            doc2Show !== null ?
                              <Typography
                                title="Click to check uploaded attachement"
                                className={classes.uploadfile}>
                                ({doc2Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                <AttachmentIcon />)
                              </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                          }
                        </span>
                      </Typography>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="file"
                        name="doc2"
                        className="form-textfield-styles"
                        onChange={(e) => handleformchange(e, 0)}
                      />
                      {
                        doc2ImgError ? <FieldError message={doc2ImgErrorMsg} /> : null
                      }
                    </>
                      :
                      <>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          type="file"
                          name="doc2"
                          label="Upload Other Document 2"
                          className="form-textfield-styles"
                          onChange={(e) => handleformchange(e, 0)}
                        />
                        {
                          doc2ImgError ? <FieldError message={doc2ImgErrorMsg} /> : null
                        }
                      </>
                  }
                </Col>
                <Col lg="6" sm="12">
                  <InputField
                    type="text"
                    lable="Document 2 Type"
                    name={doc2_type}
                    rows={1}
                    multiline={false}
                    placeHolder="Enter Document 2 Type"
                    className="form-textfield-styles"
                    value={doc2_type}
                    handleChange={(e) => setDoc2_type(e.target.value)}
                    isValidValue={doc2?.length > 0 ? isValidValues : false}
                    isSubmit={doc2?.length > 0 ? isSubmit : false}
                    requiredMessage="Document 2 Type field is required"
                    isCheckValidRID={true}
                  />
                </Col>
              </Row>
            </Col>

            {
              params.id ?
                <>
                  <Row className="mt-2">
                    <Col xl="3" lg="6" md="6" sm="12" xs="12">
                      <Typography variant="body1" className={classes.inputlabel}>Other Document 3 &nbsp;&nbsp;
                        <span onClick={() => navigateToExternalUrl(doc3Show, true)}>
                          {
                            doc3Show !== null ?
                              <Typography
                                title="Click to check uploaded attachement"
                                className={classes.uploadfile}>
                                ({doc3Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                <AttachmentIcon />)
                              </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                          }
                        </span>
                      </Typography>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="file"
                        name="doc3"
                        className="form-textfield-styles"
                        onChange={(e) => handleformchange(e, 1)}
                      />
                    </Col>
                    {
                      doc3ImgError ? <FieldError message={doc3ImgErrorMsg} /> : null
                    }
                    <Col xl="3" lg="6" md="6" sm="12" xs="12">
                      <InputField
                        type="text"
                        lable={`Document Type 3`}
                        name={doc3_type}
                        rows={1}
                        multiline={false}
                        placeHolder={`Enter Document 3 Type`}
                        className="form-textfield-styles"
                        value={doc3_type}
                        handleChange={(e) => handleformchangeType(e.target.value, 1)}
                        isValidValue={doc3?.length > 0 ? isValidValues : false}
                        isSubmit={doc3?.length > 0 ? isSubmit : false}
                        requiredMessage="Document 3 Type field is required"
                        isCheckValidRID={true}
                      />
                    </Col>
                  </Row>
                </> : <></>}

            {
              params.id ?
                <>
                  <Row className="mt-2">
                    <Col xl="3" lg="6" md="6" sm="12" xs="12">
                      <Typography variant="body1" className={classes.inputlabel}>Other Document 4 &nbsp;&nbsp;
                        <span onClick={() => navigateToExternalUrl(doc4Show, true)}>
                          {
                            doc4Show !== null ?
                              <Typography
                                title="Click to check uploaded attachement"
                                className={classes.uploadfile}>
                                ({doc4Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                <AttachmentIcon />)
                              </Typography> : <Typography>(No Attachement Uploaded)</Typography>
                          }
                        </span>
                      </Typography>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="file"
                        name="doc4"
                        className="form-textfield-styles"
                        onChange={(e) => handleformchange(e, 2)}
                      />
                    </Col>
                    {
                      doc4ImgError ? <FieldError message={doc4ImgErrorMsg} /> : null
                    }
                    <Col xl="3" lg="6" md="6" sm="12" xs="12">
                      <InputField
                        type="text"
                        lable={`Document Type 4`}
                        name={doc4_type}
                        rows={1}
                        multiline={false}
                        placeHolder={`Enter Document 4 Type`}
                        className="form-textfield-styles"
                        value={doc4_type}
                        handleChange={(e) => handleformchangeType(e.target.value, 2)}
                        isValidValue={doc4?.length > 0 ? isValidValues : false}
                        isSubmit={doc4?.length > 0 ? isSubmit : false}
                        requiredMessage="Document 4 Type is required"
                        isCheckValidRID={true}
                      />
                    </Col>
                  </Row>
                </>
                : <></>
            }
            {
              params.id ? <> </> :
                <>
                  {
                    newdocument.map((data, index) => {
                      return (<>
                        {
                          index === 0 ? <></> :
                            <>
                              <Row className="mt-2">
                                <Col xl="3" lg="3" md="5" sm="12" xs="12" key={index} >
                                  <TextField
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    type="file"
                                    name="file"
                                    label={`Upload Other Document ${index + 2}`}
                                    className="form-textfield-styles"
                                    onChange={(e) => handleformchange(e, index)}
                                  />
                                  {index === 1 &&
                                    doc3ImgError ? <FieldError message={doc3ImgErrorMsg} /> : null
                                  }
                                  {index === 2 &&
                                    doc4ImgError ? <FieldError message={doc4ImgErrorMsg} /> : null
                                  }
                                </Col>

                                <Col xl="3" lg="3" md="5" sm="12" xs="12" key={index} >
                                  <InputField
                                    type="text"
                                    lable={`Document Type ${index + 2}`}
                                    name={index === 1 ? doc3_type : doc4_type}
                                    rows={1}
                                    multiline={false}
                                    placeHolder={`Enter Document ${index + 2} Type`}
                                    className="form-textfield-styles"
                                    value={index === 1 ? doc3_type : doc4_type}
                                    handleChange={(e) => handleformchangeType(e.target.value, index)}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage={`Document ${index + 2} type field is required`}
                                    isCheckValidRID={true}
                                  />
                                </Col>
                                <Col xl="1" lg="1" md="2" sm="12" xs="12">
                                  {
                                    index > 0 &&
                                    <CloseIcon onClick={() => handleRemoveFields(index)} className={classes.button_delete} />
                                  }
                                </Col>
                              </Row>

                            </>
                        }
                      </>)
                    })
                  }
                </>
            }
          </Row>
          <Row>
            {
              params.id ? <></> :
                <Col>
                  {
                    newdocument.length < 3 ?
                      <Button className={classes.button_add} onClick={handleAddFileds}>
                        Add Other Documents
                      </Button>
                      :
                      <Button className={classes.button_add} onClick={handleAddFileds} disabled>
                        Add Other Documents
                      </Button>
                  }
                </Col>
            }
          </Row>
          <Row className="mt-4">
            <Col lg="12" sm="12">
              <div style={{ textAlign: "end" }}>
                <Link to={ROUTES.staffManagement} className={classes.linkStyle}>
                  <CancelButtonComponent ButtonLabel="Cancel" />
                </Link>{" "}
                <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
              </div>
            </Col>
          </Row>
        </form>
      </CardContent>
    </Card >

    <BootstrapDialog
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="customized-dialog-title"
      open={upgradeDialog}
    >
      <form onSubmit={SubmitHandler}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Upgrade Plan
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent} >
          <IoIosWarning color="#FDB924" size={50} style={{ marginRight: 10 }} />
          {/* <img className={classes.icon} sizes={"10"} src={Icon} /> */}
          <Typography variant="h6" component="div">
            Your plan exceeds the maximum {instructorLimit ? (`instructor limit of ${instructorLimit}`) : `staff limit of ${(staffLimit)}`}.
          </Typography>

        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            variant="contained"
            onClick={handleClose}
            className={classes.noButton}
          >
            Cancel
          </Button>
          {""}
          <Button
            type="button"
            variant="contained"
            // onClick={handleClose}
            className={classes.yesButton}
          >
            Upgrade Now
          </Button>
          {" "}
        </DialogActions>
      </form>
    </BootstrapDialog>
  </>
  );
};
export default NewStaffInstructor;