import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../reducer-store/actions/programsAction";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import TypographyComponent from '../../pages/Programs/Form/TableComponent/TypographyComponent';
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { CircularProgress, Collapse } from '@mui/material';
// import { DataGridPro, GridColDef, GridRowsProp, DataGridProProps, } from '@mui/x-data-grid-pro';
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
// import { Row, Col, Button, Container } from "react-bootstrap";
// import TreeView from '@mui/lab/TreeView';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import TreeItem from '@mui/lab/TreeItem';
// import { PAGE } from '../../reducer-store/constants';

const useStyles = makeStyles((theme) => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
    marginTop: "30px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  },
  emiRow: {
    background: '#E5E5E5',
    fontFamily: 'Arial !important',
  },
  boxBorder1: {
    textAlign: "center",
    margin: "20px 0px",
  },
  Right: {
    textAlign: "end",
    marginTop: 7,
    fontSize: '15px'
  },
  Right1: {
    textAlign: "end",
    fontSize: '15px'
  },
  Left: {
    textAlign: "start",
    marginTop: 7
  },
  completeChip: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "25px !important",
  },
  pendingChip: {
    borderRadius: "40px !important",
    backgroundColor: "#FCB823 !important",
    border: "1px solid #FCB823 !important",
    color: "#ffffff !important",
    padding: "0 43px !important",
    height: "25px !important",
  },
  Center: {
    textAlign: "center",
    marginTop: 7
  },
  switchRight: {
    textAlign: "end",

  },
  titleText: {
    marginLeft: 20,
    fontWeight: 'bolder',
    paddingTop: 10,
    fontSize: '20px !important',
    color: "#343434"
  },
  feesText: {
    marginLeft: 10,
    paddingTop: 10,
  },
  popRow: {
    paddingTop: 10,
  },
  popRow1: {
    paddingTop: 20,
  },
  lockicon: {
    '& svg': {
      fontSize: "18px"
    },
  },
  stline: {
    marginTop: '10px',
    border: '1px solid #797979'
  },
  stlineExpand: {
    marginTop: '10px',
    border: '1.2px solid #FFFFFF'
  },
  emiSlots: {
    background: '#EBEBEB',
    fontFamily: 'Arial',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },

}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          color: '#FFFFFF',
          "& .css-1ygcj2i-MuiTableCell-root": {
            color: "#FFFFFF",
            fontweight: "bold",
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#404040",
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "white",
          },
          "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
            color: "white"
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .css-pfof31-MuiTableCell-root ": {
            color: "#FFFFFF"
          },
          "& .tss-10syd3x-MUIDataTableHeadCell-root": {
            fontSize: '15px',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
        },
        head: {
          backgroundColor: "#404040",
          color: '#FFFFFF'
        },
        footer: {
          backgroundColor: "#FFFFFF"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .css-1ex1afd-MuiTableCell-root": {
            width: "18%",
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          "& .css-140puxv-MuiTypography-root-MuiDialogTitle-root": {
            backgroundColor: '#646464'
          }

        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: '#FFFFFF'
        }
      }
    }
  }
});

const ModuleView: React.FC<any> = (props) => {
  const classes = useStyles();
  const params: any = useParams();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const [moduleInfo, setModuleInfo] = useState<any>([]);
  const dispatch = useDispatch();
  const { ModuaViewlList } = bindActionCreators(Actions, dispatch);
  // const { getProgramDetails } = Actions;
  const [data, setData] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [open, setOpen] = useState(false);
  // const [openCourse, setOpenCourse] = useState(false);
  const [filterVal, setFilterVal] = useState('');
  const [searchData, setSearchData] = useState(false);
  // const [viewProgramInfos, setViewProgramInfos] = useState<any>([]);
  const modualList = useSelector((state: any) => state.modual);
  const { moduals } = modualList;

  const handleFilter = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter((items: any) =>
        items.module_name.toLowerCase().includes(e.target.value.toLowerCase())
        || items.inclass.toString().includes(e.target.value.toString())
        || items.inyard.toString().includes(e.target.value.toString())
        || items.hours.toString().includes(e.target.value.toString())
        || items.incab.toString().includes(e.target.value.toString())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const editModuleData: any = await ModuaViewlList(params.id, token);
        const allModuleList = editModuleData.data.data;
        const filteredModule = allModuleList.filter((Module: any, index: number) => {
          if (Module.program_id === parseInt(params.id)) {
            return Module;
          }
        });
        setModuleInfo(filteredModule);
        renderDataTable(filteredModule);
      }
    })();
  }, [params]);

  // useEffect(() => {
  //   (async () => {
  //     if (params && params.id) {
  //       const programData: any = await getProgramDetails(params.id, token);
  //       if (programData?.data?.ProgramModules?.length > 0) {
  //         setViewProgramInfos(programData?.data?.ProgramModules);
  //       }
  //     }
  //   })();
  // }, [params]);

  const renderDataTable = (newModuleList: any) => {
    const rowsDataTable = newModuleList && newModuleList.map((row: any) => {
      return {
        module_name: row.name,
        hours: row.total_duration_minutes !== null ? row.total_duration_minutes / 60 : "---",
        inclass: row.total_inclass_training_minutes !== null ? row.total_inclass_training_minutes / 60 : "---",
        inyard: row.total_inyard_training_minutes !== null ? row.total_inyard_training_minutes / 60 : "---",
        incab: row.total_incab_training_minutes !== null ? row.total_incab_training_minutes / 60 : "---",
        practical: row ? row?.practical_assessment_max_marks : "---",
        min_theory_percent: row?.minimum_pass_percent_theory,
        min_practical_percent: row?.minimum_pass_percent_practical,
        theory: row?.theory_test_max_marks !== null ? row?.theory_test_max_marks : "---",
        dependency: row.dependency !== null ? row.dependency : '--',
        objective: row.title_objective !== null ? row.title_objective : "--",
        description: row.description !== null ? row.description : "--",
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  if (moduleInfo === "") {
    return (
      <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    );
  }

  const columns = [
    {
      label: 'Modules name',
      name: "module_name",
    },
    {
      label: 'Total hours',
      name: "hours",
    },
    {
      label: 'Total inclass training hours',
      name: "inclass",
    },
    {
      label: 'Total inyard training hours',
      name: "inyard",
    },
    {
      label: 'Total incab training hours',
      name: "incab",
    },
    {
      label: "Max Practical Assessment Marks",
      name: "practical",
      options: {
        display: false,
      }
    },
    {
      label: "min_theory_percent",
      name: "min_theory_percent",
      options: {
        display: false,
      }
    },
    {
      label: "min_practical_percent",
      name: "min_practical_percent",
      options: {
        display: false,
      }
    },
    {
      label: "theory",
      name: "theory",
      options: {
        display: false,
      }
    },
    {
      label: "dependency",
      name: "dependency",
      options: {
        display: false,
      }
    },
    {
      label: 'Title of objective',
      name: 'objective',
      options: {
        display: false,
      }
    },
    {
      label: 'Description',
      name: 'description',
      options: {
        display: false,
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: searchData || data?.length == 0 && moduals?.data?.length == 0 ?
          'Sorry, there is no matching data to display' :
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box >
        ,
      },
    },
    customToolbar: () => {
      return (<>
        <TextField
          variant="standard"
          placeholder="Search"
          onInput={(e: any) => handleFilter(e)}
          value={filterVal}
          className={classes.searchBar}
          InputProps={{
            style: { color: "black" },
            endAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </>)
    },
    renderExpandableRow: (rowData: any) => {
      return (
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#F0F0F0' }}
            colSpan={7}
          >
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">
                      Max Practical Assessment Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Practical Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Max Theory Test Marks
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Minimum Theory Pass Percentage
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Dependency On Another Module
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" align="center">
                      {rowData[5]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[7]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[8]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[6]}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {rowData[9] !== "" ? rowData[9] : "--"}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

              <hr style={{ marginTop: 5, color: "#000000", opacity: 1 }} />

              <div style={{ flexDirection: "row", display: 'flex' }}>
                <Typography fontWeight={700} color="#404040">
                  Title Of Objective:
                </Typography>

                <Typography sx={{ marginLeft: 1 }} color="#606060">
                  {rowData[10] !== null ? rowData[10] : "---"}
                </Typography>
              </div>

              <Typography fontWeight={700} color="#404040">
                Description:
              </Typography>
              <Typography color="#606060">
                {rowData[11] !== null ? rowData[11] : "---"}
              </Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow >
      );
    },
  };

  // function RowData(props: any) {
  //   // const [openPayment, setOpenPayment] = useState(false);
  //   const { row }: any = props;
  //   return (

  //     <Table aria-label="simple table">
  //       <TableHead>
  //         <TableRow>
  //           <StyledTableCell align="center" component="th">
  //             <IconButton
  //               aria-label="expand row"
  //               size="small"
  //               onClick={() => { setOpen(!open) }}
  //               color='success'
  //             >
  //               {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
  //             </IconButton>
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {"Module name"}
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {"Total hours"}
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {" "}
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {"Total inclass training hours"}
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {"Total inyard training hours"}
  //           </StyledTableCell>
  //           <StyledTableCell align="center" component="th" sx={{ fontWeight: 900 }}>
  //             {"Total incab training hours"}
  //           </StyledTableCell>
  //         </TableRow>
  //       </TableHead>

  //       <TableBody>
  //         {
  //           row?.length > 0 ? (
  //             row?.map((data: any, index: number) => (
  //               <>
  //                 <StyledTableRow key={data.id}>
  //                   <StyledTableCell align="center">
  //                     {" "}
  //                   </StyledTableCell>
  //                   <StyledTableCell align="center">
  //                     {data.name || "--"}
  //                   </StyledTableCell>
  //                   <StyledTableCell align="center">
  //                     {(data.total_duration_minutes / 60) || "--"}
  //                   </StyledTableCell>
  //                   <StyledTableCell align="center">
  //                     {" "}
  //                   </StyledTableCell>
  //                   <StyledTableCell scope="row" align="center">
  //                     {(data.total_inclass_training_minutes / 60) || "--"}
  //                   </StyledTableCell>
  //                   <StyledTableCell align="center">
  //                     {(data.total_inyard_training_minutes / 60) || "--"}
  //                   </StyledTableCell>
  //                   <StyledTableCell align="center">
  //                     {(data.total_incab_training_minutes / 60) || "--"}
  //                   </StyledTableCell>
  //                 </StyledTableRow>

  //                 {data?.Courses?.length > 0 ? (
  //                   data?.Courses?.map((dataCourse: any, index: number) => (
  //                     <>
  //                       <StyledTableRow key={data.id}>
  //                         <StyledTableCell align="center">
  //                           {" "}
  //                         </StyledTableCell>
  //                         <StyledTableCell align="center">
  //                           {dataCourse.name || "--"}
  //                         </StyledTableCell>
  //                         <StyledTableCell align="center">
  //                           {(dataCourse.total_duration_minutes / 60) || "--"}
  //                         </StyledTableCell>
  //                         <StyledTableCell align="center">
  //                           {" "}
  //                         </StyledTableCell>
  //                         <StyledTableCell scope="row" align="center">
  //                           {(dataCourse.total_inclass_training_minutes / 60) || "--"}
  //                         </StyledTableCell>
  //                         <StyledTableCell align="center">
  //                           {(dataCourse.total_inyard_training_minutes / 60) || "--"}
  //                         </StyledTableCell>
  //                         <StyledTableCell align="center">
  //                           {(dataCourse.total_incab_training_minutes / 60) || "--"}
  //                         </StyledTableCell>
  //                       </StyledTableRow>

  //                       {dataCourse?.ModuleCourseTopics?.length > 0 ? (
  //                         dataCourse?.ModuleCourseTopics?.map((dataTopic: any, index: number) => (
  //                           <StyledTableRow key={data.id}>
  //                             <StyledTableCell align="center">
  //                               {" "}
  //                             </StyledTableCell>
  //                             <StyledTableCell align="center">
  //                               {dataTopic.name || "--"}
  //                             </StyledTableCell>
  //                             <StyledTableCell align="center">
  //                               {(dataTopic.total_duration_minutes / 60) || "--"}
  //                             </StyledTableCell>
  //                             <StyledTableCell align="center">
  //                               {" "}
  //                             </StyledTableCell>
  //                             <StyledTableCell scope="row" align="center">
  //                               {(dataTopic.total_inclass_training_minutes / 60) || "--"}
  //                             </StyledTableCell>
  //                             <StyledTableCell align="center">
  //                               {(dataTopic.total_inyard_training_minutes / 60) || "--"}
  //                             </StyledTableCell>
  //                             <StyledTableCell align="center">
  //                               {(dataTopic.total_incab_training_minutes / 60) || "--"}
  //                             </StyledTableCell>
  //                           </StyledTableRow>
  //                         ))
  //                       ) : (
  //                         <StyledTableRow>
  //                           <StyledTableCell align="center" colSpan={8}>
  //                             No refunds found
  //                           </StyledTableCell>
  //                         </StyledTableRow>
  //                       )}
  //                     </>
  //                   ))
  //                 ) : (
  //                   <StyledTableRow>
  //                     <StyledTableCell align="center" colSpan={8}>
  //                       No refunds found
  //                     </StyledTableCell>
  //                   </StyledTableRow>
  //                 )}
  //               </>
  //             ))
  //           ) : (
  //             <StyledTableRow>
  //               <StyledTableCell align="center" colSpan={8}>
  //                 No refunds found
  //               </StyledTableCell>
  //             </StyledTableRow>
  //           )}
  //       </TableBody>
  //     </Table>

  //   );
  // }

  return (
    <>
      {/* <RowData key={""} row={viewProgramInfos} /> */}

      <ThemeProvider theme={theme} >
        <MUIDataTable
          title={<TypographyComponent label={props.labelProps} />}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider >
      {props.children}
    </>
  );
};

export default ModuleView;

