import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../reducer-store/actions/topicAction";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TypographyComponent from "../../pages/Programs/Form/TableComponent/TypographyComponent";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
    searchBar: {
        borderColor: "black",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    color: '#FFFFFF',
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        color: "#FFFFFF",
                        fontweight: "bold",
                    },
                    "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
                        backgroundColor: "#404040",
                    },
                    "& .tss-1akey0g-MUIDataTableHeadCell-data": {
                        color: "white",
                    },
                    "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
                        color: "white"
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
                        backgroundColor: "#646464",
                    },
                    "& .css-pfof31-MuiTableCell-root ": {
                        color: "#FFFFFF"
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#404040",
                    color: '#FFFFFF'
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#DFDFDF"
                    },
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "18%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    "& .css-140puxv-MuiTypography-root-MuiDialogTitle-root": {
                        backgroundColor: '#646464'
                    }

                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    fill: '#FFFFFF'
                }
            }
        }
    }
});


const TopicView: React.FC = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const { TopicViewList } = bindActionCreators(Actions, dispatch);
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const topicList = useSelector((state: any) => state.topic);
    const { topics } = topicList;
    const [topicInfo, setTopicInfo] = useState<any>([]);
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);
    const [filterVal, setFilterVal] = useState('');
    const [searchData, setSearchData] = useState(false);

    const handleFilter = (e: any) => {
        if (e.target.value == "") {
            setData(searchApiData);
        } else {
            const filterData = searchApiData.filter((items: any) =>
                items.topic_name.toLowerCase().includes(e.target.value.toLowerCase())
                || items.inclass.toString().includes(e.target.value.toString())
                || items.inyard.toString().includes(e.target.value.toString())
                || items.hours.toString().includes(e.target.value.toString())
                || items.incab.toString().includes(e.target.value.toString())
                || items.course_name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            if (filterData.length > 0) {
                setData(filterData);
                setSearchData(false);
            } else {
                setData([]);
                setSearchData(true);
            }
        }
        setFilterVal(e.target.value);
    };

    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        (async () => {
            if (params && params.id) {
                const editTopicData: any = await TopicViewList(params.id, token);
                const allTopicList = editTopicData.data.data;
                const filterTopicInfo = allTopicList.filter((Topic: any) => {
                    if (Topic.program_id === parseInt(params.id)) {
                        return Topic;
                    }
                })
                setTopicInfo(filterTopicInfo);
                renderDataTable(filterTopicInfo);
            }
        })();
    }, [params]);

    const renderDataTable = (newtopicList: any) => {
        const rowsDataTable = newtopicList && newtopicList.map((row: any) => {
            return {
                topic_name: row?.name,
                course_name: row.Course?.name || "---",
                module_name: row?.ProgramModule?.name,
                hours: row.duration_minutes !== null ? row?.duration_minutes / 60 : "---",
                inclass: row.inclass_training_minutes !== null ? row?.inclass_training_minutes / 60 : "---",
                inyard: row.inyard_training_minutes !== null ? row?.inyard_training_minutes / 60 : "---",
                incab: row.incab_training_minutes !== null ? row?.incab_training_minutes / 60 : "---",
                description: row.description
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const columns = [
        {
            label: 'Topic name',
            name: "topic_name",
        },
        {
            label: 'Course name',
            name: "course_name",
        },
        {
            label: 'Module name',
            name: "module_name",
        },
        {
            label: 'Total hours',
            name: "hours",
        },
        {
            label: 'Total inclass training hours',
            name: "inclass",
        },
        {
            label: 'Total inyard training hours',
            name: "inyard",
        },
        {
            label: 'Total incab training hours',
            name: "incab",
        },
        {
            label: "Des",
            name: "description",
            options: {
                display: false,
            }
        }
    ];

    const options = {
        responsive: "vertical",
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        selectableRows: "none",
        rowsPerPageOptions: [],
        fixedHeader: false,
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && topics?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        customToolbar: () => {
            return (<>
                <TextField
                    variant="standard"
                    placeholder="Search"
                    onInput={(e: any) => handleFilter(e)}
                    value={filterVal}
                    className={classes.searchBar}
                    InputProps={{
                        style: { color: "black" },
                        endAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                />
            </>)
        },
        renderExpandableRow: (rowData: any) => {
            return (
                <StyledTableRow >
                    <StyledTableCell
                        style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#F0F0F0' }}
                        colSpan={9}
                    >
                        <Box sx={{ margin: 1 }}>
                            <Typography fontWeight={700} color="#404040">
                                Description :
                            </Typography>
                            <Typography color="#606060">
                                {rowData[7]}
                            </Typography>
                        </Box>
                    </StyledTableCell>
                </StyledTableRow>
            );
        },
    };

    if (!topics) {
        return (
            <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                <CircularProgress sx={{ color: '#707070' }} />
            </Box>
        );
    }

    return (<>
        <ThemeProvider theme={theme} >
            <MUIDataTable
                title={<TypographyComponent label="Topics" />}
                data={data}
                columns={columns}
                options={options}
            />
        </ThemeProvider >
    </>);

};

export default TopicView;