/*
  Login messages
*/
export const LOGIN_SUCCESSFULLY = "Logged In Successfully";
export const OTP_SENT_SUCCESSFULLY = "OTP Sent Successfully";
export const ACCOUNT_VERIFIED_SUCCESSFULLY = "Account Verified Successfully";
export const RESEND_OTP_SUCCESSFULLY = "OTP Resend Successfully";
export const LOGOUT_SUCCESSFULLY = "Logged Out Successfully";
export const PASSWORD_UPDATED_SUCCESSFULLY = "Password Updated Successfully";


/*
  Error message
*/
export const ALL_ERROR_MESSAGE = "Getting error while processing, Try again!";
