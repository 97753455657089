import {
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
// import { PAYMENT_INIT, PAYMENT_INIT_FIRST } from "../../../reducer-store/constants";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import { useState } from "react";
import { upgradePlanPayment, upgradePlan, renewPlan, renewPlanPayment } from "../../reducer-store/actions/planAction";
import { InnerPageRoutes } from "../../constants/routes";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    saveButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        float: "right",
        marginTop: '10px'
    },
    saveButtonDisabled: {
        marginTop: '10px',
        float: "right",
        borderRadius: "40px !important",
        backgroundColor: "#E5E5E5 !important",
        border: "1px solid #8E8E8E !important",
        color: "#959595 !important",
        padding: "0 35px !important",
        boxShadow: 'none',
        height: "40px !important",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
        cursor: 'default !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:focus': {
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none'
        }
    },
}));

const PaymentForm = ({ renew }: any) => {
    // const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [firstPaymentStatus, setFirstPaymentStatus] = useState(false);

    const handleUpgradePlan = async (stripe: any, elements: any) => {
        setFirstPaymentStatus(true);
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });
        if (error) {
            toast.error(error.message);
            return;
        }
        toast.loading("Please wait while your payment is being processed...")

        let obj = {
            "plan_id": 2,
            "gateway_name": "stripe",
            "currency": "cad"
        }

        let objRenew = {
            "gateway_name": "stripe",
            "currency": "cad"
        }

        const res: any = renew ? await renewPlanPayment(objRenew, token) : await upgradePlanPayment(obj, token);

        const clientSecretID = res?.data?.data?.clientSecret;
        const get_payment_intent: any = await stripe.confirmCardPayment(
            clientSecretID,
            {
                payment_method: {
                    card: cardElement,
                },
            }
        );

        if (get_payment_intent.error) {
            toast.dismiss();
            toast.error(get_payment_intent?.error?.message);
            return;
        } else {
            let body = {
                "plan_id": 2,
                "payment_id": res.data.data.payment?.payment_id,
            }

            let bodyRenew = {
                "payment_id": res.data.data.payment?.payment_id,
            }
            const resData: any = renew ? await renewPlan(bodyRenew, token) : await upgradePlan(body, token);
            if (!resData.error) {
                toast.dismiss();
                toast.success(renew ? "Plan renewed successfully" : "Plan upgraded successfully");
                setTimeout(() => {
                    localStorage.clear();
                    history.push({ pathname: InnerPageRoutes.thanksUpgrade, state: renew });
                }, 2500)
            }
        }
    }

    return (
        <>
            <CardElement options={{ hidePostalCode: true }} />
            <button className={firstPaymentStatus ? classes.saveButtonDisabled : classes.saveButton} disabled={firstPaymentStatus ? true : false} onClick={() => handleUpgradePlan(stripe, elements)}>Pay</button>
        </>
    );
}

export default PaymentForm;