import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Forgot_Pass from "../../svg_icons/forgot_pass.svg";
import { ROUTES } from "../../constants/routes";
import MainComponent from "./MainComponent";
import { isRequiredField, isValidEmail } from "../../common-components/FormComponent/FormValidator";
import { InputField } from "../../common-components/FormComponent/FormFieldsComponent";
import * as authAction from "../../reducer-store/actions/authActions";
import { toast } from "react-toastify";
import ToastMessage from "../../common-components/ToastMessage";
import { OTP_SENT_SUCCESSFULLY } from "../../reducer-store/messages/actionMessages";

import Logo from "../../common-components/Logo/Logo";
import { styled } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
    '@media (max-width: 1200px)': {
      fontSize: "32px",
    },
    '@media (max-width: 991px)': {
      fontSize: "30px",
    },
    '@media (max-width: 575px)': {
      fontSize: "26px",
    }
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "20px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  cardMain: {
    '@media (max-width: 991px)': {
      marginTop: "30px",
    },
  },
  imgCenter: {

    '@media (max-width: 1200px)': {
      display: "flex",
      justifyContent: "center",
    },
    '@media (max-width: 991px)': {
      display: "flex",
      justifyContent: "center",
    },
    '@media (max-width: 575px)': {
      display: "flex",
      justifyContent: "center",
    },
  },
  imgClass: {
    '@media (max-width: 1200px)': {
      width: "70%",
      height: "100%",
    },
    '@media (max-width: 991px)': {
      width: "60%",
      height: "100%",
    },
    '@media (max-width: 575px)': {
      width: "100%",
      height: "100%",
    },
    width: "70%",
    height: "100%",
  },
  logoP: {
    position: "fixed",
    '@media (max-width: 768px),(min-width: 480px)': {
      padding: "15px 30px",
    },
    '@media (max-width: 480px)': {
      padding: "15px 10px",
    }
  },
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: ' #000000',
    width: '100%',
    minHeight: 'auto',
    '@media (max-width: 768px)': {
      position: 'absolute',
      minHeight: 'auto',
      bottom: 0,
    },
  },
  FooterText: {
    textAlign: 'center',
    color: '#ffffff',
    margin: '15px 0px',
    '@media (max-width: 768px)': {
      margin: '15px 0px',
    },
  },
}));

const Forgotpass = (props: any) => {
  const mdUp = useResponsive('up', 'md', '');
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loaded, setLoader] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const history = useHistory();
  const { sentForgotPasswordOTPCode } = authAction;

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(email) &&
      isValidEmail(email)) {
      setLoader(true);
      setIsSubmit(false);
      let resData: any = await sentForgotPasswordOTPCode(email);
      if (!resData.error) {
        toast.success(OTP_SENT_SUCCESSFULLY);
        localStorage.setItem("forgot-email", email);
        setTimeout(() => {
          setLoader(false);
          history.push(ROUTES.v2AddOTP);
        }, 2000);
      }
      return;
    }
    setIsValidValues(false);
  };

  return (
    <MainComponent>
      <StyledRoot>
        <ToastMessage />
        <Box className={classes.logoP}>
          <Link to={ROUTES.login}><Logo size={30} /></Link>
        </Box>
        <section className={classes.sectionTop}>
          <Container className="mt-4">
            <Row className="stap-form">
              <Typography
                className={classes.loginTitle}
                variant="h5"
                component="div"
              >
                Forgot Password?
              </Typography>
            </Row>
            <Row className="stap-form">
              {/* <Col lg="6" sm="12" className={classes.imgCenter}>
                <img src={Forgot_Pass} className={classes.imgClass} />
              </Col> */}
              {mdUp && (
                <Col lg="6" sm="12" className={classes.imgCenter}>
                  <img src={Forgot_Pass} className={classes.imgClass} />
                </Col>
              )}
              <Col lg="6" sm="12" className="d-flex flex-column justify-content-center">
                <Box>
                  <Card className="Main_Card" variant="outlined">
                    {loaded && <LinearProgress />}
                    <Form onSubmit={SubmitHandler}>
                      <CardContent className={classes.cardMain}>
                        <InputField
                          type="text"
                          lable="Email"
                          name={email}
                          rows={10}
                          multiline={false}
                          placeHolder="Enter email"
                          className="form-textfield-styles"
                          value={email}
                          handleChange={(e) => setEmail(e.target.value)}
                          isValidValue={isValidValues}
                          isSubmit={isSubmit}
                          requiredMessage="Email field is required"
                          isCheckValidEmailValue={true}
                        />
                      </CardContent>
                      <CardContent>
                        <div className="text-center">

                          <div className="logbutton">
                            <Link
                              to={ROUTES.login}
                              className="btn btn-style-white"
                            >
                              Back
                            </Link>{" "}
                            <button type="submit" className="btn btn-style">
                              Get OTP
                            </button>
                          </div>
                        </div>
                      </CardContent>
                    </Form>
                  </Card>
                </Box>
              </Col>
            </Row>
          </Container>
          <footer className={classes.footer}>
            <Container>
              <Row>
                <Col lg="12" sm="12">
                  <p className={classes.FooterText}>© 2023 Powered by Driving School Cloud.  All rights reserved.</p>
                </Col>
              </Row>
            </Container>
          </footer>
        </section>
      </StyledRoot>
    </MainComponent>
  );
};
export default Forgotpass;
