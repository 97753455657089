import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ROUTES } from "../../constants/routes";
import LinearProgress from "@material-ui/core/LinearProgress";
import Login_header from "./Login_header";
import Login_footer from "./Login_footer";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { isRequiredField } from "../../common-components/FormComponent/FormValidator";
import { sentVerificationOTPCode } from "../../reducer-store/actions/authActions";
import { toast } from "react-toastify";
import { OTP_SENT_SUCCESSFULLY } from "../../reducer-store/messages/actionMessages";
import * as authAction from "../../reducer-store/actions/authActions";
import ToastMessage from "../../common-components/ToastMessage";
import MainComponent from "./MainComponent";

import Logo from "../../common-components/Logo/Logo";
import { styled } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "44px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
    '@media (max-width: 1200px)': {
      fontSize: "32px",
    },
    '@media (max-width: 991px)': {
      fontSize: "30px",
    },
    '@media (max-width: 575px)': {
      fontSize: "26px",
    }
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "40px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  createacc: {
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: 600,
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  logoP: {
    position: "fixed",
    '@media (max-width: 768px),(min-width: 480px)': {
      padding: "15px 30px",
    },
    '@media (max-width: 480px)': {
      padding: "15px 10px",
    }
  },
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: ' #000000',
    width: '100%',
    minHeight: 'auto',
    '@media (max-width: 768px)': {
      position: 'absolute',
      minHeight: 'auto',
      bottom: 0,
    },
  },
  FooterText: {
    textAlign: 'center',
    color: '#ffffff',
    margin: '15px 0px',
    '@media (max-width: 768px)': {
      margin: '15px 0px',
    },
  },
}));
type StateProps = {
  auth: AuthProps;
};

type AuthProps = {
  user: any;
  isAuthenticated: string;
  isVerified: string;
  selectedValue: string;
};



const OnetimePass: React.FC = (props: any) => {
  const classes = useStyles();
  const auth: AuthProps = useSelector((state: StateProps) => state.auth);
  let { user, isAuthenticated, isVerified, selectedValue } = auth;
  const [type, setType] = useState(selectedValue || "email");
  const [loaded, setLoader] = useState(false);
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const dispatch = useDispatch();
  const { sentVerificationOTPCode, updateOtpPlatFormValue } = bindActionCreators(authAction, dispatch);
  let isAuthUser = JSON.parse(isAuthenticated);
  let isVerifiedUser = JSON.parse(isVerified);
  let userObj;

  const updateTheOtpPlatform = (value: string) => {
    setType(value);
    updateOtpPlatFormValue(value);
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(type)) {
      setIsSubmit(false);
      setLoader(true);
      if (type === "email") {
        userObj = {
          email: props.history.location.state.email,
        };
      } else {
        userObj = {
          email: props.history.location.state.email,
        };
      }
      let resData: any = await sentVerificationOTPCode(userObj, type);
      if (!resData) {
        setLoader(false);
        return;
      }
      setType("email");
      toast.success(OTP_SENT_SUCCESSFULLY);
      setTimeout(() => {
        setLoader(false);
        history.push({ pathname: ROUTES.addOTP, state: props.history.location.state });
      }, 2000);
    }
    setIsValidValues(false);
  };

  return (
    <>
      {/* <Login_header /> */}
      <MainComponent>
        <StyledRoot>
          <ToastMessage />
          <Box className={classes.logoP}>
            <Link className="Logo" to={ROUTES.login}><Logo size={30} /></Link>
          </Box>
          <section className={classes.sectionTop}>
            <Container className="mt-4">
              <Row className="stap-form">
                <Typography
                  className={classes.loginTitle}
                  variant="h5"
                  component="div"
                >
                  Get Your One Time Password
                </Typography>
              </Row>
              <Row className="stap-form">
                <Col lg="6" md="8" sm="12" className="offset-lg-3 offset-md-2">
                  <Box>
                    <Card className="Main_Card" variant="outlined">
                      {loaded && <LinearProgress />}
                      <Form onSubmit={SubmitHandler}>
                        <CardContent>
                          <RadioGroup
                            aria-label="type"
                            defaultValue={type}
                            name="radio-buttons-group"
                            className="Radio"
                          >
                            <FormControlLabel
                              name="email"
                              value="email"
                              control={<Radio />}
                              label="Email Id"
                              onChange={(e: any) => updateTheOtpPlatform(e.target.value)}
                            />
                            <FormControlLabel
                              name="phone"
                              value="phone"
                              control={<Radio />}
                              label="Phone Number"
                              onChange={(e: any) => updateTheOtpPlatform(e.target.value)}
                            />
                          </RadioGroup>
                        </CardContent>
                        <CardContent>
                          <div className="logbutton">
                            <div className="text-center">
                              <Link
                                to={ROUTES.login}
                                className="btn btn-style-white"
                              >
                                Back
                              </Link>{" "}
                              <button type="submit" className="btn btn-style">
                                Send
                              </button>
                            </div>
                          </div>
                        </CardContent>
                      </Form>
                    </Card>
                  </Box>
                </Col>
              </Row>
            </Container>
          </section>
          <footer className={classes.footer}>
            <Container>
              <Row>
                <Col lg="12" sm="12">
                  <p className={classes.FooterText}>© 2023 Powered by Driving School Cloud.  All rights reserved.</p>
                </Col>
              </Row>
            </Container>
          </footer>
        </StyledRoot>
        {/* <Login_footer /> */}
      </MainComponent>
    </>
  );
};
export default OnetimePass;
