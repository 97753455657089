import axios from "axios";
import { toast } from "react-toastify";
import {
    LIST_OF_PLAN_ERROR,
    LIST_OF_PLAN_HISTORY,
    LIST_OF_PLAN_HISTORY_ERROR,
    LIST_OF_SINGLE_PLAN_HISTORY,
    LIST_OF_SINGLE_PLAN_HISTORY_ERROR
} from "../ActionTypes";
import {
    GET_PLAN_HISTORY, PLAN_RENEW, PLAN_RENEW_PAYMENT, PLAN_UPGRADE, PLAN_UPGRADE_PAYMENT, SINGLE_PLAN_API
} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
        "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
    },
};

export const planHistoryList = (
    // status = "0",
    // page = PAGE,
    // limit = LIMIT,
    token: string,
    // order: string,
    // search: string
) => {
    header.headers.Authorization = `Bearer ${token}`;
    return async (dispatch: any) => {
        return await axios
            .get(`${GET_PLAN_HISTORY}`, header)
            .then((res) => {
                dispatch({
                    type: LIST_OF_PLAN_HISTORY,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: LIST_OF_PLAN_HISTORY_ERROR,
                });
                toast.error(error.response.data.errorMsg);
            });
    };
};

export const upgradePlanPayment = async (userObj: any, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .post(PLAN_UPGRADE_PAYMENT, body, header)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const renewPlan = async (userObj: any, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .post(PLAN_RENEW, body, header)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const renewPlanPayment = async (userObj: any, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .post(PLAN_RENEW_PAYMENT, body, header)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};

export const upgradePlan = async (userObj: any, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    const body = JSON.stringify(userObj);
    return await axios
        .post(PLAN_UPGRADE, body, header)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            toast.error(error.response.data.errorMsg);
        });
};


export const SinglePlanData = (id: string, token: string) => {
    header.headers.Authorization = `Bearer ${token}`;
    return async (dispatch: any) => {
        return await axios
            .get(`${SINGLE_PLAN_API}/${id}`, header)
            .then((res) => {
                dispatch({
                    type: LIST_OF_SINGLE_PLAN_HISTORY,
                    payload: res.data,
                });
                return res;
            })
            .catch((error) => {
                dispatch({
                    type: LIST_OF_SINGLE_PLAN_HISTORY_ERROR,
                });
                toast.error(error.response.data.errorMsg);
            });
    };
};