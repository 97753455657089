import React, { useState, useEffect } from "react";
import "../../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";
import TableCell from "@mui/material/TableCell";
import { PAGE } from "../../../reducer-store/constants";
import Box from "@material-ui/core/Box";
import Typography from "@mui/material/Typography";
import { Col } from "react-bootstrap";
import { DownloadForOffline } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import * as Actions from '../../../reducer-store/actions/paymentsAction'
import { useDispatch, useSelector } from "react-redux";
import Master from '../../../svg_icons/mastro.png';
import Visa from '../../../svg_icons/visa.png';
import moment from "moment";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";
import { LIST_OF_PAYMENTS_ERROR } from "../../../reducer-store/ActionTypes";
import ToastMessage from "../../../common-components/ToastMessage";

const useStyles = makeStyles(() => ({
    boxBorder1: {
        textAlign: "center",
        margin: "20px 0px",
    },
    TransferInitiated: {
        color: "#00a651",
    },
    TransferFaild: {
        color: "red",
    },
    line: {
        backgroundColor: "#00a651",
        width: "100%",
        height: "5px",
        marginTop: "2.5%",
    },
    Right: {
        textAlign: "end",
    },
    Left: {
        textAlign: "start",
    },
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    '.tss-1akey0g-MUIDataTableHeadCell-data': {
                        fontSize: '15px'
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: '15px',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#F4F4F4"
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "13%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

const Transaction: React.FC = () => {
    const classes = useStyles();
    const [page, setPage] = useState(PAGE);
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const dispatch = useDispatch();
    const { studentPaymentsList, generatePaymentUrl } = Actions;
    const listOfPayment = useSelector((state: any) => state.payment);
    const { payments } = listOfPayment;
    const status = 1
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState(false);

    // const handleSearch = (value: string) => {
    //     setSearch(value);
    // };

    useEffect(() => {
        (async () => {
            dispatch({ type: LIST_OF_PAYMENTS_ERROR })
            const newPaymentList = await studentPaymentsList(status, token);
            renderDataTable(newPaymentList);
        })();
    }, [page]);

    const paymentStatus = (value: number) => {
        switch (value) {
            case 1:
                return "Received";
            case 2:
                return "Failed";
            case 3:
                return "Cancelled";
            case 4:
                return "Refund Requested";
            case 5:
                return "Refunded";
        }
    };

    const paymentUrl = async (paymentId: any) => {
        let obj = {
            "payment_id": paymentId,
        }
        const resData = await generatePaymentUrl(obj, token);

        if (!resData?.error) {
            toast.success("Invoice generated successfully");
            setTimeout(() => {
                navigateToExternalUrl(resData?.invoice_url, true);
            }, 2000)
        }
    }

    const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
        shouldOpenNewTab ? window.open(urlName, "_blank") : window.location.href = urlName;
        return;
    }

    const renderDataTable = (paymentsList: any) => {
        const rowsDataTable = paymentsList && paymentsList.data.map((row: any, index: number) => {
            return {
                date: row?.paid_on ? moment(row?.paid_on).format("YYYY-MM-DD") : "--",
                amount: <>{row.symbol ? row?.symbol : "--"} {" "} {Number(row?.total_amount).toFixed(2)}</>,
                status: paymentStatus(row?.status) || "--",
                transaction_id: <>{row.PaymentInfos.length ? row.PaymentInfos[0]?.transaction : "--"}</>,
                fee_type: <>{row?.details ? row?.details : row?.feeType ? row?.feeType : "--"}</>,
                instrument_logo: <>{row.PaymentInfos ? cardshow(row.PaymentInfos[0]?.network) : ""}</>,
                transaction_acc: <>{`**** **** **** ${row.PaymentInfos[0]?.last4 || "****"}`}</>,
                invoice: <>{row.status == "1" ?
                    <span onClick={() => paymentUrl(row?.payment_id)}>
                        <DownloadForOffline sx={{ fill: green[600], cursor: 'pointer' }} />
                    </span> : null}</>,
                transfer_initiate: row?.created_at,
                transfer_complete: row?.paid_on,
                paymentgateway_id: row?.payment_id,
                payStatus: row?.status,
            };
        });
        setData(rowsDataTable)
    };

    const columns = [
        {
            label: 'Date',
            name: "date",
        },
        {
            label: 'Amount',
            name: "amount",
        },
        {
            label: 'Status',
            name: "status",
        },
        {
            label: 'Transaction ID',
            name: "transaction_id",
        },
        {
            label: 'Payment Details',
            name: "fee_type",
        },
        {
            label: 'Card Type',
            name: "instrument_logo",
        },
        {
            label: 'Transaction Acc',
            name: "transaction_acc",
        },
        {
            label: 'Invoice',
            name: "invoice",
        },
        {
            label: "transfer_initiate",
            name: "transfer_initiate",
            options: {
                display: false,
            }
        },
        {
            label: "transfer_complete",
            name: "transfer_complete",
            options: {
                display: false,
            }
        },
        {
            label: 'Payment Gateway Id',
            name: "paymentgateway_id",
            options: {
                display: false,
            }
        },
        {
            label: 'payStatus',
            name: "payStatus",
            options: {
                display: false,
            }
        },
    ];
    /*     var col = [];
        var act: any = [];
        var i = 0; */

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        selectableRows: "none",
        rowsPerPageOptions: [],
        fixedHeader: false,
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        renderExpandableRow: (rowData: any) => {
            console.log("rowData", rowData)
            return (
                <TableRow >
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Box className={classes.boxBorder1}>
                            <div className="row">
                                <Col lg="4" sm="4" xs="12" className={classes.Right}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer Initiated
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp : {rowData[8] ? moment(rowData[8]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                </Col>
                                <Col lg="4" sm="4" xs="12">
                                    <div className={classes.line}></div>
                                </Col>

                                <Col lg="4" sm="4" xs="12" className={classes.Left}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={rowData[11] === 2 ? classes.TransferFaild : classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer {paymentStatus(rowData[11])}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp :  {rowData[9] ? moment(rowData[9]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Payment Gateway ID : {rowData[10]}
                                    </Typography>
                                </Col>
                            </div>
                        </Box>
                    </TableCell>
                </TableRow >
            );
        },
    };

    // function createData(
    //     card: number,
    //     date: string,
    // ) {
    //     return {
    //         card,
    //         date,
    //     };
    // }

    const cardshow = (value: any) => {
        if (value?.length === 0) {
            return "---";
        }
        switch (value?.brand) {
            case "visa":
                return <img src={Visa} alt="visa" />;
            case "master":
                return <img src={Master} alt="visa" />;
            default:
                return <img src={Visa} alt="visa" />;
        }
    };

    // function Row(props: { row: any }) {
    //     const { row }: any = props;
    //     const [open, setOpen] = useState(false);

    //     return (
    //         <React.Fragment>
    //             <TableRow>
    //                 <TableCell>
    //                     <IconButton
    //                         aria-label="expand row"
    //                         size="small"
    //                         onClick={() => setOpen(!open)}
    //                     >
    //                         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    //                     </IconButton>
    //                 </TableCell>
    //                 <TableCell align="left">
    //                     <span onClick={() => navigateToExternalUrl(row.Invoices[0].invoice_url, true)}>
    //                         <DownloadForOffline sx={{ fill: green[600] }} />
    //                     </span>
    //                 </TableCell>
    //                 {/* <TableCell align="left">Lorem Ipsum is simply dummy</TableCell> */}
    //                 <TableCell align="left">{row.symbol ? row.symbol : "--"} {" "}{row.total_amount}</TableCell>
    //                 <TableCell align="left">{row.PaymentInfos ? row.PaymentInfos[0].transaction : "--"}</TableCell>
    //                 <TableCell align="left">{row.feeType ? row.feeType : "--"}</TableCell>
    //                 <TableCell align="left">{row.PaymentInfos ? cardshow(row.PaymentInfos[0].network) : ""}</TableCell>
    //                 <TableCell align="left">**** **** **** 3238</TableCell>

    //             </TableRow>
    //             <TableRow>
    //                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
    //                     <Collapse in={open} timeout="auto" unmountOnExit>
    //                         <Box className={classes.boxBorder1}>
    //                             <div className="row">
    //                                 <Col lg="4" sm="4" xs="12" className={classes.Right}>
    //                                     <Typography
    //                                         variant="h6"
    //                                         gutterBottom
    //                                         component="div"
    //                                         className={classes.TransferInitiated}
    //                                     >
    //                                         <GppGoodRoundedIcon />
    //                                         Transfer Initiated
    //                                     </Typography>
    //                                     <Typography
    //                                         variant="subtitle1"
    //                                         gutterBottom
    //                                         component="div"
    //                                     >
    //                                         Timestamp : {row.created_at ? moment(row.created_at).format("MMM D, YYYY | h:mm:ss A") : "--"}
    //                                     </Typography>
    //                                 </Col>
    //                                 <Col lg="4" sm="4" xs="12">
    //                                     <div className={classes.line}></div>
    //                                 </Col>

    //                                 <Col lg="4" sm="4" xs="12" className={classes.Left}>
    //                                     <Typography
    //                                         variant="h6"
    //                                         gutterBottom
    //                                         component="div"
    //                                         className={classes.TransferInitiated}
    //                                     >
    //                                         <GppGoodRoundedIcon />
    //                                         Transfer Complited
    //                                     </Typography>
    //                                     <Typography
    //                                         variant="subtitle1"
    //                                         gutterBottom
    //                                         component="div"
    //                                     >
    //                                         Timestamp :  {row.paid_on ? moment(row.paid_on).format("MMM D, YYYY | h:mm:ss A") : "--"}
    //                                     </Typography>
    //                                 </Col>
    //                             </div>
    //                         </Box>
    //                     </Collapse>
    //                 </TableCell>
    //             </TableRow>

    //         </React.Fragment>
    //     );
    // };

    return (
        <>
            <ToastMessage />
            <ThemeProvider theme={theme}>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </>);
};
export default Transaction;